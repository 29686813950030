import { AgenturModel, AgtType } from 'app/models/agentur.model';
import { AgenturExtendedVM, AgenturListUiStore } from './agt.list.ui.store';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isNumeric } from 'app/utils';
import { SegementStates, SegmentState } from 'app/models/segment/segment.model';
import _ from 'lodash';
import { AgtUserUiStore } from './agt.user.ui.store';
import { schwerpunktRoles } from './agt.schwerpunkt.ui.store';
import { ISelectOpt } from 'app/components/common/Select';
import { MultiSteartIds, Steart } from 'app/models/core/steart.model';
import { KvBnrAgtFilterStore, KvVBnrAgtFilterModel } from '../kv.bnr.agt.filter.store';
import { Vertkats } from 'app/models/core/vertkat.model';

export interface IAgtFilter {
	name: string;
	value: string | number;
}

export interface IAgtFilterable {
	agt: AgenturModel | AgenturExtendedVM;
}

export interface IFilterOpts {
	ignoreAgtId: boolean;
}

export const defaultOpts: IFilterOpts = {
	ignoreAgtId: false,
};

export interface ISavedFilter {
	name: string;
	opts: IAgtFilter[];
}

interface IAgenturFilterDef {
	label: string;
	name: string;
	placeholderText: string;
	type: 'Input' | 'SingleSelect' | 'MultiSelect' | 'AgtTyp' | 'BloodyException';
	selectOpts?: ISelectOpt[];
	applyFilter: (agt: AgenturExtendedVM, opts: IFilterOpts, value: any) => [number, number]; // filterCount, hitCount
	visibleForRoles?: Steart[];
}

class AgenturFilterDef {
	label: string;
	name: string;
	type: 'Input' | 'SingleSelect' | 'MultiSelect' | 'AgtTyp' | 'BloodyException';
	placeholderText;
	selectOpts?: ISelectOpt[];
	applyFilter: (agt: AgenturExtendedVM, opts: IFilterOpts, value: any) => [number, number];

	@observable
	value: any;

	@computed
	get hasValue() {
		if (this.type === 'MultiSelect') {
			return this.value && this.value.length > 0;
		}
		return !!this.value;
	}

	visibleForRoles?: Steart[];

	constructor(opts: IAgenturFilterDef) {
		makeObservable(this);
		this.label = opts.label;
		this.name = opts.name;
		this.placeholderText = opts.placeholderText;
		this.type = opts.type;
		this.applyFilter = opts.applyFilter;
		this.selectOpts = opts.selectOpts;
		this.visibleForRoles = opts.visibleForRoles;
	}

	@computed
	get changeString() {
		switch (this.type) {
			case 'Input':
			case 'SingleSelect':
			case 'AgtTyp':
				return this.value;
			case 'MultiSelect':
				if (this.value) {
					return this.value.join(',');
				}
				return undefined;
			case 'BloodyException':
				return this.value;
		}
	}

	@action
	setPropertyString(val: string | null | undefined) {
		if (val !== null) {
			this.value = val;
		} else {
			this.value = undefined;
		}
	}

	@action
	setPropertyArray(val?: any[]) {
		if (val) {
			const clean: any = [];
			val.forEach((v) => {
				if (v && v.value) {
					clean.push(v.value);
				}
			});
			this.value = clean.map((v: string) => (_.isNumber(v) ? parseInt(v) : v));
		} else {
			this.value = undefined;
		}
	}

	@action
	setAgtType(val?: AgtType) {
		this.value = val;
	}

	@action
	setValueFromQueryString(val: any) {
		if (this.type === 'MultiSelect') {
			if (val && val !== null) {
				const vals = val.split(',');
				const clean: any = [];
				vals.forEach((v: any) => {
					if (v) {
						clean.push(v);
					}
				});
				// this.value = clean;
				this.value = clean.map((v: string) => (isNumeric(v) ? parseInt(v) : v));
			} else {
				this.value = undefined;
			}
		} else {
			this.value = val;
		}
	}
}

export class AgenturFilterUiStore {
	agenturListUiStore: AgenturListUiStore;
	agtUserUiStore: AgtUserUiStore;
	kvBnrAgtFilterStore: KvBnrAgtFilterStore;

	constructor(agenturListUiStore: AgenturListUiStore, agtUserUiStore: AgtUserUiStore, kvBnrAgtFilterStore: KvBnrAgtFilterStore) {
		makeObservable(this);
		this.agenturListUiStore = agenturListUiStore;
		this.agtUserUiStore = agtUserUiStore;
		this.kvBnrAgtFilterStore = kvBnrAgtFilterStore;
	}
	_filters: AgenturFilterDef[] = [];

	initFilters() {
		const res: AgenturFilterDef[] = [];
		res.push(
			new AgenturFilterDef({
				label: 'Freitext',
				name: 'fullText',
				type: 'Input',
				placeholderText: 'Suchen',
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						const s = value.toLowerCase();
						if (agt.fullTextExtended.indexOf(s) >= 0) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'Agentur',
				name: 'agtId',
				type: 'MultiSelect',
				placeholderText: 'Agentur Auswählen',
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						// if (value && value.length > 0 && !opts.ignoreAgtId) {
						filterCount++;
						if (value.includes(agt.agtId)) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'Betreuer',
				name: 'betreuerBnrId',
				type: 'MultiSelect',
				placeholderText: 'Betreuer Auswählen',
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						const f = agt.users.find((u) => value!.includes(u.bnrId));
						if (!!f) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'GS',
				name: 'gs',
				type: 'MultiSelect',
				placeholderText: 'GS Auswählen',
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						if (value.includes(agt.gsId)) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);
		res.push(
			new AgenturFilterDef({
				label: 'VD',
				name: 'vd',
				type: 'MultiSelect',
				placeholderText: 'VD Auswählen',
				visibleForRoles: [Steart.DVSADMIN],
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						if (value.includes(agt.vdId)) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'Vertkat',
				name: 'vertkat',
				type: 'MultiSelect',
				placeholderText: 'Vertkat Auswählen',
				visibleForRoles: [Steart.DVSADMIN],
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						if (value.includes(agt.vertKat?.id)) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'Segmentierung',
				name: 'segment',
				type: 'MultiSelect',
				placeholderText: 'Segmentierung Auswählen',
				visibleForRoles: [Steart.VBL, Steart.DVSADMIN],
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						if (value.includes(agt.segment)) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'Schwerpunkt',
				name: 'schwerpunkt',
				type: 'MultiSelect',
				placeholderText: 'Schwerpunkt Auswählen',
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						if (value.includes(agt.schwerpunkt)) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);
		res.push(
			new AgenturFilterDef({
				label: 'Stellenart',
				name: 'steart',
				type: 'MultiSelect',
				placeholderText: 'Stellenart Auswählen',
				visibleForRoles: [...MultiSteartIds],

				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						if (agt.steart && value.includes(agt.steart.id)) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'Jahresthemen',
				name: 'jahresthemen',
				type: 'MultiSelect',
				placeholderText: 'Jahresthemen Auswählen',
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						if (agt.jahresthemen) {
							const f = agt.jahresthemen._themen.find((t) => value!.includes(t));
							if (!!f) {
								hitCount++;
							}
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'Agentur oder Ada',
				name: 'agtTyp',
				type: 'AgtTyp',
				placeholderText: 'Agtyp Auswählen',
				visibleForRoles: [...MultiSteartIds],
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value) {
						filterCount++;
						if (agt.agtType === value) {
							hitCount++;
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		res.push(
			new AgenturFilterDef({
				label: 'GP',
				name: 'gp',
				type: 'MultiSelect',
				placeholderText: 'GP Auswählen',
				applyFilter: (agt, opts, value) => {
					let filterCount = 0;
					let hitCount = 0;
					if (value && value.length > 0) {
						filterCount++;
						if (agt.gpShort) {
							if (value.includes(agt.gpShort)) {
								hitCount++;
							}
						}
					}
					return [filterCount, hitCount];
				},
			}),
		);

		this._filters = res;
	}

	@computed
	get filters() {
		if (this._filters.length === 0) {
			// init needed to prevserve value status during query string setting.
			this.initFilters();
		}

		this._filters.forEach((f) => {
			switch (f.name) {
				case 'agtId':
					f.selectOpts = this.agtenturOpts;
					break;
				case 'betreuerBnrId':
					f.selectOpts = this.betreuerOpts;
					break;
				case 'gs':
					f.selectOpts = this.gsOpts;
					break;
				case 'vd':
					f.selectOpts = this.vdOpts;
					break;
				case 'segment':
					f.selectOpts = this.segmentOpts;
					break;
				case 'schwerpunkt':
					f.selectOpts = this.schwerpunktOpts;
					break;
				case 'vertkat':
					f.selectOpts = this.vertkatOpts;
					break;
				case 'steart':
					f.selectOpts = this.steartOpts;
					break;
				case 'jahresthemen':
					f.selectOpts = this.jahresThemenOpts;
					break;
				case 'gp':
					f.selectOpts = this.gpOpts;
					break;
			}
		});

		return this._filters;
	}

	@computed
	get visibleFilters() {
		const currentSteart = this.agenturListUiStore.agenturStore.session.currentSteart!;
		const userFilters = this.filters.filter((f) => {
			if (!f.visibleForRoles) {
				return true;
			}
			if (f.visibleForRoles.includes(currentSteart.steart)) {
				return true;
			}
			if (f.visibleForRoles.includes(currentSteart.steart)) {
				return true;
			}
			return false;
		});
		return userFilters.filter((f) => !f.selectOpts || (f.selectOpts && f.selectOpts.length > 1));
	}

	@computed
	get changedString() {
		let s = '';
		this.filters.forEach((f) => (s += f.changeString));
		return `${s} `;
	}

	@computed
	get fullTextFilterValue() {
		const f = this._filters.find((f) => f.name === 'fullText');
		if (!f!.value) {
			return '';
		}
		return f!.value;
	}
	@action
	setFullTextValue(v: string) {
		const f = this._filters.find((f) => f.name === 'fullText');
		f!.setPropertyString(v);
	}

	@observable
	jahresThemen?: string[];

	@computed
	get betreuerIdFilter() {
		return this.filters.find((f) => f.name === 'betreuerBnrId')!;
	}

	@computed
	get singleSelectedBetreuerId() {
		const f = this.betreuerIdFilter;
		if (f.value && f.value.length === 1) {
			return f.value[0];
		}
	}

	@action
	setBetreuerId(betreuerId?: number) {
		this.betreuerIdFilter.setPropertyArray([betreuerId]);
	}

	@observable
	singleSelectedAgtId?: number;

	@action
	selectSingleAgtId(agtId?: number) {
		this.singleSelectedAgtId = agtId;
	}

	applyFilter(agt: AgenturExtendedVM, opts: IFilterOpts) {
		let filterCount = 0;
		let hitCount = 0;

		this.filters.forEach((f) => {
			const r = f.applyFilter(agt, opts, f.value);
			filterCount += r[0];
			hitCount += r[1];
		});

		return filterCount === hitCount;
	}

	@computed
	get asQuery() {
		const q: any = {};
		this.filters.forEach((f) => {
			if (f.hasValue) {
				q[f.name] = f.changeString;
			}
		});
		return q;
	}

	@computed
	get filtersWithValue() {
		return this.filters.filter((f) => f.hasValue);
	}

	@action
	reset() {
		this.filters.forEach((f) => (f.value = undefined));
	}

	///////////////////////
	/// filter saving

	@computed
	get kvBnrAgtFilterItem() {
		const i = this.kvBnrAgtFilterStore._items;
		if (i && i.length >= 1) {
			return i[0];
		}
		return new KvVBnrAgtFilterModel({
			group: 'agt_filter',
			key: 'agt_filter',
			valueString: '[]',
		});
	}

	@computed
	get savedFilters() {
		const kv = this.kvBnrAgtFilterItem;
		return kv.filters;
	}

	@computed
	get savedFiltersOpts() {
		return this.savedFilters.map((x) => {
			return {
				label: x.name,
				value: x.name,
			};
		});
	}

	serializeCurrentFilter(): IAgtFilter[] {
		const res: IAgtFilter[] = [];
		this.filtersWithValue.forEach((f) => {
			res.push({
				name: f.name,
				value: f.value,
			});
		});
		return res;
	}

	@observable
	_loadedFilterName?: string;

	@computed
	get loadedFilterName() {
		const currentFvs = this.filtersWithValue;

		let loaded;
		for (const sf of this.savedFilters) {
			let same = true;
			if (sf.opts.length !== currentFvs.length) {
				same = false;
				continue;
			}

			sf.opts.forEach((e) => {
				const currFi = currentFvs.find((x) => x.name === e.name);
				if (!currFi) {
					same = false;
					return;
				}
				if (currFi.type === 'MultiSelect') {
					if (!_.isEqual(currFi.value, e.value)) {
						same = false;
					}
				}
				if (currFi.type !== 'MultiSelect') {
					if (currFi.value !== e.value) {
						same = false;
					}
				}
			});
			if (same) {
				loaded = sf;
				break;
			}
		}
		if (loaded) {
			return loaded.name;
		}
	}

	@computed
	get isLoadedFilter() {
		return !!this.loadedFilterName;
	}

	@computed
	get isDirty() {
		return !this.isLoadedFilter && this.filtersWithValue.length > 0;
	}

	@action
	load(name: string) {
		const saved = this.savedFilters.find((x) => x.name === name);
		if (!saved) {
			console.log('could not find filter with name ' + name);
			return;
		}
		this.reset();
		saved.opts.forEach((s) => {
			const f = this.filters.find((f) => f.name === s.name);
			if (f) {
				runInAction(() => {
					f.value = s.value;
				});
			}
		});
		this._loadedFilterName = name;
	}

	async deleteFilter(name: string) {
		this.kvBnrAgtFilterStore.clearCache();
		await this.kvBnrAgtFilterStore.findAll();
		let saved = this.savedFilters;
		// remove existing item
		saved = saved.filter((x) => x.name !== name);
		this.kvBnrAgtFilterItem.setFilters(saved);
		await this.kvBnrAgtFilterStore.save(this.kvBnrAgtFilterItem);
	}

	async saveFilter(name: string) {
		const opts = this.serializeCurrentFilter();
		this.kvBnrAgtFilterStore.clearCache();
		await this.kvBnrAgtFilterStore.findAll();
		let saved = this.savedFilters;
		// remove/replace existing item
		saved = saved.filter((x) => x.name !== name);
		saved.push({
			name,
			opts: opts,
		});
		this.kvBnrAgtFilterItem.setFilters(saved);
		await this.kvBnrAgtFilterStore.save(this.kvBnrAgtFilterItem);
	}

	////////////////////////////////////////
	//// OPTIONS FOR SELECTS ///////////////

	@computed
	get agtenturOpts() {
		let agts = this.agenturListUiStore.items.map((agt) => {
			return {
				label: `${agt.name.trim()} [${agt.agtId}] `,
				value: agt.agtId,
			};
		});
		agts = _.sortBy(agts, 'label');
		return agts;
	}

	@computed
	get betreuerOpts() {
		const validUsers = this.agenturListUiStore.userStore._items.filter((x) => !!x.name && !!x.steartText);
		if (validUsers.length < this.agenturListUiStore.userStore._items.length) {
			console.warn('es wurden folgende betreuer aus den betreuer opts gefilter da daten fehlen:');
			// this.agenturListUiStore.userStore._items.forEach((x) => {
			// 	if (!x.name || !x.statusText) {
			// 		console.log(x, x.name, x.steartText);
			// 	}
			// });
		}
		let betr = validUsers.map((betreuer) => {
			return {
				label: `${betreuer.name.trim()} ${betreuer.steartText.trim()} [${betreuer.bnrId}] `,
				value: betreuer.bnrId,
			};
		});
		betr = _.sortBy(betr, 'label');
		return betr;
	}

	@computed
	get segmentOpts() {
		const getSegmentState = (state: SegmentState) => {
			const ret = SegementStates.find((s) => s.state === state);
			return ret ? ret?.label : '';
		};

		return [
			{
				value: SegmentState.starter,
				label: getSegmentState(SegmentState.starter),
			},
			{
				value: SegmentState.entwicklungsagentur,
				label: getSegmentState(SegmentState.entwicklungsagentur),
			},
			{
				value: SegmentState.potential,
				label: getSegmentState(SegmentState.potential),
			},
			{
				value: SegmentState.breitenagentur,
				label: getSegmentState(SegmentState.breitenagentur),
			},
			{
				value: SegmentState.unternehmer,
				label: getSegmentState(SegmentState.unternehmer),
			},
		];
	}

	@computed
	get schwerpunktOpts() {
		return schwerpunktRoles.map((r) => {
			return {
				value: r,
				label: r,
			};
		});
	}
	@computed
	get vertkatOpts() {

		let allVertkats: number[] = [];
		this.agenturListUiStore.items.forEach((u) => u.vertKat?.id ? allVertkats.push(u.vertKat!.id) : null);
		allVertkats = _.sortBy(_.uniq(allVertkats));
		return allVertkats.map((i) => {
			const v = Vertkats.find(f => f.id === i);
			return {
				label: v ? v.name + ' (' + v.id + ')' : i.toString(),
				value: v ? v.id : i,
			};
		});


	}

	@computed
	get jahresThemenOpts() {
		let opts: ISelectOpt[] = [];

		this.agenturListUiStore.items.map((i) => {
			if (!i.jahresthemen) {
				return;
			}
			i.jahresthemen.themen.forEach((t) => {
				const f = opts.find((o) => o.value === t.key);
				if (!f) {
					opts.push({
						value: t.key,
						label: t.label + ' [' + t.group + ']',
					});
				}
			});
		});
		return _.sortBy(opts, 'label');
	}

	@computed
	get steartOpts() {
		let stearts = this.agenturListUiStore.items.filter((a) => a.steart).map((a) => a.steart!);

		stearts = _.uniqBy(stearts, (s) => s.id);

		let betr = stearts.map((s) => {
			let label = s._name;
			if (!label) {
				label = s.longName;
			}
			return {
				label: `${label} `,
				value: s.id,
			};
		});
		let shorties = betr.filter((x) => x.label.length <= 4);
		shorties = _.sortBy(shorties, 'label');
		let longies = betr.filter((x) => x.label.length > 4);
		longies = _.sortBy(longies, 'label');

		return shorties.concat(longies);
	}

	@computed
	get vdOpts() {
		let allVds: string[] = [];
		this.agtUserUiStore.items.forEach((u) => (allVds = allVds.concat(u.vdList)));
		allVds = _.sortBy(_.uniq(allVds));
		return allVds.map((i) => {
			return {
				label: i,
				value: i,
			};
		});
	}

	@computed
	get gsOpts() {
		let allGs: string[] = [];
		// this.agtUserUiStore.users.forEach((u) => (allGs = allGs.concat(u.gsList)));
		this.agenturListUiStore.items.forEach((a) => (a.gsId ? allGs.push(a.gsId) : undefined));
		allGs = _.sortBy(_.uniq(allGs));
		return allGs.map((i) => {
			return {
				label: i,
				value: i,
			};
		});
	}

	@computed
	get gpOpts() {
		let gps = _.uniq(this.agenturListUiStore.items.map((i) => i.gpShort));
		return gps.map((gp) => {
			return {
				label: gp,
				value: gp,
			};
		});
	}
}
