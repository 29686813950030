import { useStore } from 'app/context';
import { useCallback, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { notify } from '../common/notify';
import ProgressRing from '../common/ProgressRing';
import { Icon } from '../common/Icon';
import { ImportDataType } from 'app/stores/admin.imports.store';
import { AdminFileUploadFileNameModal } from './AdminFileUploadFileNameModal';

const allowedMimeTypes = [
	'application/zip',
	'application/zip',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
	'application/octet-stream',
	'application/vnd.ms-excel.sheet.macroEnabled.12',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel',
	'application/x-zip-compressed',
	'text/plain',
	'text/csv',
	'.csv',
	'application/msword',
	'application/pdf',
	'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
	'image/jpeg',
	'image/png',
	'audio/mp3',
	'audio/mpeg',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export interface IAdminUploadOptions {
	dataType?: ImportDataType;
	kw?: string;
	onDone?: (res: any) => void;
	id?: string;
	fileName?: string;
}

export type IAdminFileUploaderProps = Pick<DropzoneOptions, 'minSize' | 'maxSize' | 'maxFiles' | 'multiple'> &
	IAdminUploadOptions & {
		dropZoneText: string;
		uploadingText: string;
		dropZoneActiveText: string;
		isButton?: boolean;
		icon?: string;
		inline?: boolean;
		accept?: string;
	};

export const AdminFileUpload = observer((props: IAdminFileUploaderProps) => {
	const { uiStore, adminFileUploadUiStore } = useStore();
	const { dropZoneText, uploadingText, dropZoneActiveText } = props;
	const accept = props.accept ? props.accept : allowedMimeTypes.join(', ');
	const [progress, setProgress] = useState(0);
	const onDrop = useCallback(
		async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
			if (fileRejections && fileRejections.length > 0) {
				fileRejections.forEach((file: any) => {
					file.errors.forEach((err: any) => {
						switch (err.code) {
							case 'file-too-large':
								notify(`Datei ist zu groß! Dateien dürfen maximal 20MB groß sein.`, '', 'error');
								break;

							case 'file-invalid-type':
								notify(`Ungültiger Dateityp. Es werden nur Office Dateien, PDF, Bilder (JPG/PNG) und Zip Datei unterstützt.`, '', 'error');
								break;
							default:
								notify(`Fehler beim Hochladen: ${err.message}`, '', 'error');
								break;
						}
					});
				});
				return;
			}

			const totalBytes = _.sumBy(acceptedFiles, 'size');
			let currentBytes = acceptedFiles.map(() => 0);

			const promises = acceptedFiles.map((file: any, i: number) => {
				const uploadOpts: IAdminUploadOptions = {
					dataType: props.dataType,
					kw: props.kw,
					id: props.id,
				};
				uiStore.setIsUploading(true);
				return adminFileUploadUiStore.uploadFile(file, uploadOpts, (bytes: number) => {
					currentBytes[i] = bytes;
					let sumCurrent = _.sumBy(currentBytes);
					const progress = (sumCurrent / totalBytes) * 100;
					console.log(progress, sumCurrent, totalBytes);
					setProgress(progress);
				});
			});

			const res = await Promise.all(promises)
				.catch((err) => {
					console.error('Es ist ein unerwartet Fehler beim Hochladen aufgetreten.', err);
					notify('Es ist ein unerwartet Fehler beim Hochladen aufgetreten.', '', 'error');
				})
				.finally(() => {
					uiStore.setIsUploading(false);
				});
			if (props.onDone) {
				props.onDone(res);
			}
		},
		[adminFileUploadUiStore, uiStore, props],
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });

	const onFileChange = (fileName?: string) => {
		adminFileUploadUiStore.newFilename = fileName;

	}

	return (
		<>
			{props.dataType &&
				<AdminFileUploadFileNameModal dataType={props.dataType} onClose={onFileChange} />
			}
			{uiStore.isUploading && <ProgressRing total={100} value={progress} label="Hochgeladen" />}
			<div {...getRootProps()} className={`file-uploader ${props.isButton ? 'is-button' : ''} ${props.inline ? 'is-inline' : ''}`}>
				<input {...getInputProps()} accept={accept} />
				<div className="drop-area button">
					<div className="message">
						{uiStore.isUploading && (
							<div className="overlay">
								<div className="icon">
									<Icon iconClass="spinner fa-pulse" />
								</div>
								<div className="text">{uploadingText}</div>
							</div>
						)}

						{isDragActive && (
							<div className="overlay">
								<div className="icon">
									<Icon iconClass="download" />
								</div>
								<div className="text">{dropZoneActiveText}</div>
							</div>
						)}

						<div className="body">
							<div className="icon">
								<Icon iconClass={props.icon ? props.icon : 'upload'} />
							</div>
							{dropZoneText && <div className="text">{dropZoneText}</div>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
});
