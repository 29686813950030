import { Table } from 'app/components/table/table';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtProdPosVM } from 'app/models/berichte/agt.prod.pos.vm';
import { formatNum, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { Tooltip, TooltipProps } from 'recharts';
import { ScatterPlotChart, ScatterPlotModel, ScatterPlotVarationModel } from 'app/components/charts/ScatterPlotChart';
import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { eigenPlanungSchema } from './ProdPositionen';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import colors from 'assets/scss/colors.module.scss';
import { WidgetNavigate } from 'app/components/common/WidgetNavigate';
import { Icon } from 'app/components/common/Icon';
import { ZweitverkauferProdPosVM } from 'app/stores/ui/zweitverkaeufer.prod.ui.store';
import { GPMAXYEAR } from 'app/stores/ui/berichte.ui.store';

const fillColor = (ze: number) => {
	if (!ze) {
		return colors.grey;
	}

	if (ze < 60) {
		return colors.error;
	}
	if (ze < 160) {
		return colors.warning;
	}

	return colors.success;
};

const scatterVariations = [
	new ScatterPlotVarationModel({
		key: 'jahr',
		labelX: 'Zielerreichung',
		pathX: 'zeJahrLimited',
		pathY: 'posVal.ergebnis',
		buttonLabel: 'Jahr',
		labelY: 'Ergebnis',
		plotColor: fillColor,
		plotColorProp: 'posVal.ze',
		domainX: [40, 180],
		ticksX: [40, 60, 80, 100, 120, 140, 160, 180],
	}),
	new ScatterPlotVarationModel({
		key: 'zeit',
		labelX: 'Zielerreichung',
		pathX: 'zeZeitLimited',
		pathY: 'posVal.ergebnis',
		buttonLabel: 'Zeit',
		labelY: 'Ergebnis',
		plotColor: fillColor,
		plotColorProp: 'posVal.zeZeit',
		domainX: [40, 180],
		ticksX: [40, 60, 80, 100, 120, 140, 160, 180],
	}),
];

export const ProdZwischenstandWidget = observer((props: IWidgetConsumer) => {
	const { berichteUiiStore, agtProdUiStore } = useStore();
	const [loaded, setLoadad] = useState<boolean>(false);

	// const planKey = 'gesamtbewertung';
	const planKey = 'produktivitaet';

	const tm = useMemo(() => {
		const tm = new TableModel<AgtProdPosVM, number>();
		tm.sortBy = 'name';
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		agtProdUiStore.findByPlanKey(planKey, GPMAXYEAR).then((res) => {
			tm.setRowData(res);
			setLoadad(true);
		});
	}, [tm, setLoadad, agtProdUiStore, planKey, berichteUiiStore.selectedAgt]);

	const chartOpts = new ScatterPlotModel({
		tableModel: tm,
		variations: scatterVariations,
	});

	const rptPath = `/berichte/production/gp/${berichteUiiStore.gpMaxYear}/prod-zwischenstand`;

	return (
		<>
			{loaded && (
				<Widget title={'Produktivitäten Zwischenstand'} action={<WidgetNavigate text={'Zum Bericht'} path={rptPath} />} widgetId={props.widgetKey}>
					<ScatterPlotChart {...chartOpts} tooltip={<Tooltip content={<AgtPosTooltip />} />} />
				</Widget>
			)}
		</>
	);
});

const AgenturZEIcon = (row: TableRow<AgtProdPosVM>) => {
	return (
		<>
			{row.data.posVal && (
				<>
					<span style={{ color: fillColor(row.data.posVal?.ze) }}>
						<Icon iconClass="circle" />
					</span>
				</>
			)}
		</>
	);
};

export interface IGPYear {
	year: number;
}

export const ProdZwischenstand = observer((props: IGPYear) => {
	const { berichteUiiStore, agtProdUiStore, zweitverkauferProdUiStore, agenturListUiStore } = useStore();
	const [loaded, setLoadad] = useState<boolean>(false);
	// const planKey = 'gesamtbewertung';
	const planKey = 'produktivitaet';

	const formatPerc = (val: number) => {
		return `${formatPercent(val, 1)} %`;
	};

	const tmAgt = useMemo(() => {
		const tm = new TableModel<AgtProdPosVM, number>();
		const cols = [
			// {
			// 	label: 'bm',
			// 	path: 'posVal.bm',
			// },
			// {
			// 	label: 'gp',
			// 	path: 'agt.vtnrGpincId',
			// },
			{
				label: '',
				path: 'posVal.ziel',
				render: AgenturZEIcon,
			},
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: 'Zielwert',
				path: 'posVal.ziel',
				format: formatNum,
			},
			{
				label: 'Eigener Zielwert',
				path: 'eigenerVal.valueNum',
				format: formatNum,
				editable: true,
			},
			{
				label: 'Ergebnis',
				path: 'posVal.ergebnis',
				format: formatNum,
			},
			{
				label: 'ZE',
				path: 'posVal.ze',
				format: formatPerc,
			},
			{
				label: 'ZE (Zeit)',
				path: 'posVal.zeZeit',
				format: formatPerc,
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, []);

	const tmZwv = useMemo(() => {
		const tmz = new TableModel<ZweitverkauferProdPosVM, number>();
		const colsZ = [
			{
				label: 'Zweitverkäufer',
				path: 'zweitverkaufer.name',
				render: (row: TableRow<ZweitverkauferProdPosVM>) => {
					return (
						<>
							{' '}
							{row.data.zweitverkaufer.name} ({row.data.zweitverkaufer.bnrcId})
						</>
					);
				},
			},
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: 'Zielwert',
				path: 'posVal.ziel',
				format: formatNum,
			},
			{
				label: 'Ergebnis',
				path: 'posVal.ergebnis',
				format: formatNum,
			},
			{
				label: 'ZE',
				path: 'posVal.ze',
				format: formatPerc,
			},
			{
				label: 'ZE (Zeit)',
				path: 'posVal.zeZeit',
				format: formatPerc,
			},
		];
		tmz.setCols(colsZ);
		tmz.sortBy = 'zweitverkaufer.name';
		tmz.idProperty = 'bnrcId';
		tmz.idType = 'number';
		return tmz;
	}, []);

	useEffect(() => {
		agtProdUiStore.findByPlanKey(planKey, props.year).then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtProdPosVM[];
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tmAgt.setRowData(res);
			setLoadad(true);
		});
	}, [tmAgt, setLoadad, agtProdUiStore, planKey, props.year, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	const onSubmit = async () => {
		await agtProdUiStore.saveMany(tmAgt.data, planKey, props.year);
	};

	useEffect(() => {
		zweitverkauferProdUiStore.load().then(() => {
			let res = zweitverkauferProdUiStore.items.filter(x => x.posVal && x.posVal.year === props.year);
			res = agenturListUiStore.applyFilterOnAgt(res) as ZweitverkauferProdPosVM[];
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tmZwv.setRowData(res);
			setLoadad(true);
		});
	}, [tmAgt, setLoadad, agtProdUiStore, planKey, berichteUiiStore.selectedAgt, zweitverkauferProdUiStore, tmZwv, agenturListUiStore.filterHasChanged, agenturListUiStore]);
	return <>{loaded && <AgenturPositionenEdit planKey={planKey} tm={tmAgt} tmZwv={tmZwv} onSubmit={onSubmit} />}</>;
});

export interface IAgenturPositionen {
	tm: TableModel<AgtProdPosVM, number>;
	tmZwv: TableModel<ZweitverkauferProdPosVM, number>;
	planKey: string;
	onSubmit: () => void;
}

const AgenturPositionenEdit = observer((props: IAgenturPositionen) => {
	const tm = props.tm;

	const chartOpts = new ScatterPlotModel({
		tableModel: tm,
		variations: scatterVariations,
	});




	return (
		<>
			<div className="table-container">
				<div className="pad-1rem">
					<ScatterPlotChart {...chartOpts} tooltip={<Tooltip content={<AgtPosTooltip />} />} />
				</div>
				<Table tm={props.tm} stickyHeader={true} stickyFooter={true} schema={eigenPlanungSchema} onSubmit={props.onSubmit} />
				{!tm.editMode && props.tmZwv.data.length > 0 && <Table tm={props.tmZwv} stickyHeader={true} stickyFooter={true} />}
			</div>
		</>
	);
});

const AgtPosTooltip = (props: TooltipProps<any, any>) => {
	const { active, payload } = props;
	if (active && payload && payload.length) {
		const data = payload[0].payload as AgtProdPosVM;
		return (
			<div className="custom-tooltip is-paddingless">
				<table className="table is-tooltip-table">
					<thead>
						<tr>
							<th colSpan={2}>{`${data.agt.name} (${data.agt.agtId})`}</th>
						</tr>
					</thead>
					<tbody>
						{data.posVal && (
							<>
								<tr>
									<td>Ergebnis:</td>
									<td>{data.posVal.ergebnis}</td>
								</tr>
								<tr>
									<td>Ziel:</td>
									<td>{data.posVal.ziel}</td>
								</tr>
								<tr>
									<td>ZE:</td>
									<td>{data.posVal.ze}</td>
								</tr>
								<tr>
									<td>ZE*:</td>
									<td>{data.posVal.zeStern}</td>
								</tr>
								<tr>
									<td>Zeit ZE:</td>
									<td>{data.posVal.zeZeit}</td>
								</tr>
							</>
						)}
					</tbody>
				</table>
			</div>
		);
	}

	return null;
};
