import { AgenturModel } from 'app/models/agentur.model';
import _ from 'lodash';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { AgenturStore } from '../agentur.store';
import { AgtPlanModel, AgtPlanStore } from '../agt.plan.store';
import { BnrTaskModel, BnrTaskStore, TaskStatus } from '../bnr.task.store';
import { SendDocPerEmailData } from './document.ui.store';
import { BaseUiStore } from './base.ui.store';

interface ITaskVM {
	currentBnrId: number;
	agt?: AgenturModel;
	plan?: AgtPlanModel;
	task: BnrTaskModel;
}

export class TaskVM {
	constructor(opts: ITaskVM) {
		makeObservable(this);
		this.currentBnrId = opts.currentBnrId;
		this.agt = opts.agt;
		this.plan = opts.plan;
		this.task = opts.task;
	}

	currentBnrId: number;
	agt?: AgenturModel;
	plan?: AgtPlanModel;
	task: BnrTaskModel;
	@computed
	get id() {
		return this.task.id;
	}

	@computed
	get isNew() {
		return !this.task.id;
	}

	@computed
	get agtId() {
		if (this.agt) {
			return this.agt.agtId;
		}
		return undefined;
	}

	@computed
	get planYear() {
		if (this.plan) {
			return this.plan.year;
		}
		return undefined;
	}

	@computed
	get statusText() {
		if (this.task.archived) {
			return 'Archiviert';
		}
		return this.task.status === TaskStatus.OPEN ? 'Offen' : 'Erledigt';
	}
	@computed
	get isOpen() {
		return this.task.status === TaskStatus.OPEN;
	}
	@computed
	get isClosed() {
		return this.task.status === TaskStatus.CLOSED;
	}

	@computed
	get fullText() {
		let t = '';
		if (this.agt) {
			t += ' ' + this.agt.agtId.toString().toLowerCase();
			t += ' ' + this.agt.name.toString().toLowerCase();
		}
		if (this.task) {
			if (this.task.text) {
				t += ' ' + this.task.text.toLowerCase();
			}
			if (this.task.body) {
				t += ' ' + this.task.body.toLowerCase();
			}
			if (this.task.thema) {
				t += ' ' + this.task.thema.title;
			}
		}
		return t.toLowerCase();
	}
}

class TaskFilter {
	constructor() {
		makeObservable(this);
	}

	@observable
	agtId?: number;
	@observable
	planYear?: number;
	@observable
	fullText: string = '';

	@observable
	hideArchived: boolean = true;

	@observable
	isOpen: boolean = false;

	@observable
	isClosed: boolean = false;

	@action
	reset() {
		this.agtId = undefined;
		this.planYear = undefined;
		this.hideArchived = true;
		this.fullText = '';
	}
}

class TaskStats {
	total: number = 0;
	offen: number = 0;
	erledigt: number = 0;
	dueThisWeek: number = 0;

	@computed
	get offenPerc() {
		if (this.offen && this.total) {
			return (this.offen / this.total) * 100;
		}
		return 0;
	}

	@computed
	get erledigtPerc() {
		if (this.erledigt && this.total) {
			return (this.erledigt / this.total) * 100;
		}
		return 0;
	}
}

export class TaskUiStore extends BaseUiStore<TaskVM> {
	name = 'TaskUiStore';
	bnrTaskStore: BnrTaskStore;
	agenturStore: AgenturStore;
	agtPlanStore: AgtPlanStore;

	constructor(bnrTaskStore: BnrTaskStore, agenturStore: AgenturStore, agtPlanStore: AgtPlanStore) {
		super();
		makeObservable(this);
		this.bnrTaskStore = bnrTaskStore;
		this.agenturStore = agenturStore;
		this.agtPlanStore = agtPlanStore;
	}

	@observable
	currentFilter: TaskFilter = new TaskFilter();

	@computed
	get currentItems() {
		let items = this.items.map((i) => i);
		const f = this.currentFilter;
		if (!f) {
			return items;
		}
		if (f.hideArchived) {
			items = items.filter((i) => !i.task.archived);
		}
		if (f.agtId) {
			items = items.filter((i) => i.agtId === f.agtId);
		}

		if (f.planYear) {
			items = items.filter((i) => i.planYear === f.planYear);
		}
		if (f.fullText.length > 0) {
			const ft = f.fullText.toLowerCase();
			items = items.filter((i) => i.fullText.indexOf(ft) >= 0);
		}

		if (f.isOpen) {
			items = items.filter((i) => i.isOpen);
		}
		if (f.isClosed) {
			items = items.filter((i) => i.isClosed);
		}

		return _.sortBy(items, (r) => r.task.modified).reverse();
	}

	@computed
	get currentOpenAgtTasks() {
		let items = this.currentItems;
		// items = items.filter((i) => i.planYear === moment().year() || i.isOpen);
		items = items.filter((i) => i.isOpen);
		items = _.sortBy(items, (i) => {
			return i.task.dueDate;
		}).reverse();
		return items;
	}

	@observable current?: TaskVM;
	@computed
	get currentDocId() {
		if (this.current) {
			return this.current.id;
		}
		return undefined;
	}

	@computed
	get stats() {
		const stats = new TaskStats();
		const tasks = this.items.filter((i) => !i.task.archived);
		stats.total = tasks.length;
		stats.offen = tasks.filter((i) => i.isOpen).length;
		stats.erledigt = tasks.filter((i) => i.isClosed).length;
		const kw = moment().format('YYYYww');
		stats.dueThisWeek = tasks.filter((i) => i.task.kwDueDate === kw).length;
		return stats;
	}

	@action
	setCurrent(doc?: TaskVM) {
		this.current = doc;
	}

	@observable currentModalTask?: TaskVM;

	@action
	setCurrentModalTask(task?: TaskVM) {
		this.currentModalTask = task;
	}
	@action
	setPreviousModalTask() {
		if (!this.currentModalTask || this.currentItems.length === 0) {
			return;
		}
		let i = this.currentItems.findIndex((d) => d.id === this.currentModalTask!.id);
		if (i === 0) {
			i = this.currentItems.length;
		}
		const d = this.currentItems[i - 1];
		this.setCurrentModalTask(d);
	}

	@action
	setNextModalTask() {
		if (!this.currentModalTask || this.currentItems.length === 0) {
			return;
		}
		let i = this.currentItems.findIndex((d) => d.id === this.currentModalTask!.id);
		if (i === this.currentItems.length) {
			i = 0;
		}
		const d = this.currentItems[i + 1];
		this.setCurrentModalTask(d);
	}

	async _baseLoad() {
		const fetch = await Promise.all([this.agenturStore.findAll(), this.agtPlanStore.findAll(), this.bnrTaskStore.findAll()]);
		const agts = fetch[0];
		const plans = fetch[1];
		const tasks = fetch[2];

		const res: TaskVM[] = [];

		tasks.forEach((t) => {
			const p = plans.find((p) => p.year === t.planYear);
			const vm = new TaskVM({
				currentBnrId: this.agenturStore.session.currentUser!.bnrId,
				task: t,
				plan: p,
				agt: agts.find((a) => a.agtId === t.agtId),
			});

			res.push(vm);
		});
		runInAction(() => {
			this.items = res;
		});

	}

	async findAll() {
		this.load();
		return this.items;
	}

	addMassnahme(agt?: AgenturModel, plan?: AgtPlanModel) {
		const t = new BnrTaskModel({
			currentBnrId: this.bnrTaskStore.session.currentUser!.bnrId,
			agtId: agt ? agt.agtId : undefined,
			planId: plan ? plan.id : undefined,
			planYear: plan ? plan.year : undefined,
			body: '',
			text: 'Neue Maßnahme',
			created: new Date(),
			modified: new Date(),
		});
		const task = new TaskVM({
			currentBnrId: this.bnrTaskStore.session.currentUser!.bnrId,
			agt,
			plan,
			task: t,
		});
		return task;
	}

	async save(task: TaskVM) {
		const taskId = task.id;
		const t = await this.bnrTaskStore.save(task.task);
		const t2 = new TaskVM({
			currentBnrId: task.currentBnrId,
			agt: task.agt,
			plan: task.plan,
			task: t,
		});
		runInAction(() => {
			this.items = this.items.filter((d) => d.id !== taskId);
			this.items.push(t2);
		});
	}

	@action
	async deleteById(id: string) {
		await this.bnrTaskStore.deleteById(id);
		runInAction(() => {
			this.items = this.items.filter((d) => d.id !== id);
			this.setCurrent(undefined);
			this.setCurrentModalTask(undefined);
		});
	}

	async download(task: TaskVM) {
		const fileName = moment(task.task.modified).format('YYYYMMDD') + '-AGENTUR-' + _.snakeCase(task.task.text) + '.pdf';
		const windowReference = window.open();
		if (!windowReference) {
			return;
		}
		windowReference.opener = null;
		let pdfUrl;
		pdfUrl = await this.bnrTaskStore.downloadDocAsPdf(task.id, fileName);
		windowReference.location = pdfUrl as any;
	}

	async sendDocumentPerEmail(id: string, data: SendDocPerEmailData) {
		return await this.bnrTaskStore.sendDocumentPerEmail(id, data);
	}
}
