import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { formatDate } from 'app/utils';
import { AgenturSegment } from './AgenturSegment';
import { observer } from 'mobx-react';
import { AgtClusterTagList } from '../planung/AgtClusterWidget';
import { CLUSTERMAXYEAR } from 'app/stores/ui/berichte.ui.store';
import { Icon } from 'app/components/common/Icon';
import Config from 'Config';

export const AgenturProvModelWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;

	return (
		<Widget title={'Agentur Übersicht'} modifier="is-paddingless" widgetId={props.widgetKey}>
			{agt && (
				<table className="table is-text align-top">
					<tbody>
						<tr>
							<td>Prov.-modell</td>
							<td>
								<strong>{agt.provsys.name}</strong>
							</td>
						</tr>
						<tr>
							<td>Segmentierung</td>
							<td>
								<AgenturSegment agt={agt} />
							</td>
						</tr>
						<tr>
							<td>Geschäftsplan:</td>
							<td>
								<strong>{agt.vtnrGpincBez}</strong>
							</td>
						</tr>
						{agt.showApLeben && (
							<tr>
								<td>AP-Leben:</td>
								<td>
									<strong>{agt.aladin.provApLeben} ‰</strong>
								</td>
							</tr>
						)}
						<tr>
							<td>AP-Fonds:</td>
							<td>
								<strong>{agt.aladin.provApFonds} ‰</strong>
							</td>
						</tr>
						<tr>
							<td>Prov-Stufe AP:</td>
							<td>
								<strong>{agt.aladin.provStufenAP}</strong>
							</td>
						</tr>
						<tr>
							<td>Prov-Stufe FP:</td>
							<td>
								<strong>{agt.aladin.provStufenFP}</strong>
							</td>
						</tr>
						<tr>
							<td>
								<strong>Schadenregulierung</strong>
							</td>
							<td></td>
						</tr>
						<tr>
							<td>Vollmacht:</td>
							<td>
								<strong>{agt.schadenregBetrag}</strong>
							</td>
						</tr>
						{agt.schadenregBis3000 && (
							<tr>
								<td>Ablaufdatum: </td>
								<td>
									<strong>
										{formatDate(agt.aladin.schadenregRezertifizierungDatum)} / {agt.aladin.shadenregRezerAblauf}
									</strong>
								</td>
							</tr>
						)}
						<tr>
							<td>
								<strong>Agentur Cluster</strong>
							</td>
							<td>
								<AgtClusterTagList year={CLUSTERMAXYEAR} />
							</td>
						</tr>
						{!agt.visTeilnahme && Config.showVisTeilnahme && (
							<tr>
								<td>
									<strong>VIS-Teilnahme</strong>
								</td>
								<td>
									<Icon iconClass={'exclamation-circle'} faModifier="far color-red" /> <strong>Keine VIS-Teilnahme</strong>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			)}
		</Widget>
	);
});
