import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AgenturModel } from 'app/models/agentur.model';
import { AgenturStore } from '../agentur.store';
import { BnrStatsStore } from '../bnr.stats.store';
import { AkumaPrimeModel, KvAgenturYearAkumaPrimeStore } from '../kv.agentur.year.akumaprime.store';
import colors from 'assets/scss/colors.module.scss';
import { ISelectOpt } from 'app/components/common/Select';
import { calcZielErriechung, formatKw } from 'app/utils';
import { BaseUiStore } from './base.ui.store';

export enum AkumaPrimeType {
	einordnung = 'einordnung',
	terminierung = 'terminierung',
	beziehung = 'beziehung',
	terminierungErgebnis = 'terminierungErgebnis',
	einordnungsquote = 'einordnungsquote',
}
export interface IAkumaPrimeProps {
	items: AkumaPrimeModel[];
	einordnung?: {
		[key: string]: number;
	};
	beziehung?: {
		[key: string]: number;
	};
	terminierung?: {
		[key: string]: number;
	};
	terminierungErgebnis?: {
		[key: string]: number;
	};
}

export class AgtAkumaPrimeKwVM implements IAkumaPrimeProps {
	constructor(agt: AgenturModel, items: AkumaPrimeModel[]) {
		makeObservable(this);
		this.agt = agt;
		this.items = items;

		if (items.length === 0) {
			this.kw = 0;
			this.agtId = 0;
			return;
		}

		this.kw = items[0].kw;
		this.agtId = items[0].agtId;

		items.forEach((item) => {
			this.einordnung[item.einordnung] = item.count + (this.einordnung[item.einordnung] || 0);
			this.beziehung[item.beziehung] = item.count + (this.beziehung[item.beziehung] || 0);
			this.terminierung[item.terminierung] = item.count + (this.terminierung[item.terminierung] || 0);

			if (item.terminierungErgebnis) {
				this.terminierungErgebnis[item.terminierungErgebnis] = item.count + (this.terminierungErgebnis[item.terminierungErgebnis] || 0);
			}
		});
	}

	agtId: number = 0;
	agt: AgenturModel;

	items: AkumaPrimeModel[];

	einordnung: { [key: string]: number } = {};
	beziehung: { [key: string]: number } = {};
	terminierung: { [key: string]: number } = {};
	terminierungErgebnis: { [key: string]: number } = {};

	kw: number = 0;

	@computed
	get akumePrimeVals(): AkumaPrimeVal[] {
		const vals: any[] = [];
		const res: AkumaPrimeVal[] = [];

		AkumaPrimeSchema.forEach((s) => {
			const enumName = AkumaPrimeType[s.type];
			const val = _.get(this, `${enumName}.${s.key}`);
			vals.push({ ...s, value: val || 0 });
		});

		const einordnungVals = vals.filter((x) => x.type === AkumaPrimeType.einordnung);
		const totalEinordnung = einordnungVals.reduce((acc, val) => acc + val.value, 0);

		const beziehungVals = vals.filter((x) => x.type === AkumaPrimeType.beziehung);
		const totalBeziehung = beziehungVals.reduce((acc, val) => acc + val.value, 0);

		const terminierungVals = vals.filter((x) => x.type === AkumaPrimeType.terminierung);
		const totalTerminierung = terminierungVals.reduce((acc, val) => acc + val.value, 0);

		const terminierungErgebnisVals = vals.filter((x) => x.type === AkumaPrimeType.terminierungErgebnis);
		const totalTerminierungErgebnis = terminierungErgebnisVals.reduce((acc, val) => acc + val.value, 0);

		res.push(
			...einordnungVals.map((akumePrimeVal) => {
				return new AkumaPrimeVal({ ...akumePrimeVal, groupTotal: totalEinordnung });
			}),
			...beziehungVals.map((akumePrimeVal) => {
				return new AkumaPrimeVal({ ...akumePrimeVal, groupTotal: totalBeziehung });
			}),
			...terminierungVals.map((akumePrimeVal) => {
				return new AkumaPrimeVal({ ...akumePrimeVal, groupTotal: totalTerminierung });
			}),
			...terminierungErgebnisVals.map((akumePrimeVal) => {
				return new AkumaPrimeVal({ ...akumePrimeVal, groupTotal: totalTerminierungErgebnis });
			}),
		);

		return res;
	}

	// Einordnung
	@computed
	get einordnungTotal() {
		// this just counts all the values, to determine total amount of client to einordnen
		const val = this.items.map((i) => i.count).reduce((acc, val) => acc + val, 0);
		return val;
	}
	@computed
	get eingeordnetTotal() {
		// const allEingeordnet = allEinordnung - allNichtEingeordnet;
		const vals = this.items.filter((i) => !['NE'].includes(i.einordnung));
		const sum = vals.reduce((acc, val) => acc + val.count, 0);
		return sum;
	}
	@computed
	get einordnungsQuote() {
		return calcZielErriechung(this.einordnungTotal, this.eingeordnetTotal);
	}

	@computed
	get einordnungSach() {
		const filteredItems = this.items.filter((i) => ['S'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get einordnungLeben() {
		const filteredItems = this.items.filter((i) => ['L'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get einordnungKranken() {
		const filteredItems = this.items.filter((i) => ['K'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get einordnungSonstige() {
		const filteredItems = this.items.filter((i) => ['SO'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get einordnungNichtKontaktieren() {
		const filteredItems = this.items.filter((i) => ['NK'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get einordnungBestehendeAktion() {
		const filteredItems = this.items.filter((i) => ['BA'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get einordnungNichtEingeordnet() {
		const filteredItems = this.items.filter((i) => ['NE'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}

	@computed
	get abwahl() {
		const vals = this.items.filter((i) => ['NK', 'BA'].includes(i.einordnung));
		const sum = vals.reduce((acc, val) => acc + val.count, 0);
		return sum;
	}
	@computed
	get abwahlQuote() {
		if (this.eingeordnetTotal === 0) {
			return 0;
		}
		// Abwahlquote = (Nicht kontaktieren) / allEingeordnet
		const abwahl = this.abwahl;
		return calcZielErriechung(this.eingeordnetTotal, abwahl);
	}

	@computed
	get zuKontaktierendeKunden() {
		const vals = this.items.filter((i) => !['NK', 'BA', 'NE'].includes(i.einordnung));
		const sum = vals.reduce((acc, val) => acc + val.count, 0);
		return sum;
	}
	@computed
	get terminierungAgentur() {
		// all items with terminierung AGT and not einordnung BA or NK
		const filteredItems = this.items.filter((i) => ['AGT'].includes(i.terminierung) && !['BA', 'NK'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get terminierungExternTotal() {
		// all items with terminierung CC and not einordnung BA or NK
		const filteredItems = this.items.filter((i) => ['CC'].includes(i.terminierung) && !['BA', 'NK'].includes(i.einordnung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get terminierungExternQuote() {
		if (this.zuKontaktierendeKunden === 0) {
			return 0;
		}
		return calcZielErriechung(this.zuKontaktierendeKunden, this.terminierungExternTotal);
		//return (this.terminierungExternTotal / this.zuKontaktierendeKunden) * 100;
	}

	// Beziehung
	@computed
	get beziehungUnbekannt() {
		const filteredItems = this.items.filter((i) => ['BZU'].includes(i.beziehung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get beziehungWenigerGut() {
		const filteredItems = this.items.filter((i) => ['BZWG'].includes(i.beziehung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get beziehungMittel() {
		const filteredItems = this.items.filter((i) => ['BZM'].includes(i.beziehung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}
	@computed
	get beziehungGut() {
		const filteredItems = this.items.filter((i) => ['BZG'].includes(i.beziehung));
		return filteredItems.reduce((acc, val) => acc + val.count, 0);
	}

	static sumFromKwAgts(items: AgtAkumaPrimeKwVM[]) {
		const last = items[items.length - 1];
		const summed = new AgtAkumaPrimeKwVM(last.agt, []);
		summed.kw = last.kw;
		summed.agtId = last.agtId;
		summed.items = items.map((i) => i.items).flat();

		AkumaPrimeSchema.forEach((s) => {
			if (s.type === AkumaPrimeType.einordnung) {
				summed.einordnung[s.key] = 0;
				items.forEach((item) => {
					summed.einordnung[s.key] += item.einordnung[s.key] || 0;
				});
			}
			if (s.type === AkumaPrimeType.beziehung) {
				summed.beziehung[s.key] = 0;
				items.forEach((item) => {
					summed.beziehung[s.key] += item.beziehung[s.key] || 0;
				});
			}
			if (s.type === AkumaPrimeType.terminierung) {
				summed.terminierung[s.key] = 0;
				items.forEach((item) => {
					summed.terminierung[s.key] += item.terminierung[s.key] || 0;
				});
			}
			if (s.type === AkumaPrimeType.terminierungErgebnis) {
				summed.terminierungErgebnis[s.key] = 0;
				items.forEach((item) => {
					summed.terminierungErgebnis[s.key] += item.terminierungErgebnis[s.key] || 0;
				});
			}
		});

		return summed;
	}
}

// ******************************************************************************************

export interface IAkumaPrimeDef {
	label: string;
	sort: number;
	key: string;
	path: string;
	type: AkumaPrimeType;
	color: string;
}

// Einordnung
// Rang	ID	Label
// 1	S	Sach
// 2	L	Leben
// 3	K	Kranken
// 4	SO	Sonstige
// 5	NK	Nicht Kontaktieren
// 6	NE	Nicht eingeordnet
// 7	BA	Bestehende Aktion

// Terminierung
// Rang	ID	Label
// 1	T	Termin
// 2	KI	Kein Interesse
// 3	KNE	Kunde nicht erreicht
// 4	KNK	Kunde nicht kontaktiert

// Beziehung
// Rang	ID	Label
// 1	BZU	Beziehung: unbekannt/keine info
// 2	BZWG Beziehung: weniger gut
// 3	BZM	Beziehung: mittel
// 4	BZG	Beziehung: gut
// 5	BZD	Beziehung: Default

// Zentrale Nachbearbeitung
// Rang	ID	Label
// 1	1	Nachbeiarbeitet
// 2	0	Nicht nachbearbeitet

export const AkumaPrimeSchema: IAkumaPrimeDef[] = [
	// Einordnung
	{
		label: 'Sach',
		sort: 1,
		key: 'S',
		type: AkumaPrimeType.einordnung,
		path: 'einordnungSach',
		color: colors.yellow,
	},
	{
		label: 'Leben',
		sort: 2,
		key: 'L',
		type: AkumaPrimeType.einordnung,
		path: 'einordnungLeben',
		color: colors.blue,
	},
	{
		label: 'Kranken',
		sort: 3,
		key: 'K',
		type: AkumaPrimeType.einordnung,
		path: 'einordnungKranken',
		color: colors.green,
	},
	{
		label: 'Sonstige',
		sort: 4,
		key: 'SO',
		type: AkumaPrimeType.einordnung,
		path: 'einordnungSonstige',
		color: colors.orange,
	},
	{
		label: 'Nicht Kontaktieren',
		sort: 5,
		key: 'NK',
		type: AkumaPrimeType.einordnung,
		path: 'einordnungNichtKontaktieren',
		color: colors.richRed,
	},
	{
		label: 'Bestehende Aktion',
		sort: 6,
		key: 'BA',
		type: AkumaPrimeType.einordnung,
		path: 'einordnungBestehendeAktion',
		color: colors.purple,
	},
	{
		label: 'Nicht eingeordnet',
		sort: 7,
		key: 'NE',
		type: AkumaPrimeType.einordnung,
		path: 'einordnungNichtEingeordnet',
		color: colors.greyLight,
	},

	// Beziehung
	{
		label: 'Beziehung: unbekannt/keine info',
		sort: 1,
		key: 'BZU',
		type: AkumaPrimeType.beziehung,
		path: 'beziehungUnbekannt',
		color: colors.yellow,
	},
	{
		label: 'Beziehung: weniger gut',
		sort: 2,
		key: 'BZWG',
		type: AkumaPrimeType.beziehung,
		path: 'beziehungWenigerGut',
		color: colors.richRed,
	},
	{
		label: 'Beziehung: mittel',
		sort: 3,
		key: 'BZM',
		type: AkumaPrimeType.beziehung,
		path: 'beziehungMittel',
		color: colors.richBlue,
	},
	{
		label: 'Beziehung: gut',
		sort: 4,
		key: 'BZG',
		type: AkumaPrimeType.beziehung,
		path: 'beziehungGut',
		color: colors.green,
	},

	// Terminierung intern/extern
	// ziel: this.prime.zuKontaktierendeKunden,
	// ergebnis: this.prime.terminierungExternTotal,
	{
		label: 'Zu kontaktieren',
		sort: 1,
		key: 'ZK',
		type: AkumaPrimeType.terminierung,
		path: 'zuKontaktierendeKunden',
		color: colors.purple,
	},
	{
		label: 'Terminierung durch Agentur',
		sort: 1,
		key: 'AGT',
		type: AkumaPrimeType.terminierung,
		path: 'terminierungAgentur',
		color: colors.darkYellow,
	},
	// {
	// 	label: 'Externe Terminierung',
	// 	sort: 2,
	// 	key: 'CC',
	// 	type: AkumaPrimeType.terminierung,
	// 	path: 'terminierungExtern',
	// 	color: colors.richTurquoise,
	// },
	{
		label: 'Externe Terminierung',
		sort: 2,
		key: 'CC',
		type: AkumaPrimeType.terminierung,
		path: 'terminierungExternTotal',
		color: colors.richTurquoise,
	},
	// Externe terminierung ergebnis
	{
		label: 'Kein Interesse',
		sort: 1,
		key: 'TKI',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTKI',
		color: colors.richRed,
	},
	{
		label: 'Falsche Telefonnummer',
		sort: 1,
		key: 'TFT',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTFT',
		color: colors.warning,
	},
	{
		label: 'Keine E-Mail in Bookingtime',
		sort: 1,
		key: 'TKE',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTKE',
		color: colors.darkYellow,
	},
	{
		label: 'Bereits im Gespräch',
		sort: 1,
		key: 'TBIG',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTBIG',
		color: colors.richBlue,
	},
	{
		label: 'Andere Hauptbetreuung',
		sort: 1,
		key: 'TAHB',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTAHB',
		color: colors.brown,
	},
	{
		label: 'Nicht Erreicht',
		sort: 1,
		key: 'TNE',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTNE',
		color: colors.red,
	},
	{
		label: 'Redet nur mit Agentur',
		sort: 1,
		key: 'TNA',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTNA',
		color: colors.turquoise,
	},
	{
		label: 'Wünscht keinen aktiven Kontakt',
		sort: 1,
		key: 'TKK',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTKK',
		color: colors.purple,
	},
	{
		label: ' Redet nur mit VT',
		sort: 1,
		key: 'TVT',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTVT',
		color: colors.richTurquoise,
	},
	{
		label: 'Termin vereinbart',
		sort: 1,
		key: 'TTV',
		type: AkumaPrimeType.terminierungErgebnis,
		path: 'terminErgebnisTTV',
		color: colors.success,
	},
];

export interface IAkumaPrimeVal extends IAkumaPrimeDef {
	value: number;
	percent: number;
	groupTotal: number;
}
export class AkumaPrimeVal implements IAkumaPrimeVal {
	constructor(opts: any) {
		this.label = opts.label;
		this.sort = opts.sort;
		this.key = opts.path;
		this.type = opts.type;
		this.path = opts.path;
		this.color = opts.color;
		this.groupTotal = opts.groupTotal;
		this.value = opts.value;
	}

	label: string;
	sort: number;
	key: string;
	color: string;
	path: string;
	type: AkumaPrimeType;
	value: number;
	groupTotal: number;

	@computed
	get percent() {
		if (this.groupTotal === 0) {
			return 0;
		}
		return (this.value / this.groupTotal) * 100;
	}

	static getBnrAvg = (path: string, items: AgtAkumaPrimeKwVM[]) => {
		const count = items.length;
		let sum,
			avg = 0;
		sum = Math.round(_.sumBy(items, path + '.percent'));
		avg = sum / count;

		const def = AkumaPrimeSchema.find((x) => x.path === path);
		const pv = new AkumaPrimeVal(def);
		pv.value = avg;
		return pv;
	};
}

export class AgtAkumaPrimeUiStore extends BaseUiStore<AgtAkumaPrimeKwVM> {
	name = 'AgtAkumaPrimeUiStore';
	agenturStore: AgenturStore;
	bnrStatsStore: BnrStatsStore;
	kvAgenturYearAkumaPrimeStore: KvAgenturYearAkumaPrimeStore;

	constructor(agenturStore: AgenturStore, bnrStatsStore: BnrStatsStore, kvAgenturYearAkumaPrimeStore: KvAgenturYearAkumaPrimeStore) {
		super();
		makeObservable(this);
		this.kvAgenturYearAkumaPrimeStore = kvAgenturYearAkumaPrimeStore;
		this.agenturStore = agenturStore;
		this.bnrStatsStore = bnrStatsStore;
	}
	onLogout() {
		this.kvAgenturYearAkumaPrimeStore.onLogout();
		this.agenturStore.onLogout();
		this.bnrStatsStore.onLogout();
	}

	filterByAgtId(agtId: number) {
		return this.items.filter((i) => i.agtId === agtId);
	}

	filterByAgtIdandKw(agtId: number) {
		let items = this.items.filter((i) => i.agtId === agtId);
		items = items.filter((i) => this.currentKWs.includes(i.kw));
		return items;
	}

	@observable
	data: AkumaPrimeModel[] = [];

	@observable
	dataFilterable: any[] = [];

	@observable
	showGraphs: boolean = false;

	@observable
	kws: number[] = [];

	@computed
	get kwsOpts(): ISelectOpt[] {
		return this.kws.map((kw) => {
			return {
				value: kw,
				label: formatKw(kw),
			};
		});
	}

	@computed
	get minKw(): number {
		if (this.minKwCurrent) {
			return this.minKwCurrent;
		}
		if (this.kws.length) {
			return this.kws[this.kws.length - 5];
		}
		return 0;
	}

	@computed
	get maxKw(): number {
		if (this.maxKwCurrent) {
			return this.maxKwCurrent;
		}
		if (this.kws.length) {
			return this.kws[this.kws.length - 1];
		}
		return 0;
	}

	@computed
	get firstWeek() {
		return this.kws[0];
	}
	@computed
	get lastWeek() {
		return this.kws[this.kws.length - 2];
	}

	@observable
	minKwCurrent?: number;

	@observable
	maxKwCurrent?: number;

	@observable
	chartKey = 'charty';

	@computed
	get currentKWs() {
		const res: number[] = [];
		this.kws.forEach((kw) => {
			if (this.minKw && kw >= this.minKw) {
				if (this.maxKw && kw <= this.maxKw) {
					res.push(kw);
				}
			}
			if (!this.minKw) {
				// this case is needed for the go live for the first 4 weeks
				if (this.maxKw && kw <= this.maxKw) {
					res.push(kw);
				}
			}
		});
		return res;
	}

	@computed
	get currentKWItems() {
		const items = this.items.filter((i) => this.currentKWs.includes(i.kw));
		const groupedByAgt = _.chain(items)
			.groupBy('agtId')
			.map((value, key) => {
				return value;
			})
			.value();

		return groupedByAgt.map((i) => {
			return AgtAkumaPrimeKwVM.sumFromKwAgts(i);
		});
	}

	@computed
	get currentKWData() {
		const kwData = this.items.filter((i) => this.currentKWs.includes(i.kw));
		return kwData;
	}

	checkAgtParticipation(agtId: number) {
		const agtItem = this.filterByAgtId(agtId);
		// filter by lastWeek
		const participatedLastWeek = agtItem.filter((i) => i.kw === this.lastWeek);
		return participatedLastWeek.length > 0;
	}

	@action
	async _baseLoad() {
		if (this.agenturStore.session.isAdmin) {
			this.showGraphs = false;
		} else {
			this.showGraphs = true;
		}
		this.loadingPromise = new Promise((resolve) => {
			this.loadingPromiseResolver = resolve;
		});
		const promises = await Promise.all([this.kvAgenturYearAkumaPrimeStore.findAll(), this.agenturStore.findAll()]);
		const data = promises[0];
		let agts = promises[1].slice();

		this.data = data;

		runInAction(() => {
			this.kws = _.uniq(_.map(data, 'kw')).sort();
			this.items = [];
		});

		const groupedByAgt = _.chain(data)
			.groupBy('agtId')
			.map((value, key) => {
				return value;
			})
			.value();

		groupedByAgt.forEach((items) => {
			let agt: any;
			[agt, agts] = this.findAndSplice(agts, (a) => a.agtId === items[0].agtId);
			if (!agt) {
				// console.warn('could not find agt with id ' + items[0].agtId);
				return;
			}
			const byKW = _.chain(items)
				.groupBy('kw')
				.map((value, key) => {
					return value;
				})
				.value();

			const kws = byKW.map((kwItems) => new AgtAkumaPrimeKwVM(agt!, kwItems));
			runInAction(() => {
				this.items = this.items.concat(kws);
			});
		});
		const max = _.maxBy(data, 'kw');
		if (max) {
			this.datenStand = max.kw;
		}
	}
}
