import { useStore } from 'app/context';
import { GPMAXYEAR } from 'app/stores/ui/berichte.ui.store';
import { formatBM, formatKwArray } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';


interface IDatenStand {
	year: number;
}



export const DatenStandFitnessTracker = observer(() => {

	return <>{<>Stand: Siehe einzelne Wachstumsdimensionen</>}</>;

});


export const DatenStandPosZiel = observer((props: IDatenStand) => {

	if (props.year === GPMAXYEAR) {
		return <DatenStand dataType='aup_gp' />;
	}
	return <DatenStand dataType='aup_vorjahr' />;

});

export const DatenStandDigiFaktor = observer(() => {
	const { agtDigigFaktorStore } = useStore();

	return <>{agtDigigFaktorStore.datenStand && <>Stand: {formatBM(agtDigigFaktorStore.datenStand)}</>}</>;
});

export const DatenStandAkumaPrime = observer(() => {
	const { agtAkumaPrimeUiStore } = useStore();

	return <>{agtAkumaPrimeUiStore.currentKWs && <>Stand: KW {formatKwArray(agtAkumaPrimeUiStore.currentKWs)}</>}</>;
});



interface IDatenStandGen {
	dataType: string
}

export const DatenStand = observer((props: IDatenStandGen) => {
	const { datenStandStore } = useStore();
	const [dt, setDt] = useState<string>();
	useEffect(() => {
		datenStandStore.findAll().then(items => {
			const stand = items.find(i => i.dataType === props.dataType);
			if (stand && stand.standFormated) {
				setDt(stand.standFormated)
			}
		})

	}, [datenStandStore._items])

	return <>{<>Stand: {dt}</>}</>;
});