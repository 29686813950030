import { formatPercent } from 'app/utils';
import { observer } from 'mobx-react';

interface IPercentAmpel {
	yellowMin?: number;
	greenMin: number;
	value: number;
}
export const PercentAmpel = observer((props: IPercentAmpel) => {
	const { value, yellowMin, greenMin } = props;

	let color = 'color-error';
	if (yellowMin && value >= yellowMin) {
		color = 'color-warning';
	}
	if (value >= greenMin) {
		color = 'color-success';
	}

	return (
		<div className={`percent-ampel`}>
			<span className={`circle ${color}`}>&#11044;</span>
			<span className="value"> {formatPercent(props.value)}%</span>
		</div>
	);
});
