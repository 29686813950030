import { AgenturModel } from 'app/models/agentur.model';
import _ from 'lodash';
import { computed, runInAction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { KvAgtMsClusterStore } from '../kv.agt.cluster.store';
import { BaseUiStore } from './base.ui.store';
import { KvAgtMsLiteModel } from 'app/models/kv.agt.ms.lite.model';

export class AgtClusterVM {
	constructor(agt: AgenturModel, items: KvAgtMsLiteModel[]) {
		this.agt = agt;
		if (items.length === 0) {
			this.bm = 0;
			this.agtId = 0;
			// this.bnrId = 0;
			return;
		}
		this.bm = items[0].bm;
		this.agtId = items[0].agtId;

		const item = items[0];
		this.betreuungUb = item.j ? item.j.UB : undefined;
		this.betreuungBbs = item.j ? item.j.BBS : undefined;
		this.betreuungKme = item.j ? item.j.KMW : undefined;
		this.betreuungKvs = item.j ? item.j.KVS : undefined;
		this.betreuungVas = item.j ? item.j.VAS : undefined;
		this.betreuungBsg = item.j ? item.j.BSG : undefined;
		this.betreuungVbl = item.j ? item.j.VBL : undefined;


		const cols = ['betreuungUb', 'betreuungBbs', 'betreuungKme', 'betreuungKvs', 'betreuungVas', 'betreuungBsg', 'betreuungVbl'];

		let sum = 0;
		let count = 0;
		cols.forEach((c) => {
			const val = _.get(this, c);
			if (val && val > 0) {
				sum += val;
				count++;
			}
		});
		const avg = sum / count;
		this.avg = Math.round(avg * 10) / 10;
	}

	bm: number;
	agtId: number;
	agt: AgenturModel;
	// bnrId: number;
	betreuungUb?: number;
	betreuungBbs?: number;
	betreuungKme?: number;
	betreuungKvs?: number;
	betreuungVas?: number;
	betreuungBsg?: number;
	betreuungVbl?: number;

	avg?: number;
}

interface IClusterCol {
	name: string;
	sort: number;
	path: string;
}

export class AgtClusterUiStore extends BaseUiStore<AgtClusterVM> {
	name = 'AgtClusterUiStore';
	kvAgtMsClusterStore: KvAgtMsClusterStore;
	agenturStore: AgenturStore;

	constructor(agenturStore: AgenturStore, kvAgtMsClusterStore: KvAgtMsClusterStore) {
		super();
		this.kvAgtMsClusterStore = kvAgtMsClusterStore;
		this.agenturStore = agenturStore;
	}

	@computed
	get steartCols() {
		let cols: IClusterCol[] = [
			{
				name: 'UB',
				sort: 10,
				path: 'betreuungUb',
			},
			{
				name: 'BBS',
				sort: 7,
				path: 'betreuungBbs',
			},
			{
				name: 'KME',
				sort: 6,
				path: 'betreuungKme',
			},
			{
				name: 'KVS',
				sort: 5,
				path: 'betreuungKvs',
			},
			{
				name: 'VAS',
				sort: 4,
				path: 'betreuungVas',
			},
			{
				name: 'BSG',
				sort: 3,
				path: 'betreuungBsg',
			},
			{
				name: 'VBL',
				sort: 1,
				path: 'betreuungVbl',
			},
		];

		for (const col of cols) {
			if (col.name === this.agenturStore.session.currentSteart!.name) {
				col.sort = 0;
			}
		}

		return _.sortBy(cols, 'sort');
	}

	onLogout() {
		this.kvAgtMsClusterStore.onLogout();
		this.agenturStore.onLogout();
	}

	async filterByAgtId(agtId: number) {
		await this.load();
		return this.items.filter((i) => i.agtId === agtId);
	}

	async filterByAgtIdAndYear(agtId: number, year: number) {
		await this.load();
		return this.items.filter((i) => i.agtId === agtId && i.bm === year);
	}

	years: number[] = [];

	async findAllByYear(year: number) {
		await this.load();
		return this.items.filter((i) => i.bm === year);
	}

	async _baseLoad() {

		const data = await this.kvAgtMsClusterStore.findAll();
		const agts = await this.agenturStore.findAll();

		this.years = _.uniq(_.map(data, 'bm')).sort();
		const groupedByAgt = _.chain(data)
			.groupBy('agtId')
			.map((value, key) => {
				return value;
			})
			.value();

		let res: AgtClusterVM[] = [];

		groupedByAgt.forEach((items) => {
			const agt = agts.find((a) => a.agtId === items[0].agtId);
			if (!agt) {
				// console.warn('agt.digifaktorui.store.load() could not find agt with id' + items[0].agtId);
				return;
			}
			const byBM = _.chain(items)
				.groupBy('bm')
				.map((value, key) => {
					return value;
				})
				.value();
			const bms = byBM.map((bmItems) => new AgtClusterVM(agt!, bmItems));
			res = res.concat(bms);
		});
		const max = _.maxBy(data, 'bm');
		if (max) {
			this.datenStand = max.bm;
		}
		runInAction(() => {
			this.items = res;
		});

	}
}
