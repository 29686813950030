import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AgtFitnessVm } from 'app/stores/ui/agt.fitness.ui.store';

export const FitnessTrackerListeGsExport = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const tm = useMemo(() => {
		const tm = new TableModel<AgtFitnessVm, number>();
		const cols: ITableColumn<AgtFitnessVm, number>[] = [];

		const avgAggOnly = () => {
			return [
				{
					aggType: AggregrationType.avg,
				},
				{
					aggType: AggregrationType.count,
				},

			]
		}

		const sumAvg = () => {
			return [
				{
					aggType: AggregrationType.sum,
				},
				{
					aggType: AggregrationType.avg,
				},
			]
		}



		cols.push({
			label: 'vd',
			path: 'agt.vdId',
			render: (row) => {
				return <>{row.data.agt.vdId}  <strong>&nbsp;&nbsp;</strong></>
			}
		});
		cols.push({
			label: 'gs',
			path: 'agt.gsId',
			// agg: [
			// 	{
			// 		aggType: AggregrationType.countDistinct,
			// 	}
			// ]
		});

		// data exists 
		cols.push(
			{
				label: 'googleExists',
				path: 'googleExists',
				agg: sumAvg()


			},
		);
		cols.push(
			{
				label: 'amisNowExists',
				path: 'amisNowExists',
				agg: sumAvg()
			},
		);
		cols.push(
			{
				label: 'planerExists',
				path: 'planerExists',
				agg: sumAvg()
			},
		);
		cols.push(
			{
				label: 'primeExists',
				path: 'primeExists',
				agg: sumAvg()
			},
		)
		cols.push(
			{
				label: 'mazExists',
				path: 'mazExists',
				agg: sumAvg()
			},
		)



		// GOOGLE
		cols.push(
			...[
				{
					label: 'googleRank',
					path: 'gup.rank',
					agg: avgAggOnly()
				},
				{
					label: 'gupAnzahlBewertungen',
					path: 'anzBew.ergebnis',
					agg: avgAggOnly()
				},
				{
					label: 'gupAnzahlBewertungenZiel',
					path: 'anzBew.ziel',
				},
				{
					label: 'gupBewertungDurchschnitt',
					path: `bewAvg.ergebnis`,
					agg: avgAggOnly()
				},
				{
					label: 'gupBewertungDurchschnittZiel',
					path: `bewAvg.ziel`
				},
				{
					label: 'gupMedia',
					path: `imgCount.ergebnis`,
					agg: avgAggOnly()

				},
				{
					label: 'gupMediaZiel',
					path: `imgCount.ziel`,
				},
				{
					label: 'gupFA',
					path: `qCount.ergebnis`,
					agg: avgAggOnly()

				},
				{
					label: 'gupFAZiel',
					path: `qCount.ziel`,
				},
			].map((c) => ({
				...c,
				labelModifier: 'bg-green',
				cellModifier: 'bg-green',
			})),
		);

		/// AMIS.NOW
		cols.push({
			label: 'telefonieNachrichtVonKvmHalle',
			path: `amisNow.item.telefonieNachrichVonKvmHalle`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'aufgabeAusAkuma',
			path: `amisNow.item.aufgabeAusAkuma`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'kundennachrichtVertriebschance',
			path: `amisNow.item.kundennachrichtVertriebschance`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'kuendigungsinformation',
			path: `amisNow.item.kuendigungsinformation`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'aufgabeAusElektronischerPostkorb',
			path: `amisNow.item.aufgabeAusElektronischerPostkorb`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'aufgabeAusAmisnowPostkorb',
			path: `amisNow.item.aufgabeAusAmisnowPostkorb`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'freigabeAngebot',
			path: `amisNow.item.freigabeAngebot`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'kundennachrichtServiceanliegen',
			path: `amisNow.item.kundennachrichtServiceanliegen`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'meineAllianzNachricht',
			path: `amisNow.item.meineAllianzNachricht`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'telefonieNachrichtVonKvmHalleInteressent',
			path: `amisNow.item.telefonieNachrichtVonKvmHalleInteressent`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'kundennachrichtVertriebschanceWiederanlage',
			path: `amisNow.item.kundennachrichtVertriebschanceWiederanlage`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'eigeneAufgaben',
			path: `amisNow.item.eigeneAufgaben`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'summe',
			path: `amisNow.item.summe`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'davonRead',
			path: `amisNow.item.davonRead`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		cols.push({
			label: 'quoteRead',
			path: `amisNow.item.quoteRead`,
			cellModifier: 'bg-blue',
			labelModifier: 'bg-blue',
			agg: avgAggOnly()
		});

		/// AKUMA
		cols.push({
			label: 'isAkumaPrimeUser',
			path: `isAkumaPrimeUser`,
			labelModifier: 'bg-yellow',
			cellModifier: 'bg-yellow',
			agg: sumAvg()
		});

		//  --- PLANER ---
		cols.push(
			...[
				{
					label: 'planerAnzahlWochenNutzung',
					path: `planerAnzahlWochenNutzung.ergebnis`,
					agg: avgAggOnly()
				},
				{
					label: 'planerUebertragungZaehler',
					path: `planerUebertragungquoteAmisNow.ergebnis`,
					agg: avgAggOnly()
				},
				{
					label: 'planerUebertragungNenner',
					path: `planerUebertragungquoteAmisNow.ziel`,
					agg: avgAggOnly()
				},
				{
					label: 'planerUebertragungQuote',
					path: `planerUebertragungquoteAmisNow.ze`,
					agg: avgAggOnly()
				},
			].map((c) => ({
				...c,
				labelModifier: 'bg-yellow',
				cellModifier: 'bg-yellow',
			})),
		);

		//  --- PRIME ---
		cols.push(
			...[
				{
					label: 'primeEinordnungZaehler',
					path: `primeEinordnungsquote.ergebnis`,
					agg: avgAggOnly()
				},
				{
					label: 'primeEinordnungNenner',
					path: `primeEinordnungsquote.ziel`,
					agg: avgAggOnly()
				},
				{
					label: 'primeEinordnungQuote',
					path: `primeEinordnungsquote.ze`,
					agg: avgAggOnly()
				},
				{
					label: 'primeAbwahlquoteZaehler',
					path: `primeAbwahlquote.ergebnis`,
					agg: avgAggOnly()
				},
				{
					label: 'primeAbwahlquoteNenner',
					path: `primeAbwahlquote.ziel`,
					agg: avgAggOnly()
				},
				{
					label: 'primeAbwahlQuote',
					path: `primeAbwahlquote.ze`,
					agg: avgAggOnly()
				},
				{
					label: 'primeTerminierungExternZaehler',
					path: 'primeTerminierungExtern.ergebnis',
					agg: avgAggOnly()
				},
				{
					label: 'primeTerminierungExternNenner',
					path: `primeTerminierungExtern.ziel`,
					agg: avgAggOnly()
				},
				{
					label: 'primeTerminierungExternQuote',
					path: 'primeTerminierungExtern.ze',
					agg: avgAggOnly()
				},
			].map((c) => ({
				...c,
				labelModifier: 'bg-yellow',
				cellModifier: 'bg-yellow',
			})),
		);

		/// Meine Allianz
		cols.push(
			...[
				{
					label: 'mazBestand',
					path: `mazBestandDurchdringungsquote.ergebnis`,
					agg: avgAggOnly()
				},
				{
					label: 'mazBestandZiel',
					path: `mazBestandDurchdringungsquote.ziel`,
					agg: avgAggOnly()
				},
				{
					label: 'mazNeugeschaeft',
					path: `mazNeuDurchdringungsquote.ergebnis`,
					agg: avgAggOnly()
				},
				{
					label: 'mazNeugeschaeftZiel',
					path: `mazNeuDurchdringungsquote.ziel`,
					agg: avgAggOnly()
				},
			].map((c) => ({
				...c,
				labelModifier: 'bg-blue',
				cellModifier: 'bg-blue',
			})),
		);

		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.idProperty = 'agtId';
		tm.idType = 'number';

		return tm;
	}, []);

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			// tm.setRowData(items);
			tm.setGroupByData(items, (i: AgtFitnessVm) => i.agt.vdId);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Fitnesstrackerdaten gefunden</div>
			) : (
				<>
					<Table stickyHeader={true} tm={tm} allowCsvExport={true} csvExportName="fitness-tracker-export.csv"></Table>
				</>
			)}
		</>
	);
});
