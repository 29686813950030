import React, { ReactNode, useEffect, useRef } from 'react';

interface IVisibleObserver {
	onVisible: () => void;
	children?: ReactNode;
}

const VisibleObserver: React.FC<IVisibleObserver> = (props: IVisibleObserver) => {
	const componentRef = useRef(null);
	const { children, onVisible } = props;

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries, observerInstance) => {
				const [entry] = entries; // We only have one target element

				if (entry.isIntersecting) {
					// Fire the callback when component is visible
					onVisible();

					// Stop observing once the component has been seen
					observerInstance.unobserve(componentRef.current!);
				}
			},
			{
				threshold: 0.1, // 10% of the element must be visible to trigger
			},
		);

		if (componentRef.current) {
			observer.observe(componentRef.current);
		}

		return () => {
			// Cleanup the observer when the component unmounts
			if (componentRef.current) {
				observer.unobserve(componentRef.current);
			}
		};
	}, [onVisible]);

	return (
		<div ref={componentRef} className="visible-observer">
			{children}
		</div>
	);
};

export default VisibleObserver;
