import { computed, makeObservable, observable } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class AgtPlanMetaData {
	// metadata: "{\"jpgDocId\":null,\"apDocId\":null,\"vorbereitet\":false}"
	constructor(opts: any) {
		makeObservable(this);
		this.jpgDocId = opts.jpgDocId;
		this.apDocId = opts.apDocId;
		this.vorbereitet = opts.vorbereitet;
	}

	@observable
	jpgDocId?: string;
	@observable
	apDocId?: string;
	@observable
	vorbereitet?: boolean;

	toJSON() {
		return JSON.stringify({
			jpgDocId: this.jpgDocId,
			apDocId: this.apDocId,
			vorbereitet: this.vorbereitet,
		});
	}

	static fromJson(j?: string) {
		if (!j || j === '') {
			return new AgtPlanMetaData({});
		}
		const o = JSON.parse(j);
		return new AgtPlanMetaData(o);
	}
}

export class AgtPlanThemen {
	constructor(themen: any, agtId: number, year: number) {
		this.agtId = agtId;
		this.planYear = year;
		if (!themen || themen === '') {
			this.themen = [];
			return;
		}
		const items = JSON.parse(themen);
		this.themen = items.map((i: any) => new AgtPlanThemaModel(i));
	}
	agtId: number;
	planYear: number;

	@observable
	themen: AgtPlanThemaModel[];

	serialize() {
		return this.themen.map((t) => t.serialize());
	}
}

export class AgtPlanThemaModel {
	//[{"title":"Qualifikation/Weiterbildung","key":"qualifikationweiterbildung"},{"title":"Schwerpunktagentur","key":"schwerpunktagentur"},{"title":"Sonstiges","key":"sonstiges"}]
	constructor(opts: any) {
		this.title = opts.title;
		this.key = opts.key;
	}
	title: string;
	key: string;

	serialize() {
		return {
			title: this.title,
			key: this.key,
		};
	}
}

export class AgtPlanModel {
	constructor(opts: any) {
		makeObservable(this);
		this.id = opts.id;
		this.agtId = opts.agtId;
		this.bnrId = opts.bnrId;
		this.title = opts.title;
		this.metadata = AgtPlanMetaData.fromJson(opts.metadata);
		if (opts.ziele && opts.ziele !== '') {
			this.ziele = opts.ziele.split(',');
		}
		this.themen = new AgtPlanThemen(opts.themen, this.agtId, this.year);
		this.shared = opts.shared;
		this.created = opts.created;
		this.modified = opts.modified;
	}

	id?: string;
	agtId: number;
	bnrId: number;

	@observable
	title: string;
	@computed
	get year() {
		return parseInt(this.title);
	}

	@observable
	metadata: AgtPlanMetaData;
	@observable
	shared: boolean;
	@observable
	themen: AgtPlanThemen;
	@observable
	ziele: string[] = [];

	created: Date;
	modified: Date;
	serialize() {
		return {
			id: this.id,
			title: this.title,
			agtId: this.agtId,
			bnrId: this.bnrId,
			metadata: this.metadata.toJSON(),
			shared: this.shared,
			themen: JSON.stringify(this.themen.serialize()),
			ziele: this.ziele.join(','),
			created: this.created,
			modified: this.modified,
		};
	}
}

export class AgtPlanStore extends BaseCachedEntityStore<AgtPlanModel> {
	apiPath = 'api/plans';
	idProp: string = 'id';

	disableFindForStearts = [123];

	createInstances(opts: any): AgtPlanModel {
		return new AgtPlanModel(opts);
	}

	async findByAgtIdYear(agtId: number, year: number) {
		const items = await this.findAll();
		return items.find((d) => d.agtId === agtId && d.year === year);
	}

	async findByAgtId(agtId: number) {
		const items = await this.findAll();
		return items.filter((d) => d.agtId === agtId);
	}

	async clearPlanungForTest() {
		await this.get("/clear");
	}
}
