import { UserModel } from 'app/models/core/user.model';
import _ from 'lodash';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import routes from 'routes';
import { AdminBnrTransferStore, BnrTransferModel } from '../admin.bnrtransfer.store';
import { AdminUserStore } from '../admin.user.store';

export class BnrTransferVM {
	constructor(user: BnrTransferModel, targetUser: UserModel) {
		makeObservable(this);
		this.transfer = user;
		this.targetUser = targetUser;
	}
	@observable
	transfer: BnrTransferModel;
	@observable
	targetUser: UserModel;

	@computed
	get fullText() {
		let s = '';
		if (this.targetUser.name) {
			s += this.targetUser.name.toLowerCase();
		}
		if (this.targetUser.email) {
			s += this.targetUser.email.toLowerCase();
		}
		if (this.targetUser.bensl) {
			s += ' ' + this.targetUser.bensl.toLowerCase();
		}
		if (this.targetUser.bnrId) {
			s += ' ' + this.targetUser.bnrId;
		}
		if (this.targetUser.comment) {
			s += ' ' + this.targetUser.comment;
		}

		s += this.transfer.id;
		s += ' ' + this.transfer.sourceComment;
		s += ' ' + this.transfer.sourceName;
		s += ' ' + this.transfer.sourceBnrId;

		s += ' ' + this.transfer.targetComment;
		s += ' ' + this.transfer.targetBnrId;
		s += ' ' + this.transfer.adminComment;

		return s;
	}

	@computed
	get overAllState() {
		if (this.transfer.transferDate) {
			return 'Abgeschloßen';
		}
		if (this.transfer.state === 'approved') {
			return 'Nutzer hat besätigt';
		}

		if (this.transfer.state === 'rejected') {
			return 'Nutzer hat abgelehnt';
		}
		return 'Nicht definiert';
	}

	@computed
	get hasTransfered() {
		return !!this.transfer.transferDate;
	}

	@computed
	get canSendTransferRequestMail() {
		if (this.transfer.state === 'rejected') {
			return false;
		}
		if (this.transfer.anonHash && this.transfer.sourceBnrId && this.transfer.targetBnrId) {
			return true;
		}
		return false;
	}

	@computed
	get emailBody() {
		const targetName = this.targetUser.firstName + ' ' + this.targetUser.lastName;
		const serverUrl = window.location.origin;
		const p = routes.BNRTRANSFER.getPath(this.transfer.anonHash, this.transfer.sourceBnrId.toString(), this.transfer.targetBnrId.toString());
		const link = `${serverUrl}/#${p}`;
		let text = `Hallo,\n\n`;
		text += `Ihr Nachfolger:innen, ${targetName}, würde gerne Ihre agenturbezogen Daten der DVS übernehmen. \n`;
		text += 'Unter folgenden Link können Sie diese Anfrage annehmen oder ablehnen.\n';
		text += 'Bitte klicken Sie auf nachfolgenden Link um Ihre Auswahl zu treffen.\n\n';
		text += link;
		text += '\n\nBei Rückfragen stehen wir Ihnen gerne zur Verfügung. ';
		text += '\n\nBeste Grüße ';
		text += '\nIhr DVS TEAM ';
		return encodeURIComponent(text);
	}
}

export interface INewTransfer {
	sourceBnrId: number;
	targetBnrId: number;
	adminComment: string;
	targetComment: string;
}
export interface ITransferable {
	table: string;
	sum: number;
}

export class AdminBnrTransferUiStore {
	adminBnrTransferStore: AdminBnrTransferStore;
	adminUserStore: AdminUserStore;

	constructor(adminBnrTransferStore: AdminBnrTransferStore, adminUserStore: AdminUserStore) {
		makeObservable(this);
		this.adminBnrTransferStore = adminBnrTransferStore;
		this.adminUserStore = adminUserStore;
	}

	@observable
	fullText: string = '';

	@observable
	hideAutomatedTransfer: boolean = true;

	@observable current?: BnrTransferVM;

	items: IObservableArray<BnrTransferVM> = observable([]);

	@computed
	get filtered() {
		let items = this.items as BnrTransferVM[];

		if (this.hideAutomatedTransfer) {
			items = items.filter((i) => i.transfer.sourceName !== 'userupdate');
		}
		if (this.fullText) {
			const f = this.fullText.toLowerCase();
			items = items.filter((i) => i.fullText.indexOf(f) >= 0);
		}
		return items;
	}

	loaded: boolean = false;

	@action
	async load() {
		if (this.loaded) {
			return this.items;
		}
		const p = await Promise.all([this.adminBnrTransferStore.findAll(), this.adminUserStore.findAll()]);
		const items = p[0];
		const users = p[1];
		const res: BnrTransferVM[] = [];
		for (const t of items) {
			//const u = await this.adminUserStore.findUserByBnrId(t.targetBnrId);
			const u = users.find((u) => u.bnrId === t.targetBnrId);
			if (u) {
				const vm = new BnrTransferVM(t, u);
				res.push(vm);
			} else {
				console.log('unknown user ' + t.targetBnrId);
			}
		}
		runInAction(() => {
			this.items.replace(res);
			this.loaded = true;
		});

		return this.items;
	}

	async loadById(id: string) {
		const items = await this.load();
		const item = items.find((i) => i.transfer.id === id);
		runInAction(() => {
			this.current = item;
			if (item) {
				this.check(item.transfer.sourceBnrId, item.transfer.targetBnrId);
			} else {
				this.currentHasData = false;
			}
		});
	}

	@action
	async newTransfer(opts: INewTransfer) {
		const t = new BnrTransferModel(opts);
		const t2 = await this.adminBnrTransferStore.save(t);
		const u = await this.adminUserStore.findUserByBnrId(t2.targetBnrId);
		if (u) {
			const vm = new BnrTransferVM(t2, u);
			this.items.push(vm);
			return vm;
		}
	}

	async findAll() {
		return this.load();
	}

	async save(vm: BnrTransferVM) {
		const res = await this.adminBnrTransferStore.save(vm.transfer);
		runInAction(() => {
			this.loaded = false;
		});
		await this.load();
		return this.items.find((i) => i.transfer.id === res.id);
	}

	@observable
	currentHasData: boolean = false;

	async check(sourceBnrId: number, targetBnrId: number) {
		const data = await this.adminBnrTransferStore.check(sourceBnrId, targetBnrId);
		const g: ITransferable[] = _.chain(data)
			.groupBy('table')
			.toPairs()
			.map((itms, i) => ({
				table: itms[0],
				sum: _.sumBy(itms[1], (i) => i.count),
			}))
			.value();
		runInAction(() => {
			this.currentHasData = g && g.length > 0;
		});
		return g;
	}
}
