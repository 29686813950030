import { Button } from 'app/components/common/Button';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';

interface IFitnessTrackerPdfDownloadButton {
	agtId: number;
	modifier?: string;
}

export const FitnessTrackerPdfDownloadButton = observer((props: IFitnessTrackerPdfDownloadButton) => {
	const { documentUiStore, uiStore } = useStore();

	const onClick = async () => {
		try {
			const blob = await documentUiStore.bnrUploadStore.downloadFitnessPdf(props.agtId, true);
			const url = URL.createObjectURL(blob!);
			uiStore.setShowPdf(url);
			return;
		} catch (ex) {
			alert('PDF wurde nicht gefunden.');
		}
	};

	return (
		<Button className={`button is-primary is-small ${props.modifier || ''}`} onClick={onClick}>
			Fitness PDF Anzeigen
		</Button>
	);
});


export const FitnessTrackerLeitfadenButton = () => {
	return (
		<a className={`button is-inverted is-small`} href={`Fitness_Leitfaden.pdf`} title={'Leitfaden'} target="_blank" rel="noreferrer">
			Leitfaden Anzeigen
		</a>
		
	);
};
