import { makeObservable, observable, runInAction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { AgenturModel } from 'app/models/agentur.model';
import _ from 'lodash';
import { BaseUiStore } from './base.ui.store';
import { KvAgtMsLiteModel } from 'app/models/kv.agt.ms.lite.model';
import { KvAgtAmisNowStore } from '../kv.agt.amisnow.store';

export class AmisNowModel {
	m: KvAgtMsLiteModel;

	telefonieNachrichVonKvmHalle: number;
	aufgabeAusAkuma: number;
	kundennachrichtVertriebschance: number;
	kuendigungsinformation: number;
	aufgabeAusElektronischerPostkorb: number;
	aufgabeAusAmisnowPostkorb: number;
	freigabeAngebot: number;
	kundennachrichtServiceanliegen: number;
	meineAllianzNachricht: number;
	telefonieNachrichtVonKvmHalleInteressent: number;
	kundennachrichtVertriebschanceWiederanlage: number;
	summe: number;
	davonRead: number;
	quoteRead: number;
	eigeneAufgaben: number;

	constructor(opts: KvAgtMsLiteModel) {
		this.m = opts;
		const j = opts.j;

		this.telefonieNachrichVonKvmHalle = j.telefonie_nachrich_von_kvm_halle;
		this.aufgabeAusAkuma = j.aufgabe_aus_akuma;
		this.kundennachrichtVertriebschance = j.kundennachricht_vertriebschance;
		this.kuendigungsinformation = j.kündigungsinformation;
		this.aufgabeAusElektronischerPostkorb = j.aufgabe_aus_elektronischer_postkorb;
		this.aufgabeAusAmisnowPostkorb = j.aufgabe_aus_amisnow_postkorb;
		this.freigabeAngebot = j.freigabe_angebot;
		this.kundennachrichtServiceanliegen = j.kundennachricht_serviceanliegen;
		this.meineAllianzNachricht = j.meine_allianz_nachricht;
		this.telefonieNachrichtVonKvmHalleInteressent = j.telefonie_nachricht_von_kvm_halle_interessent;
		this.kundennachrichtVertriebschanceWiederanlage = j.kundennachricht_vertriebschance_wiederanlage;
		this.eigeneAufgaben = j.eigene_aufgaben;
		this.summe = j.summe;
		this.davonRead = j.davon_read;
		this.quoteRead = j.quote_read;
	}
}

export class AmisNowVm {
	agt: AgenturModel;
	item?: AmisNowModel;

	constructor(agt: AgenturModel, kv?: KvAgtMsLiteModel) {
		// makeObservable(this);
		this.agt = agt;
		if (kv) {
			this.item = new AmisNowModel(kv);
		}
	}
}

export class AgtAmisNowUiStore extends BaseUiStore<AmisNowVm> {
	name = 'AgtAmisNowUiStore';
	kvAgtAmisNowStore: KvAgtAmisNowStore;
	agenturStore: AgenturStore;

	constructor(agenturStore: AgenturStore, kvAgtAmisNowStore: KvAgtAmisNowStore) {
		super();
		makeObservable(this);
		this.kvAgtAmisNowStore = kvAgtAmisNowStore;
		this.agenturStore = agenturStore;
	}

	@observable
	bmCurrent?: number;

	async _baseLoad() {
		if (this.items.length > 0) {
			return;
		}
		let data = await this.kvAgtAmisNowStore.findAll();
		// this.bms = _.uniq(_.map(data, 'bm')).sort();
		// this.bmCurrent = this.bms[0];
		const agts = await this.agenturStore.findAll();
		const res: AmisNowVm[] = [];
		for (const agt of agts) {
			const kv = data.find((d) => d.agtId === agt.agtId);

			const vm = new AmisNowVm(agt, kv);
			res.push(vm);
		}
		runInAction(() => {
			this.items = res;
		});
	}
}

export const amisNowProps = [
	{
		sort: 0,
		label: 'Telefonie Nachricht von KVM Halle - Kunde',
		path: `amisNow.item.telefonieNachrichVonKvmHalle`,
	},
	{
		sort: 1,
		label: 'Aufgabe aus AKUMA',
		path: `amisNow.item.aufgabeAusAkuma`,
	},
	{
		sort: 2,
		label: 'Kundennachricht Vertriebschance',
		path: `amisNow.item.kundennachrichtVertriebschance`,
	},
	{
		sort: 3,
		label: 'Kündigungs\u00ADinformation', // soft hyphen
		path: `amisNow.item.kuendigungsinformation`,
	},
	{
		sort: 4,
		label: 'Aufgabe aus Elektronischer Postkorb',
		path: `amisNow.item.aufgabeAusElektronischerPostkorb`,
	},
	{
		sort: 5,
		label: 'Aufgabe aus AmisNow Postkorb',
		path: `amisNow.item.aufgabeAusAmisnowPostkorb`,
	},
	{
		sort: 6,
		label: 'Freigabe Angebot',
		path: `amisNow.item.freigabeAngebot`,
	},
	{
		sort: 7,
		label: 'Kundennachricht Serviceanliegen',
		path: `amisNow.item.kundennachrichtServiceanliegen`,
	},
	{
		sort: 8,
		label: 'Meine Allianz Nachricht',
		path: `amisNow.item.meineAllianzNachricht`,
	},
	{
		sort: 9,
		label: 'Telefonie Nachricht von KVM Halle - Interessent',
		path: `amisNow.item.telefonieNachrichtVonKvmHalleInteressent`,
	},
	{
		sort: 10,
		label: 'Kundennachricht Vertriebschance Wiederanlage',
		path: `amisNow.item.kundennachrichtVertriebschanceWiederanlage`,
	},
	{
		sort: 11,
		label: 'Eigene Aufgaben',
		path: `amisNow.item.eigeneAufgaben`,
	},
	{
		sort: 12,
		label: 'Summe',
		path: `amisNow.item.summe`,
	},
	{
		sort: 13,
		label: 'Davon Gelesen',
		path: `amisNow.item.davonRead`,
	},
	{
		sort: 14,
		label: 'Quote Gelesen',
		path: `amisNow.item.quoteRead`,
	},
];
