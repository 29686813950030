import { action, computed, IObservableArray, makeAutoObservable, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { aggregateAvg, aggregateCount, aggregateCountDistinct, aggregatePercentCountOfTotal, aggregateSum } from './table.aggregators';

export enum AggregrationType {
	empty = 'empty',
	avg = 'avg',
	percentCountOfTotal = 'percentCountOfTotal',
	sum = 'sum',
	count = 'count',
	countDistinct = 'countDistinct',
}

export interface ITableAggregateColumn<T, P> {
	render?: (tm: TableModel<T, P>) => JSX.Element;
	path?: string | string[];
	format?: { (val: any): string };
	aggType?: AggregrationType;
	aggIf?: (val: any) => boolean;
	visible?: boolean;
	aggCustomSymbol?: React.ReactNode;
	aggInfo?: React.ReactNode;
	aggModifier?: string;
	rowAgg?: boolean;
}

export class TableAggregrateColumn<T, P> {
	constructor(opts: ITableAggregateColumn<T, P>, tm: TableModel<T, P>) {
		makeAutoObservable(this);
		this.tm = tm;
		this.format = opts.format;
		this.path = opts.path!; // is enforced before constructor
		this.render = opts.render;
		if (opts.aggType) {
			this.aggType = opts.aggType;
		}
		if (opts.aggIf) {
			this.aggIf = opts.aggIf;
		}
		if (opts.visible !== undefined) {
			this.visible = opts.visible;
		}
		if (opts.aggCustomSymbol) {
			this.aggCustomSymbol = opts.aggCustomSymbol;
		}
		if (opts.aggInfo) {
			this.aggInfo = opts.aggInfo;
		}
		if (opts.aggModifier) {
			this.aggModifier = opts.aggModifier;
		}
		if (opts.rowAgg) {
			this.rowAgg = opts.rowAgg;
		}
	}

	aggIf(val: any) {
		if (val > 0) {
			return true;
		}
		return false;
	}

	visible: boolean = true;

	path: string | string[];
	aggType: AggregrationType = AggregrationType.sum;
	aggCustomSymbol?: React.ReactNode;
	aggInfo?: React.ReactNode;
	aggModifier?: string;
	format?: { (val: any): string };
	tm: TableModel<T, P>;
	render?: (tm: TableModel<T, P>, aggValue?: number) => JSX.Element;
	rowAgg: boolean = false;
	doFormat(val: any) {
		if (!this.format) {
			return val;
		}
		return this.format(val);
	}
	@computed
	get aggValue() {
		if (this.aggType === AggregrationType.sum) {
			return aggregateSum(this.tm.data, this.path! as string, this.aggIf);
		}
		if (this.aggType === AggregrationType.avg) {
			return aggregateAvg(this.tm.data, this.path! as string, this.aggIf);
		}
		if (this.aggType === AggregrationType.percentCountOfTotal) {
			return aggregatePercentCountOfTotal(this.tm.data, this.path! as string, this.aggIf);
		}
		if (this.aggType === AggregrationType.count) {
			return aggregateCount(this.tm.data, this.path! as string, this.aggIf);
		}
		if (this.aggType === AggregrationType.countDistinct) {
			return aggregateCountDistinct(this.tm.data, this.path! as string, this.aggIf);
		}
		return undefined;
	}
}

export interface ITableColumn<T, P> {
	label: string | string[];
	icon?: string;
	path: string | string[];
	sortBy?: string | rowSortable<T>;
	editable?: boolean;
	editType?: 'number' | 'checkbox' | 'segment';
	render?: (row: TableRow<T>) => JSX.Element;
	format?: { (val: any): string } | { (val: any): string }[] | { (val: any): JSX.Element } | { (val: any): JSX.Element }[];
	agg?: ITableAggregateColumn<T, P> | ITableAggregateColumn<T, P>[];
	width?: number;
	cellModifier?: string;
	labelModifier?: string;
}

export class TableColumn<T, P> {
	constructor(opts: ITableColumn<T, P>, tm: TableModel<T, P>) {
		makeAutoObservable(this);
		this.label = opts.label;
		this.icon = opts.icon;
		this.format = opts.format;
		this.path = opts.path;
		this.sortBy = opts.sortBy;
		this.width = opts.width;
		this.cellModifier = opts.cellModifier;
		this.labelModifier = opts.labelModifier;

		this.render = opts.render;
		if (opts.agg) {
			if (Array.isArray(opts.agg)) {
				this.aggs = opts.agg.map((o) => {
					if (!o.path) {
						o.path = this.path;
					}
					return new TableAggregrateColumn(o, tm)
				});
			} else {
				this.aggs = [];
				if (!opts.agg.path) {
					opts.agg.path = this.path;
				}
				const agg = new TableAggregrateColumn(opts.agg, tm);
				this.aggs.push(agg);
			}
		}
		if (opts.editable === true) {
			this.editable = true;
			if (opts.editType) {
				this.editType = opts.editType;
			}
		}
	}
	label: string | string[];
	icon?: string;
	cellModifier?: string;
	labelModifier?: string;
	width?: number;
	path: string | string[];
	aggs?: TableAggregrateColumn<T, P>[];
	editType: 'number' | 'checkbox' | 'segment' = 'number';

	@computed
	get keyPath() {
		if (Array.isArray(this.path)) {
			return this.path[0];
		}
		return this.path;
	}

	@computed
	get rowAgg() {
		if (!this.aggs) {
			return;
		}
		if (Array.isArray(this.aggs)) {
			const a = this.aggs.find(a => a.rowAgg);
			if (a) {
				return a;
			}
			return this.aggs[0];
		}
		return this.aggs;

	}

	sortBy?: string | rowSortable<T>;
	format?: { (val: any): string } | { (val: any): string }[] | { (val: any): JSX.Element } | { (val: any): JSX.Element }[];

	doFormat(val: any, index: number) {
		if (!this.format) {
			return val;
		}
		if (Array.isArray(this.format)) {
			let fmt = this.format[index];
			if (!fmt) {
				fmt = this.format[0];
			}
			return fmt(val);
		} else {
			return this.format(val);
		}
	}

	render?: (row: TableRow<T>) => JSX.Element;

	@observable
	editable: boolean = false;
}

export type rowSortable<T> = (data: T) => any

export class TableRow<T> {
	constructor(data: T) {
		makeObservable(this);
		this.data = data;
	}
	@observable data: T;
	@observable selected: boolean = false;
	@observable hovering: boolean = false;

	@observable isGroupStart: boolean = false;
	@observable groupLabel: string = '';

	@action
	setData(path: string, val: any) {
		_.set(this.data as any, path, val);
	}
}

export class TableModel<T, P> {
	constructor() {
		makeObservable(this);
		this.rows = observable([]);
	}
	idProperty: string = 'id';
	idType: 'number' | 'string' = 'number';
	@observable columns: TableColumn<T, P>[] = [];
	@observable rows: IObservableArray<TableRow<T>>;



	renderBatchSize: number = 150;

	@observable
	currentBatchStep: number = 0;

	@action
	showMoreRows() {
		this.currentBatchStep++;
	}

	@computed
	get showingAllRows() {
		return this.visibleRowCount === this.rows.length;
	}

	@computed
	get batchedRows() {
		const batchSize = this.renderBatchSize;
		const batches: TableRow<T>[][] = [];
		if (this.rows.length < batchSize) {
			return [this.rows];
		}
		for (let i = 0; i < this.rows.length; i += batchSize) {
			batches.push(this.rows.slice(i, i + batchSize));
		}
		return batches;
	}

	@computed
	get firstBatch() {
		return this.batchedRows[0];
	}


	@computed
	get otherBatches() {
		return this.batchedRows.slice(1, this.currentBatchStep);
	}

	@computed
	get visibleRowCount() {
		let size = this.batchedRows[0].length;
		this.otherBatches.forEach(b => size += b.length)
		return size;
	}



	_showIndx: boolean = false;

	showHeader: boolean = true;

	groupHeaderRow?: ITableColumn<T, P>;

	@computed
	get showSum() {
		const aggCols = this.columns.filter((c) => c.aggs);
		if (aggCols.length > 0) {
			return true;
		}
		return false;
	}

	@observable selectedId?: P;
	@action
	setSelectedId(id?: P) {
		this.selectedId = id;
		this.doSelectRow();
	}

	@observable hoverId?: P;
	@action
	setHoverId(id?: P) {
		this.hoverId = id;
		this.doHoverRow();
	}

	@observable sortDisabled: boolean = false;
	@observable sortBy?: string | rowSortable<T>;
	@observable sortAsc: boolean = true;

	@computed
	get columnCount() {
		if (this._showIndx) {
			return this.columns.length + 1;
		}
		return this.columns.length;
	}

	@computed
	get showFooter() {
		return this.showSum || this.hasEditbableColumns;
	}

	@observable editDisabled: boolean = false;
	@computed
	get hasEditbableColumns() {
		if (this.editDisabled) {
			return false;
		}
		const cols = this.columns.filter((x) => x.editable);
		if (cols.length > 0) {
			return true;
		}
		return false;
	}

	@observable editMode: boolean = false;
	@action
	setEditMode(val: boolean) {
		this.editMode = val;
	}

	@action
	setSortBy(path: (string | rowSortable<T>)) {
		if (this.sortBy === path) {
			this.sortAsc = !this.sortAsc;
			return;
		}
		this.sortBy = path;
		this.sortAsc = true;
	}

	@action sort() {
		if (!this.sortBy) {
			this.group(this.rows);
			return;
		}
		const s = this.sortBy;

		// let sorted = _.sortBy(this.rows, (r) => {
		// 	const v = _.get(r.data, s);
		// 	return v === undefined || v === null ? undefined : v;
		// });

		const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
		let sorted = this.rows.sort((a, b) => {
			let x, y;

			if (typeof s === 'string') {
				x = _.get(a.data, s);
				y = _.get(b.data, s);
				// handle login date
				if (x instanceof Date) x = x.getTime();
				if (y instanceof Date) y = y.getTime();
			} else {
				x = s(a.data);
				y = s(b.data);
			}


			return collator.compare(x, y);
		});

		if (!this.sortAsc) {
			sorted = _.reverse(sorted);
		}
		this.onSort();
		this.group(sorted);
	}

	@action group(rows: TableRow<T>[]) {
		if (!this.groupHeaderRow) {
			this.rows.replace(rows);
			return;
		}
		const groupPath = this.groupHeaderRow.path;
		rows.forEach((row, i) => {
			row.isGroupStart = true;
			const currVal = _.get(row.data, groupPath);
			row.groupLabel = currVal;
			if (i === 0) {
				return;
			}
			const previousRow = this.rows[i - 1];
			const prevVal = _.get(previousRow.data, groupPath);
			if (currVal === prevVal) {
				row.isGroupStart = false;
			}
		});
		this.rows.replace(rows);
	}

	onSort = () => { };

	@action
	setCols(cols: ITableColumn<T, P>[]) {
		this.columns = [];
		cols.forEach((c) => {
			const tc = new TableColumn<T, P>(c, this);
			this.columns.push(tc);
		});
	}

	@observable data: T[] = [];

	setGroupByData(data: T[], grouper: (i: T) => any) {
		// this.data = data;
		const groupedItems = data.reduce((groups: Record<string, T[]>, item: T) => {
			const id = grouper(item);
			// const gsId = item.agt.gsId;
			if (!groups[id]) {
				groups[id] = [];
			}
			groups[id].push(item);
			return groups;
		}, {});

		const columns = this.columns;
		const rows: TableRow<T>[] = [];
		const newData: any[] = [];
		for (const id in groupedItems) {
			const group = groupedItems[id];
			const groupy = {};
			columns.forEach(col => {
				let val: any;
				const agg = col.rowAgg;
				if (!agg) {
					val = _.get(group[0], col.path);
					_.set(groupy, col.path, val);
					return;
				}
				const path = agg.path as string;
				switch (agg.aggType) {
					case AggregrationType.sum:
						val = aggregateSum(group, path, agg.aggIf);
						break;
					case AggregrationType.avg:
						val = aggregateAvg(group, path, agg.aggIf);
						break;
					case AggregrationType.count:
						val = aggregateCount(group, path, agg.aggIf);
						break;
					case AggregrationType.countDistinct:
						val = aggregateCountDistinct(group, path, agg.aggIf);
						break;
					case AggregrationType.percentCountOfTotal:
						val = aggregatePercentCountOfTotal(group, path, agg.aggIf);
						break;
				}
				if (isNaN(val)) {
					val = 0;
				}
				_.set(groupy, path, val);
			});
			newData.push(groupy);
			const tr = new TableRow<T>(groupy as any);
			rows.push(tr);

		}
		this.data = newData;
		this.rows.replace(rows);
		this.sort();

	}


	@action
	setRowData(data: T[]) {
		this.data = data;
		const rows: TableRow<T>[] = [];
		this.data.forEach((d, i) => {
			const tr = new TableRow<T>(d);
			rows.push(tr);
		});
		this.rows.replace(rows);
		this.sort();
	}

	extraData: any;
	@action
	setExtraData(x: any) {
		this.extraData = x;
	}

	@computed
	get formData() {
		return observable({
			extra: this.extraData,
			data: this.data,
		});
	}

	onRowClick = (row: TableRow<T>) => { };
	onRowShiftClick = (row: TableRow<T>) => { };

	@action
	doSelectRow() {
		const id = this.selectedId;
		this.rows.forEach((d) => {
			runInAction(() => {
				if (this.compareById(d.data, id)) {
					d.selected = true;
					return;
				}
				d.selected = false;
			});
		});
	}

	@action
	doHoverRow() {
		const id = this.hoverId;
		this.rows.forEach((d) => {
			runInAction(() => {
				if (this.compareById(d.data, id)) {
					d.hovering = true;
					return;
				}
				d.hovering = false;
			});
		});
	}

	compareById(row1: T, id?: P) {
		const p = this.idProperty;
		return (row1 as any)[p] === id;
	}
}
