import { useStore } from 'app/context';
import { AupFile } from 'app/stores/admin.imports.store';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import routes from 'routes';
import { notify } from '../common/notify';
import { useHistory } from 'react-router';
import { formatDate, formatDateLong, humanizeFileSize } from 'app/utils';
import { Button } from '../common/Button';
import { observer } from 'mobx-react';
import { ITableColumn, TableModel, TableRow } from '../table/table.model';
import { Table } from '../table/table';
import { useEffect } from 'react';
import { isDvsImportFileName } from 'app/stores/ui/admin.file.upload.ui.store';
import { Icon } from '../common/Icon';
import moment from 'moment';


interface IAdminImportFiles {
	files: AupFile[];
	job: ImportJobVM;
}


export const AdminImportFiles = observer((props: IAdminImportFiles) => {
	const { adminImportsUiStore, uiStore, adminFileUploadUiStore, adminDatenStandStore: datenStandStore } = useStore();
	const history = useHistory();

	const startImportJob = (fileName: string) => {
		const job = adminFileUploadUiStore.prepareJobForTrigger(fileName, props.job.job)
		if (!job) {
			return;
		}
		adminImportsUiStore.triggerJob(job).then((res: any) => {
			history.push(routes.ADMINIMPORTS.getPath('job', res.id));
		});
	};

	const handleDelete = (fileName: string) => {
		if (!window.confirm(`Soll die Datei ${fileName} wirklich gelöscht werden?`)) {
			return;
		}

		notify('Datei wird gelöscht', '', 'info');
		fileName = props.job.job.dataType + '/' + fileName;
		adminImportsUiStore.adminImportsStore.deleteUploadFile(fileName).then(() => {
			window.location.reload();
		});
	};

	const handleRename = (orgFilename: string) => {
		adminFileUploadUiStore.renameFile(props.job.job.dataType, orgFilename).then(() => {
			window.location.reload();
		});
	};

	const tm = new TableModel<AupFile, number>();
	let cols: ITableColumn<AupFile, number>[] = [
		{
			label: 'Name',
			path: 'fileName',
		},
		{
			label: 'Datum',
			path: 'fileDate',
			format: (val) => formatDateLong(val),
		},
		{
			label: 'Größe',
			path: 'fileSize',
			format: (val) => humanizeFileSize(val),
		},
		{
			label: 'DatenStand',
			path: 'datenStand',
			format: formatDate,
			render: (row: TableRow<AupFile>) =>
				<>	{row.data.datenStand && <>{formatDate(row.data.datenStand)}</>} {props.job.job.needsBM && <strong>{moment(row.data.datenStand).format("YYYYMM")}</strong>}
				</>
			,
		},
		{
			label: 'Aktuell',
			path: 'isMostCurrent',
			render: (row: TableRow<AupFile>) =>
				<>	{row.data.isMostCurrent && <Icon iconClass={'check'} />}
				</>
			,
		},
		{
			label: 'Import',
			path: 'fehler',
			render: (row: TableRow<AupFile>) =>
				<Button type="button" className={'button is-primary'} onClick={() => startImportJob(row.data.fileName)} disabled={!isDvsImportFileName(row.data.fileName, props.job.job.dataType)}>
					Importieren
				</Button>,
		},

		{
			label: 'Umbenennen',
			path: 'rename',
			render: (row: TableRow<AupFile>) =>
				<Button type="button" className={'button is-warning'} onClick={() => handleRename(row.data.fileName)}>
					Umbenennen
					{isDvsImportFileName(row.data.fileName, props.job.job.dataType) && <>
						<Icon iconClass={'check'} />
					</>}

				</Button>,
		},

		{
			label: 'Löschen',
			path: 'Delete',
			render: (row: TableRow<AupFile>) =>
				<Button type="button" className={'button is-danger'} onClick={() => handleDelete(row.data.fileName)}>
					Löschen
				</Button>,
		},
	];
	tm.setCols(cols);
	tm.sortBy = 'fileDate';
	tm.sortAsc = false;
	tm.idProperty = 'id';
	tm.idType = 'string';

	useEffect(() => {
		datenStandStore.findAll().then((staende) => {
			const datenStand = staende.find(x => x.dataType === props.job.job.dataType);

			props.files.forEach(f => {
				if (f.fileName === datenStand?.file) {
					f.isMostCurrent = true;
				}

			});
			tm.setRowData(props.files);
		})

	}, [props.files])

	return (
		<div className="pad-top-1rem border-top">
			<strong>Hochgeladene Dateien: </strong>
			<br />
			{props.files.length === 0 && <>Keine Dateien hochgeladen </>}
			<Table stickyHeader={true} tm={tm}></Table>

		</div>
	);
});
