import { action, computed, makeObservable } from 'mobx';
import moment from 'moment';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { AgtGpStore } from './agt.gp.store';
import { SessionStore } from './session.store';
import { BaseEntityStore } from './core/base.entity.store';

export class ZeModel {
	constructor(opts: any) {
		makeObservable(this);
		this.ergebnis = opts.ergebnis;
		this.stichtag = moment(opts.stichtag).toDate();
		this.ziel = opts.ziel;
	}
	stichtag: Date;
	ergebnis: number;
	ziel: number;

	@action
	addZiel(z: number) {
		if (z) {
			this.ziel = this.ziel + z;
		}
	}

	@action
	addErgebnis(e: number) {
		if (e) {
			this.ergebnis = this.ergebnis + e;
		}
	}

	@computed
	get monat() {
		return moment(this.stichtag).month() + 1;
	}

	@computed
	get ze() {
		const z = this.ziel;
		if (z === 0) {
			return 0;
		}
		const e = this.ergebnis;
		let ze = (e / z) * 100;
		ze = Math.round(ze * 100) / 100;
		return ze;
	}

	@computed
	get zeZeit() {
		const z = (this.ziel * this.monat) / 12;
		if (z === 0) {
			return -1;
		}
		const e = this.ergebnis;
		let ze = (e / z) * 100;
		ze = Math.round(ze * 100) / 100;
		return ze;
	}
}

export class BnrZielModel {
	constructor(opts: any) {
		makeObservable(this);
		this.bnrId = opts.bnrId;
		this.id = opts.id;
		this.bm = opts.bm;
		this.ergebnis = opts.ergebnis;
		this.posId = opts.posId;
		this.ziel = opts.ziel;
		const mom = moment(opts.stichtag);
		this.stichtag = mom.toDate();
		// first 4 digits of bm is the year
		const yy = opts.bm.toString().substring(0, 4);
		this.year = parseInt(yy);
	}
	id: string;
	bnrId: number;
	posId: number;
	bm: string;
	stichtag: Date;
	ergebnis: number;
	ziel: number;
	year: number;

	clone() {
		return new BnrZielModel(this);
	}

	// @computed
	// get year() {
	// 	return moment(this.bm + '01').year();
	// }

	@computed
	get monat() {
		return moment(this.stichtag).month() + 1;
	}

	@computed
	get ze() {
		const z = this.ziel;
		if (z === 0) {
			return 0;
		}
		const e = this.ergebnis;
		let ze = (e / z) * 100;
		ze = Math.round(ze * 100) / 100;
		return ze;
	}

	@computed
	get zeStern() {
		if (this.ze < 60) {
			return 60;
		}
		if (this.ze > 160) {
			return 160;
		}
		return this.ze;
	}

	@computed
	get zeZeit() {
		const z = (this.ziel * this.monat) / 12;
		if (z === 0) {
			return -1;
		}
		const e = this.ergebnis;
		let ze = (e / z) * 100;
		ze = Math.round(ze * 100) / 100;
		return ze;
	}
}

export class BnrZielStore extends BaseCachedEntityStore<BnrZielModel> {
	apiPath = 'api/vbl/posziel';
	idProp: string = 'id';
	gpStore: AgtGpStore;
	disableFindForStearts = [1];


	constructor(session: SessionStore, gpStore: AgtGpStore) {
		super(session);
		this.gpStore = gpStore;
	}

	createInstances(opts: any): BnrZielModel {
		return new BnrZielModel(opts);
	}

	async findAll(): Promise<BnrZielModel[]> {
		const data = await this.__findAll();
		if (!data || data.length === 0) {
			console.warn('Keine BNR Ziel Daten');
			return [];
		}
		return data;
	}

	async findByPosId(posId: number, year: number) {
		let data = await this.findAll();
		data = data.filter((x) => x.year === year);
		return data.find((d) => d.posId === posId);
	}
}

class CacheItem {
	constructor(bnrId: number, items: BnrZielModel[]) {
		this.bnrId = bnrId;
		this.items = items;
		this.loadingPromise = new Promise((resolve) => {
			this._resolver = resolve;
		});
	}
	markAsLoaded = () => {
		if (this._resolver) {
			this._resolver();
		}
	};
	_resolver: any;
	bnrId: number;
	items: BnrZielModel[];
	loadingPromise: Promise<any>;
}

export class BetreuerZielStore extends BaseEntityStore<BnrZielModel> {
	apiPath = 'api/vbl/posziel';
	idProp: string = 'id';
	gpStore: AgtGpStore;



	constructor(session: SessionStore, gpStore: AgtGpStore) {
		super(session);
		this.gpStore = gpStore;
	}

	createInstances(opts: any): BnrZielModel {
		return new BnrZielModel(opts);
	}

	cache: CacheItem[] = [];

	async findAllByBnrId(bnrId: number): Promise<BnrZielModel[]> {
		let cacheItem = this.cache.find((c) => c.bnrId === bnrId);
		if (cacheItem) {
			await cacheItem.loadingPromise;
			return cacheItem.items;
		}
		cacheItem = new CacheItem(bnrId, []);
		this.cache.push(cacheItem);

		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const items: BnrZielModel[] = await this.axios({
			url: 'api/vbl/posziel/' + bnrId,
			method: 'GET',
			headers: header.headers,
		}).then((res: any) => {
			if (res && res.data) {
				return res.data.map((d: any) => new BnrZielModel(d));
			}
			return [];
		});

		cacheItem.items = items;
		cacheItem.markAsLoaded();
		return items;
	}

	async findByBnrIdPosId(bnrId: number, posId: number, year: number) {
		let data = await this.findAllByBnrId(bnrId);
		data = data.filter((x) => x.year === year);
		return data.find((d) => d.posId === posId);
	}
}
