import Axios from 'axios';
import Config from 'Config';
import { autorun } from 'mobx';
import { AdminAgenturStore, AgenturStore } from './agentur.store';
import { AuthStore } from './auth.store';
import { BerichteUiiStore } from './ui/berichte.ui.store';
import { AgtGpStore } from './agt.gp.store';
import { KvAgtEigenePlanungStore } from './kv.agt.eigene.planung.store';
import { AgtZielStore } from './agt.ziel.store';
import { SessionStore } from './session.store';
import { UiStore } from './ui.store';
import { KvAgtCustomExpertiseStore } from './kv.agt.custom.expertise.store';
import { PersonalCustomStore } from './personal.custom.store';
import { PersonalTeilhaberStore } from './personal.teilhaber.store';
import { PersonalZweitverkaeuferStore } from './personal.zweitverkauefer.store';
import { PdfStore } from './pdf.store';
import { AgtPosAktStore, AgtPosBjStore, AgtPosMsStore } from './agt.pos.stores';
import { BnrStatsStore } from './bnr.stats.store';

import { PersonalUiStore } from './ui/personal.ui.store';
import { AgtProdUiStore } from './ui/agt.prod.ui.store';
import { AgtBestandUiStore } from './ui/agt.bestand.ui.store';
import { AgtNeugeschaftUiStore } from './ui/agt.neugeschaeft.ui.store';
import { AnwendungenUiStore } from './ui/anwendungen.ui.store';
import { AgtDigigFaktorUiStore } from './ui/agt.digifaktor.ui.store';
import { AgtBranchenplanungUiStore } from './ui/agt.branchenplanung.ui.store';
import { AgtBpStore } from './agt.bp.store';

import { AgtYearStore } from './agt.year.store';
import { AgtSchwerpunktUiStore } from './ui/agt.schwerpunkt.ui.store';
import { AgenturUiStore } from './ui/agentur.ui.store';
import { AgenturListUiStore } from './ui/agt.list.ui.store';
import { KvLeadnowCheckStore } from './kv.agt.leadnowcheck.store';
import { AgtLeadNowCheckUiStore } from './ui/agt.leadnowcheck.ui.store';
import { AgtCoronaCheckUiStore } from './ui/agt.coronacheck.ui.store';
import { KvCoronaCheckStore } from './kv.agt.coronacheck.store';
import { WidgetUiStore } from './ui/widget.ui.store';
import { KvBnrBpEigenesZielStore } from './kv.bnr.bp.eigenes.ziel.store';
import { KvBnrWidgetSettingsStore } from './kv.bnr.widget.settings.store';
import { BetreuerZielStore, BnrZielStore } from './bnr.ziel.store';
import { BnrGpStore } from './bnr.gp.store';
import { BnrProdUiStore } from './ui/bnr.prod.ui.store';
import { AgtPlanStore } from './agt.plan.store';
import { AgtPlanUiStore } from './ui/agt.plan.ui.store';
import { BnrDocumentStore } from './bnr.document.store';
import { DocumentUiStore } from './ui/document.ui.store';
import { BnrUploadStore } from './bnr.upload.store';
import { SharedDocumentStore } from './shared.document.store';
import { BnrTemplateStore } from './bnr.template.store';
import { TemplateUiStore } from './ui/template.ui.store';
import { BnrPlanungsToolCommisionCalcStore } from './bnr.planungstool.store';
import { BnrTaskStore } from './bnr.task.store';
import { TaskUiStore } from './ui/task.ui.store';
import { AgtPlanThemenUiStore } from './ui/agt.plan.themen.ui.store';
import { AgtPlanExportUiStore } from './ui/agt.plan.export.ui.store';
import { NotizenUiStore } from './ui/notizen.ui.store';
import { UserSettingsStore } from './user.settings.store';
import { AdminUserStore } from './admin.user.store';
import { AdminUserUiStore } from './ui/admin.user.ui.store';
import { AgtUserUiStore } from './ui/agt.user.ui.store';
import { AdminBnrTransferStore } from './admin.bnrtransfer.store';
import { AdminBnrTransferUiStore } from './ui/admin.bnrtransfer.ui.store';
import { BnrtransferStore } from './bnrtransfer.store';
import { AdminImportsStore } from './admin.imports.store';
import { AdminImportsUiStore } from './ui/admin.imports.ui.store';
import { AdminNotificationsStore } from './admin.notification.store';
import { AdminTemplatesStore } from './admin.templates.store';
import { AdminTemplatesUiStore } from './ui/admin.templates.ui.store';
import { AdminBnrBlacklistStore } from './admin.bnrblacklist.store';
import { AdminBnrBlacklistUiStore } from './ui/admin.bnrblacklist.ui.store';
import { TrackerStore } from 'app/stores/tracker.store';
import { AgtRingUiStore } from './ui/agt.ring.ui.store';

import { AgtErrungenschaftenUiStore } from './ui/agt.errungenschaften.ui.store';
import { BnrErrungenschaftenUiStore } from './ui/bnr.errungenschaften.ui.store';
import { ZweitverkaueferZielStore } from './zweitverkaeufer.ziel.store';
import { ZweitverkauferProdUiStore } from './ui/zweitverkaeufer.prod.ui.store';
import { KvBnrYearErrungenschaftenStore } from './kv.bnr.year.errungenschaften.store';
import { KvAgenturYearErrungenschaftenStore } from './kv.agentur.year.errungenschaften.store';
import { WochenmailtUiStore } from './ui/wochenmail.ui.store';
import { KvAgenturYearSegmentStore } from './kv.agentur.year.segment.store';
import { AgtSegmentUiStore } from './ui/agt.segment.ui.store';
import { AgtAkumaPrimeUiStore } from './ui/agt.akumaprime.ui.store';
import { KvAgenturYearAkumaPrimeStore } from './kv.agentur.year.akumaprime.store';
import { KvAgenturYearAkumaPlanerStore } from './kv.agentur.year.akumaplaner.store';
import { BnrAkumaPrimeUiStore } from './ui/bnr.akumaprime.ui.store';
import { BnrBranchenplanungUiStore } from './ui/bnr.branchenplanung.ui.store';

import { BnrStore } from './bnr.store';
import { BnrAgenturStore } from './bnr.agentur.store';
import { KvAgtDigitalreportStore } from './kv.agt.digitalreport.store';
import { GsvbUiStore } from './ui/gsvb.ui.store';
import { AgtDigitalreportUiStore } from './ui/agt.digitalreport.ui.store';
import { TeamUiStore } from './ui/team.ui.store';
import { UserStore } from './user.store';
import { TempAgenturStore } from './temp.agentur.store';
import { TempAgenturUiStore } from './ui/temp.agentur.ui.store';
import { AdminBnrDiffUiStore } from './ui/admin.bnr.diff.ui.store';
import { KvAgtMsClusterStore } from './kv.agt.cluster.store';
import { AgtClusterUiStore } from './ui/agt.cluster.ui.store';
import { AdminBnrDiffStore } from './admin.bnrdiff.store';

import { AdminTrackingStore } from './admin.tracking.store';
import { AdminUsageUiStore } from './ui/admin.usage.ui.store';
import { MapsStore } from './maps.store';
import { AgenturMapsUiStore } from './ui/agentur.maps.ui.store';
import { SteartStore } from './steart.store';
import { AgenturJahresthemenStore } from './agentur.jahresthemen.store';
import { AdminWochenmailKwStore } from './admin.wochenmail.kw.store';
import { AdminWochenmailUiStore } from './ui/admin.wochenmail.ui.store';
import { AdminUserLoginUiStore } from './ui/admin.user.login.ui.store';
import { NewsStore } from './news.store';
import { AdminNewsUiStore } from './ui/admin.news.ui.store';
import { NewsUiStore } from './ui/news.ui.store';
import { AgenturFilterUiStore } from './ui/agt.list.filter.ui.store';
import { KvBnrAgtFilterStore } from './kv.bnr.agt.filter.store';
import { AgtAkumaPlanerUiStore } from './ui/agt.akumaplaner.ui.store';
import { KvAgtLeadNowStore } from './kv.agt.leadnow.store';
import { AgtLeadNowUiStore } from './ui/agt.leadnow.ui.store';
import { KvAgtGoogleProfileStore } from './kv.agt.googleprofile.store';
import { KvAgtAmisNowStore } from './kv.agt.amisnow.store';
import { AgtGoogleProfileUiStore } from './ui/agt.googleprofile.ui.store';
import { AgtFitnessUiStore } from './ui/agt.fitness.ui.store';
import { AdminDatenStandStore, DatenStandStore } from './datenstand.store';
import { AgtHerbstwerbungUiStore } from './ui/agt.herbstwerbung.ui.store';
import { AgtAmisNowUiStore } from './ui/agt.amisnow.ui.store';
import { AdminFileUploadUiStore } from './ui/admin.file.upload.ui.store';

export class GlobalStore {
	authStore: AuthStore;
	uiStore: UiStore;
	session: SessionStore;
	adminDatenStandStore: AdminDatenStandStore;
	datenStandStore: DatenStandStore;
	pdfStore: PdfStore;
	bnrStore: BnrStore;
	userStore: UserStore;
	bnrAgenturStore: BnrAgenturStore;
	bnrStatsStore: BnrStatsStore;
	steartStore: SteartStore;
	agenturStore: AgenturStore;
	tempAgenturStore: TempAgenturStore;
	kvAgtDigitalreportStore: KvAgtDigitalreportStore;
	kvAgenturYearSegmentStore: KvAgenturYearSegmentStore;
	kvAgenturYearErrungenschaftenStore: KvAgenturYearErrungenschaftenStore;
	kvAgtMsClusterStore: KvAgtMsClusterStore;
	agtZielStore: AgtZielStore;
	bnrZielStore: BnrZielStore;
	betreuerZielStore: BetreuerZielStore;
	zweitverkaueferZielStore: ZweitverkaueferZielStore;
	bnrDocumentStore: BnrDocumentStore;
	bnrUploadStore: BnrUploadStore;
	bnrTaskStore: BnrTaskStore;
	bnrPlanungsToolCommisionCalcStore: BnrPlanungsToolCommisionCalcStore;
	sharedDocumentStore: SharedDocumentStore;
	kvAgtEigenePlanungStore: KvAgtEigenePlanungStore;
	kvAgtCustomExpertiseStore: KvAgtCustomExpertiseStore;
	kvBnrYearErrungenschaftenStore: KvBnrYearErrungenschaftenStore;
	kvLeadnowCheckStore: KvLeadnowCheckStore;
	kvAgenturYearAkumaPrimeStore: KvAgenturYearAkumaPrimeStore;
	kvAgenturYearAkumaPlanerStore: KvAgenturYearAkumaPlanerStore;
	kvCoronaCheckStore: KvCoronaCheckStore;
	kvBnrAgtFilterStore: KvBnrAgtFilterStore;
	kvBnrBpEigenesZielStore: KvBnrBpEigenesZielStore;
	kvBnrWidgetSettingsStore: KvBnrWidgetSettingsStore;
	kvAgtLeadNowStore: KvAgtLeadNowStore;
	kvAgtGoogleProfileStore: KvAgtGoogleProfileStore;
	personalCustomStore: PersonalCustomStore;
	personalTeilhaberStore: PersonalTeilhaberStore;
	personalZweitverkaeuferStore: PersonalZweitverkaeuferStore;
	personalUiStore: PersonalUiStore;
	agtPlanStore: AgtPlanStore;
	agtGpStore: AgtGpStore;
	bnrGpStore: BnrGpStore;
	agtPosBjStore: AgtPosBjStore;
	agtPosMsStore: AgtPosMsStore;
	agtPosAktStore: AgtPosAktStore;
	agtBpStore: AgtBpStore;
	agenturJahresthemenStore: AgenturJahresthemenStore;
	agtYearStore: AgtYearStore;
	bnrTemplateStore: BnrTemplateStore;
	userSettingsStore: UserSettingsStore;
	bnrtransferStore: BnrtransferStore;
	mapsStore: MapsStore;
	agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore;
	agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore;
	bnrAkumaPrimeUiStore: BnrAkumaPrimeUiStore;
	gsvbUiStore: GsvbUiStore;
	newsStore: NewsStore;

	adminUserStore: AdminUserStore;
	adminBnrDiffStore: AdminBnrDiffStore;
	adminBnrTransferStore: AdminBnrTransferStore;
	adminImportsStore: AdminImportsStore;
	adminTemplatesStore: AdminTemplatesStore;
	adminBnrBlacklistStore: AdminBnrBlacklistStore;
	adminTrackingStore: AdminTrackingStore;
	adminAgenturStore: AdminAgenturStore;
	adminWochenmailKwStore: AdminWochenmailKwStore;

	widgetUiStore: WidgetUiStore;
	anwendungenUiStore: AnwendungenUiStore;
	berichteUiiStore: BerichteUiiStore;
	agenturUiStore: AgenturUiStore;
	agenturMapsUiStore: AgenturMapsUiStore;
	tempAgenturUiStore: TempAgenturUiStore;
	agtProdUiStore: AgtProdUiStore;
	bnrProdUiStore: BnrProdUiStore;
	agtDigigFaktorStore: AgtDigigFaktorUiStore;
	agtBestandUiStore: AgtBestandUiStore;
	agtNeugeschaftUiStore: AgtNeugeschaftUiStore;
	agtBranchenplanungUiStore: AgtBranchenplanungUiStore;
	bnrBranchenplanungUiStore: BnrBranchenplanungUiStore;
	agtSchwerpunktUiStore: AgtSchwerpunktUiStore;
	agtLeadNowCheckUiStore: AgtLeadNowCheckUiStore;
	agtCoronaCheckUiStore: AgtCoronaCheckUiStore;
	agtLeadNowUiStore: AgtLeadNowUiStore;
	agtGoogleProfileUiStore: AgtGoogleProfileUiStore;
	agtAmisNowUiStore: AgtAmisNowUiStore;
	agtAmisNowStore: KvAgtAmisNowStore

	agtPlanUiStore: AgtPlanUiStore;
	agtRingUiStore: AgtRingUiStore;
	agtHerbstwerbungUiStore: AgtHerbstwerbungUiStore;
	documentUiStore: DocumentUiStore;
	templateUiStore: TemplateUiStore;
	taskUiStore: TaskUiStore;
	agtPlanThemenUiStore: AgtPlanThemenUiStore;
	agtPlanExportUiStore: AgtPlanExportUiStore;
	agtUserUiStore: AgtUserUiStore;
	agtDigitalreportUiStore: AgtDigitalreportUiStore;
	zweitverkauferProdUiStore: ZweitverkauferProdUiStore;
	agtSegmentUiStore: AgtSegmentUiStore;
	agtClusterUiStore: AgtClusterUiStore;

	agenturListUiStore: AgenturListUiStore;
	agenturFilterUiStore: AgenturFilterUiStore;
	teamUiStore: TeamUiStore;
	notizenUiStore: NotizenUiStore;

	agtErrungenschaftenUiStore: AgtErrungenschaftenUiStore;
	bnrErrungenschaftenUiStore: BnrErrungenschaftenUiStore;
	agtFitnessUiStore: AgtFitnessUiStore;
	wochenmailtUiStore: WochenmailtUiStore;
	newsUiStore: NewsUiStore;

	adminUserUiStore: AdminUserUiStore;
	adminBnrDiffUiStore: AdminBnrDiffUiStore;
	adminBnrTransferUiStore: AdminBnrTransferUiStore;
	adminImportsUiStore: AdminImportsUiStore;
	adminNotificationsStore: AdminNotificationsStore;
	adminTemplatesUiStore: AdminTemplatesUiStore;
	adminBnrBlacklistUiStore: AdminBnrBlacklistUiStore;
	adminUsageUiStore: AdminUsageUiStore;
	adminWochenmailUiStore: AdminWochenmailUiStore;
	adminUserLoginUiStore: AdminUserLoginUiStore;
	adminNewsUiStore: AdminNewsUiStore;
	adminFileUploadUiStore: AdminFileUploadUiStore;

	trackerStore: TrackerStore;

	constructor() {
		const axios = Axios.create({
			baseURL: Config.API_BASE_URL,
		});

		// base : should not contain references to other stores (except session)
		//  maybe better to name these "services".
		this.session = new SessionStore(axios);
		this.uiStore = new UiStore(this.session);
		this.authStore = new AuthStore(this.session, this.uiStore);
		this.pdfStore = new PdfStore(this.session);
		this.bnrStore = new BnrStore(this.session);
		this.userStore = new UserStore(this.session);
		this.adminDatenStandStore = new AdminDatenStandStore(this.session);
		this.datenStandStore = new DatenStandStore(this.session);
		this.bnrAgenturStore = new BnrAgenturStore(this.session);
		this.steartStore = new SteartStore(this.session);
		this.bnrStatsStore = new BnrStatsStore(this.session);
		this.agtPosBjStore = new AgtPosBjStore(this.session);
		this.agtPosMsStore = new AgtPosMsStore(this.session);
		this.agtPosAktStore = new AgtPosAktStore(this.session);
		this.kvAgtDigitalreportStore = new KvAgtDigitalreportStore(this.session);
		this.agenturJahresthemenStore = new AgenturJahresthemenStore(this.session);
		this.kvBnrAgtFilterStore = new KvBnrAgtFilterStore(this.session);
		this.kvBnrYearErrungenschaftenStore = new KvBnrYearErrungenschaftenStore(this.session);
		this.kvAgenturYearErrungenschaftenStore = new KvAgenturYearErrungenschaftenStore(this.session);
		this.kvAgenturYearSegmentStore = new KvAgenturYearSegmentStore(this.session);
		this.kvAgenturYearAkumaPrimeStore = new KvAgenturYearAkumaPrimeStore(this.session);
		this.kvAgenturYearAkumaPlanerStore = new KvAgenturYearAkumaPlanerStore(this.session);
		this.kvAgtCustomExpertiseStore = new KvAgtCustomExpertiseStore(this.session);
		this.kvLeadnowCheckStore = new KvLeadnowCheckStore(this.session);
		this.kvAgtGoogleProfileStore = new KvAgtGoogleProfileStore(this.session);
		this.agtAmisNowStore = new KvAgtAmisNowStore(this.session);
		this.kvCoronaCheckStore = new KvCoronaCheckStore(this.session);
		this.kvAgtMsClusterStore = new KvAgtMsClusterStore(this.session);
		this.kvAgtLeadNowStore = new KvAgtLeadNowStore(this.session);
		this.agtPlanStore = new AgtPlanStore(this.session);
		this.bnrDocumentStore = new BnrDocumentStore(this.session);
		this.bnrTaskStore = new BnrTaskStore(this.session);
		this.bnrPlanungsToolCommisionCalcStore = new BnrPlanungsToolCommisionCalcStore(this.session);
		this.bnrUploadStore = new BnrUploadStore(this.session);
		this.agtGpStore = new AgtGpStore(this.session);
		this.bnrGpStore = new BnrGpStore(this.session);
		this.agtBpStore = new AgtBpStore(this.session);
		this.agtYearStore = new AgtYearStore(this.session);
		this.agtZielStore = new AgtZielStore(this.session, this.agtGpStore);
		this.bnrZielStore = new BnrZielStore(this.session, this.agtGpStore);
		this.betreuerZielStore = new BetreuerZielStore(this.session, this.agtGpStore);
		this.zweitverkaueferZielStore = new ZweitverkaueferZielStore(this.session);
		this.kvAgtEigenePlanungStore = new KvAgtEigenePlanungStore(this.session);
		this.kvBnrBpEigenesZielStore = new KvBnrBpEigenesZielStore(this.session);
		this.kvBnrWidgetSettingsStore = new KvBnrWidgetSettingsStore(this.session);
		this.personalCustomStore = new PersonalCustomStore(this.session);
		this.personalTeilhaberStore = new PersonalTeilhaberStore(this.session);
		this.personalZweitverkaeuferStore = new PersonalZweitverkaeuferStore(this.session);
		this.sharedDocumentStore = new SharedDocumentStore(this.session);
		this.bnrTemplateStore = new BnrTemplateStore(this.session);
		this.userSettingsStore = new UserSettingsStore(this.session);
		this.bnrtransferStore = new BnrtransferStore(this.session);
		this.mapsStore = new MapsStore(this.session);
		this.newsStore = new NewsStore(this.session);

		this.agenturStore = new AgenturStore(this.session, this.kvAgtCustomExpertiseStore);
		this.tempAgenturStore = new TempAgenturStore(this.session);

		this.agtAkumaPrimeUiStore = new AgtAkumaPrimeUiStore(this.agenturStore, this.bnrStatsStore, this.kvAgenturYearAkumaPrimeStore);
		this.bnrAkumaPrimeUiStore = new BnrAkumaPrimeUiStore(this.agtAkumaPrimeUiStore);

		this.agtAkumaPlanerUiStore = new AgtAkumaPlanerUiStore(this.agenturStore, this.kvAgenturYearAkumaPlanerStore);

		this.adminUserStore = new AdminUserStore(this.session);
		this.adminBnrDiffStore = new AdminBnrDiffStore(this.session);
		this.adminBnrTransferStore = new AdminBnrTransferStore(this.session);
		this.adminImportsStore = new AdminImportsStore(this.session);
		this.adminNotificationsStore = new AdminNotificationsStore(this.session);
		this.adminTemplatesStore = new AdminTemplatesStore(this.session);
		this.adminBnrBlacklistStore = new AdminBnrBlacklistStore(this.session);
		this.adminTrackingStore = new AdminTrackingStore(this.session);
		this.adminAgenturStore = new AdminAgenturStore(this.session, this.kvAgtCustomExpertiseStore);
		this.adminWochenmailKwStore = new AdminWochenmailKwStore(this.session);

		// ui-specific
		this.widgetUiStore = new WidgetUiStore(this.kvBnrWidgetSettingsStore);
		this.personalUiStore = new PersonalUiStore(this.session, this.personalCustomStore, this.personalTeilhaberStore, this.personalZweitverkaeuferStore, this.agenturStore);
		this.agtDigigFaktorStore = new AgtDigigFaktorUiStore(this.session, this.agenturStore, this.bnrStatsStore);
		this.agtRingUiStore = new AgtRingUiStore(this.session, this.agenturStore, this.agtZielStore);
		this.agtHerbstwerbungUiStore = new AgtHerbstwerbungUiStore(this.session, this.agenturStore, this.agtZielStore);
		this.agtUserUiStore = new AgtUserUiStore(this.userStore);
		this.gsvbUiStore = new GsvbUiStore(this.bnrStore, this.bnrAgenturStore, this.adminAgenturStore);

		this.berichteUiiStore = new BerichteUiiStore(
			this.session,
			this.agenturStore,
			this.agtGpStore,
			this.agtZielStore,
			this.agtDigigFaktorStore,
			this.agtRingUiStore,
			this.agtUserUiStore,
		);
		this.agtDigitalreportUiStore = new AgtDigitalreportUiStore(this.session, this.agenturStore, this.kvAgtDigitalreportStore);

		this.tempAgenturUiStore = new TempAgenturUiStore(this.tempAgenturStore, this.agenturStore);
		this.agenturListUiStore = new AgenturListUiStore(this.agenturStore, this.userStore);
		this.agenturFilterUiStore = new AgenturFilterUiStore(this.agenturListUiStore, this.agtUserUiStore, this.kvBnrAgtFilterStore);
		this.agenturUiStore = new AgenturUiStore(this.session, this.agenturListUiStore);
		this.agenturMapsUiStore = new AgenturMapsUiStore(this.session, this.mapsStore, this.agenturListUiStore, this.agtUserUiStore);

		this.teamUiStore = new TeamUiStore(this.agtUserUiStore);

		this.agtProdUiStore = new AgtProdUiStore(this.session, this.agenturStore, this.agtZielStore, this.kvAgtEigenePlanungStore, this.agtGpStore, this.zweitverkaueferZielStore);
		this.bnrProdUiStore = new BnrProdUiStore(this.session, this.agenturStore, this.bnrZielStore, this.agtProdUiStore, this.bnrGpStore, this.betreuerZielStore);
		this.agtBestandUiStore = new AgtBestandUiStore(this.session, this.agenturStore, this.agtPosBjStore, this.agtPosMsStore, this.agtPosAktStore);
		this.agtNeugeschaftUiStore = new AgtNeugeschaftUiStore(this.session, this.agenturStore, this.agtPosBjStore, this.agtPosMsStore, this.agtPosAktStore);
		this.agtBranchenplanungUiStore = new AgtBranchenplanungUiStore(
			this.session,
			this.agenturStore,
			this.agtBpStore,
			this.kvBnrBpEigenesZielStore,
			this.agtRingUiStore,
			this.agtProdUiStore,
		);
		this.bnrBranchenplanungUiStore = new BnrBranchenplanungUiStore(this.session, this.agtBranchenplanungUiStore);
		this.agtSchwerpunktUiStore = new AgtSchwerpunktUiStore(this.session, this.agenturStore, this.agtYearStore);
		this.agtLeadNowCheckUiStore = new AgtLeadNowCheckUiStore(this.session, this.agenturStore, this.kvLeadnowCheckStore, this.agtDigigFaktorStore);
		this.agtCoronaCheckUiStore = new AgtCoronaCheckUiStore(this.session, this.agenturStore, this.kvCoronaCheckStore);
		this.agtClusterUiStore = new AgtClusterUiStore(this.agenturStore, this.kvAgtMsClusterStore);

		this.taskUiStore = new TaskUiStore(this.bnrTaskStore, this.agenturStore, this.agtPlanStore);
		this.templateUiStore = new TemplateUiStore(this.bnrTemplateStore);
		this.documentUiStore = new DocumentUiStore(
			this.session,
			this.bnrDocumentStore,
			this.bnrUploadStore,
			this.sharedDocumentStore,
			this.agenturStore,
			this.agtPlanStore,
			this.bnrPlanungsToolCommisionCalcStore,
			this.agtPosBjStore,
			this.personalUiStore,
			this.bnrTemplateStore,
			this.teamUiStore,
		);
		this.agtSegmentUiStore = new AgtSegmentUiStore(this.session, this.agenturStore, this.kvAgenturYearSegmentStore, this.agtNeugeschaftUiStore, this.agtBestandUiStore);
		this.anwendungenUiStore = new AnwendungenUiStore(this.session, this.agenturStore, this.agtBpStore, this.agtSegmentUiStore, this.agtUserUiStore);
		this.agtPlanUiStore = new AgtPlanUiStore(this.session, this.agenturStore, this.agtPlanStore);
		this.agtPlanExportUiStore = new AgtPlanExportUiStore(this.session, this.agtPlanUiStore, this.documentUiStore, this.agtBranchenplanungUiStore, this.templateUiStore);
		this.agtPlanThemenUiStore = new AgtPlanThemenUiStore(this.session, this.agtPlanUiStore, this.taskUiStore);
		this.notizenUiStore = new NotizenUiStore(this.session, this.bnrDocumentStore, this.bnrUploadStore);
		this.agtRingUiStore = new AgtRingUiStore(this.session, this.agenturStore, this.agtZielStore);
		this.zweitverkauferProdUiStore = new ZweitverkauferProdUiStore(this.session, this.agenturStore, this.personalZweitverkaeuferStore, this.zweitverkaueferZielStore);
		this.agtLeadNowUiStore = new AgtLeadNowUiStore(this.agenturStore, this.kvAgtLeadNowStore);
		this.agtGoogleProfileUiStore = new AgtGoogleProfileUiStore(this.agenturStore, this.kvAgtGoogleProfileStore);
		this.agtAmisNowUiStore = new AgtAmisNowUiStore(this.agenturStore, this.agtAmisNowStore);
		this.agenturListUiStore.setDependenStores(
			this.agtBranchenplanungUiStore,
			this.agtSchwerpunktUiStore,
			this.documentUiStore,
			this.agtSegmentUiStore,
			this.steartStore,
			this.agenturJahresthemenStore,
			this.agenturFilterUiStore,
		);

		this.agtErrungenschaftenUiStore = new AgtErrungenschaftenUiStore(
			this.agenturStore,
			this.agenturListUiStore,
			this.agtBestandUiStore,
			this.agtDigigFaktorStore,
			this.agtRingUiStore,
			this.agtGpStore,
			this.agtZielStore,
			this.agtLeadNowCheckUiStore,
			this.agtBranchenplanungUiStore,
			this.agtProdUiStore,
			this.kvAgenturYearErrungenschaftenStore,
			this.agtAkumaPrimeUiStore,
			this.agtDigitalreportUiStore,
			this.agtAkumaPlanerUiStore
		);

		this.bnrErrungenschaftenUiStore = new BnrErrungenschaftenUiStore(
			this.agtErrungenschaftenUiStore,
			this.agenturListUiStore,
			this.documentUiStore,
			this.taskUiStore,
			this.bnrGpStore,
			this.bnrProdUiStore,
			this.agtBranchenplanungUiStore,
			this.kvBnrYearErrungenschaftenStore,
		);

		this.agtFitnessUiStore = new AgtFitnessUiStore(this.agenturStore, this.agtGoogleProfileUiStore, this.agtAkumaPrimeUiStore, this.agtAkumaPlanerUiStore, this.agtDigitalreportUiStore, this.agtAmisNowUiStore);
		this.newsUiStore = new NewsUiStore(this.newsStore);

		this.wochenmailtUiStore = new WochenmailtUiStore(
			this.session,
			this.taskUiStore,
			this.documentUiStore,
			this.bnrErrungenschaftenUiStore,
			this.agtErrungenschaftenUiStore,
			this.personalUiStore,
			this.agenturStore,
			this.newsUiStore,
		);

		this.adminUserUiStore = new AdminUserUiStore(this.adminUserStore);
		this.adminBnrDiffUiStore = new AdminBnrDiffUiStore(this.adminBnrDiffStore, this.adminUserUiStore);
		this.adminBnrTransferUiStore = new AdminBnrTransferUiStore(this.adminBnrTransferStore, this.adminUserStore);
		this.adminImportsUiStore = new AdminImportsUiStore(this.adminImportsStore, this.adminDatenStandStore);
		this.adminTemplatesUiStore = new AdminTemplatesUiStore(this.adminTemplatesStore);
		this.adminBnrBlacklistUiStore = new AdminBnrBlacklistUiStore(this.adminBnrBlacklistStore);
		this.adminUsageUiStore = new AdminUsageUiStore(this.session, this.adminTrackingStore, this.adminUserUiStore);
		this.adminWochenmailUiStore = new AdminWochenmailUiStore(this.adminWochenmailKwStore, this.adminUserUiStore);
		this.adminUserLoginUiStore = new AdminUserLoginUiStore(this.adminTrackingStore, this.adminUserUiStore);
		this.adminNewsUiStore = new AdminNewsUiStore(this.newsStore);
		this.adminFileUploadUiStore = new AdminFileUploadUiStore(this.uiStore, this.adminImportsStore);

		this.trackerStore = new TrackerStore(this.session);

		autorun(async () => {
			if (this.session.currentUser) {
				if (this.session.currentUser.isAdmin) {
					this.agtYearStore.findAll();
				} else {
					await Promise.all([
						this.steartStore.findAll(),
						this.userStore.findAll(),
						this.widgetUiStore.load(),
						// this.agtDigigFaktorStore.load(),
						this.kvAgtCustomExpertiseStore.findAll(),
						// this.bnrDocumentStore.findAll(),
						this.kvBnrWidgetSettingsStore.findAll(),
						this.bnrErrungenschaftenUiStore.load(),
						this.agtAkumaPrimeUiStore.load(),
					]);
				}
				// this.agenturStore.findAll();
				// this.posAgtZielStore.findAll();
				// this.agenturListUiStore.load();
			} else {
				this.agenturStore.onLogout();
				this.personalUiStore.onLogout();
				this.agtDigigFaktorStore.onLogout();
				this.berichteUiiStore.onLogout();
				this.anwendungenUiStore.onLogout();
				this.agenturUiStore.onLogout();
				this.agtProdUiStore.onLogout();
				this.agtBestandUiStore.onLogout();
				this.agtNeugeschaftUiStore.onLogout();
				this.agtBranchenplanungUiStore.onLogout();
				this.agtSchwerpunktUiStore.onLogout();
				this.widgetUiStore.onLogout();
			}
		});
	}
}
