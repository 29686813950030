import { AgenturModel } from 'app/models/agentur.model';
import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from 'app/models/gp/bnr.gp.pos.model';
import { ZweitverkaeuferModel } from 'app/models/personal/zweitverkauefer.model';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { BnrZielModel } from '../bnr.ziel.store';
import { PersonalZweitverkaeuferStore } from '../personal.zweitverkauefer.store';
import { SessionStore } from '../session.store';
import { ZweitverkaueferZielStore } from '../zweitverkaeufer.ziel.store';
import { GPMAXYEAR } from './berichte.ui.store';
import { BaseUiStore } from './base.ui.store';
import _ from 'lodash';

interface IZweitverkauferProdPosVM {
	bnrcId: number;
	zweitverkaufer: ZweitverkaeuferModel;
	agt: AgenturModel;
	posDef: BnrGpPosModel;
	posVal?: BnrZielModel;
}

export class ZweitverkauferProdPosVM {
	constructor(opts: IZweitverkauferProdPosVM) {
		makeObservable(this);
		this.bnrcId = opts.bnrcId;
		this.zweitverkaufer = opts.zweitverkaufer;
		this.posDef = opts.posDef;
		this.posVal = opts.posVal;
		this.agt = opts.agt;
		this.agtId = opts.agt.agtId;
	}
	bnrcId: number;
	agtId: number;
	zweitverkaufer: ZweitverkaeuferModel;
	posDef: BnrGpPosModel;
	posVal?: BnrZielModel;
	agt: AgenturModel;

	getLimited(z: number) {
		if (z < 40) {
			z = 40;
		}
		if (z > 180) {
			z = 180;
		}
		return z;
	}

	@computed
	get zeZeitLimited() {
		let z = 0;
		if (this.posVal) {
			z = this.posVal.zeZeit;
		}
		return this.getLimited(z);
	}

	@computed
	get zeJahrLimited() {
		let z = 0;
		if (this.posVal) {
			z = this.posVal.ze;
		}
		return this.getLimited(z);
	}
}

const zweitVkProdPos = new BnrGpPosModel({ year: GPMAXYEAR, posId: 2255854, group: 'gpplus', posText: 'Gesamtbewertung', planKey: 'gesamtbewertung', einheit: Einheit.bew });

export class ZweitverkauferProdUiStore extends BaseUiStore<ZweitverkauferProdPosVM> {
	name = 'ZweitverkauferProdUiStore';
	session: SessionStore;
	agenturStore: AgenturStore;
	personalZweitverkaeuferStore: PersonalZweitverkaeuferStore;
	zweitverkaueferZielStore: ZweitverkaueferZielStore;
	constructor(session: SessionStore, agenturStore: AgenturStore, personalZweitverkaeuferStore: PersonalZweitverkaeuferStore, zweitverkaueferZielStore: ZweitverkaueferZielStore) {
		super();
		this.session = session;
		this.agenturStore = agenturStore;
		this.personalZweitverkaeuferStore = personalZweitverkaeuferStore;
		this.zweitverkaueferZielStore = zweitverkaueferZielStore;
	}



	async _baseLoad() {
		const zws = await this.personalZweitverkaeuferStore.findAll();
		let poses = await this.zweitverkaueferZielStore.findAll();
		const agts = await this.agenturStore.findAll();
		const res: ZweitverkauferProdPosVM[] = [];

		let years = Object.keys(_.groupBy(poses, 'year')).map(y => parseInt(y));


		years.forEach(year => {

			zws.forEach((zw) => {
				let pos = poses.find((p) => p.bnrId === zw.bnrcId && p.posId === 2255854 && p.year === year);
				if (!pos) {
					// fallback für für agpler
					pos = poses.find((p) => p.bnrId === zw.bnrcId && p.posId === 2255855 && p.year === year);
				}

				if (!pos && zw.bnrId == 3028249) {
					pos = poses.find((p) => p.bnrId === 3028250 && p.posId === 2255855 && p.year === year);
				}



				// bnrcId wird zum mappen und anzeigen verwendeta
				const agt = agts.find((a) => a.agtId === zw!.agtId);
				// if (res.find((x) => x.bnrcId === zw.bnrcId)) {
				// 	return;
				// }
				const vm = new ZweitverkauferProdPosVM({
					bnrcId: zw.bnrcId,
					zweitverkaufer: zw!,
					posDef: zweitVkProdPos,
					posVal: pos,
					agt: agt!,
				});
				res.push(vm);
			});

		})
		runInAction(() => {
			this.items = res;
		});

	}
}
