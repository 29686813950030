import {
	ForgotPasswordContainer,
	LoginContainer,
	LogoutContainer,
	RegisterContainer,
	SetPasswordContainer,
	SuperLoginContainer,
	VerifyPinContainer,
} from 'app/containers/AuthContainers';
import AgenturContainer from 'app/containers/AgenturContainer';
import HomeContainer from 'app/containers/HomeContainer';
import NotizenContainer from 'app/containers/NotizenContainer';
import BerichteContainer from 'app/containers/BerichteContainer';
import AnwendungenContainer from 'app/containers/AnwendungenContainer';
import DokumenteContainer from 'app/containers/DokumenteContainer';
import VerlinkungenContainer from 'app/containers/VerlinkungenContainer';
import FeedbackContainer from 'app/containers/FeedbackContainer';
import PrintingContainer from 'app/containers/PrintingContainer';
import React from 'react';
import TasksContainer from 'app/containers/TasksContainer';
import SettingsContainer from 'app/containers/SettingsContainer';
import AdminHomeContainer from 'app/containers/AdminHomeContainer';
import AdminUsersContainer from 'app/containers/AdminUsersContainer';
import AdminBnrTransferContainer from 'app/containers/AdminBnrTransferContainer';
import BnrTransferContainer, { AdminBnrTransferDoneContainer } from 'app/containers/BnrTransferContainer';
import AdminImportsContainer from 'app/containers/AdminImportsContainer';
import AdminNotificationsContainer from 'app/containers/AdminNotificationsContainer';
import AdminTemplatesContainer from 'app/containers/AdminTemplatesContainer';
import AdminBnrBlacklistContainer from 'app/containers/AdminBnrBlacklistContainer';
import ErrungenschaftenContainer, { ErrungenschaftenCrawlContainer } from 'app/containers/ErrungenschaftenContainer';
import WochenmailContainer from 'app/containers/WochenmailContainer';
import AdminGsvbContainer from 'app/containers/AdminGsvbContainer';
import TeamContainer from 'app/containers/TeamContainer';
import AdminBnrDiffContainer from 'app/containers/AdminBnrDiffContainer';
import AdminUsageContainer from 'app/containers/AdminUsageContainer';
import AdminCrawlContainer from 'app/containers/AdminCrawlContainer';
import AdminUserLoginContainer from 'app/containers/AdminUserLoginContainer';
import AdminPanikContainer from 'app/containers/AdminPanikContainer';
import AdminBnrDiffMultiContainer from 'app/containers/AdminBnrDiffMultiContainer';
import AdminNewsContaniner from 'app/containers/AdminNewsContaniner';
import TestClearPlanungContainer from 'app/containers/TestClearPlanungContainer';

export interface IRoute {
	route: string;
	getPath(p1?: string, p2?: string, p3?: string, p4?: string, p5?: string, p6?: string): string;
	name?: string;
	container: React.FC<any> | React.FunctionComponent | React.ComponentClass | React.ComponentClass<any>;
	exact?: boolean;
	private: boolean;
}

class Routes {
	HOME: IRoute = {
		route: '/',
		getPath: () => '/',
		name: 'Home',
		container: HomeContainer,
		private: true,
	};

	NOTIZEN: IRoute = {
		route: '/notizen',
		getPath: () => '/notizen',
		name: 'Agentur',
		container: NotizenContainer,
		private: true,
	};

	AGENTUR: IRoute = {
		route: '/agentur/:agtId?/:panel?/:level1?/:level2?/:level3?/:level4?/:level5?',
		getPath: (agtId?: string, panel?: string, level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) => {
			const p = ['agentur'];
			if (agtId) {
				p.push(agtId);
				if (panel) {
					p.push(panel);
					if (level1) {
						p.push(level1);
						if (level2) {
							p.push(level2);
							if (level3) {
								p.push(level3);
								if (level4) {
									p.push(level4);
									if (level5) {
										p.push(level5);
									}
								}
							}
						}
					}
				}
			}
			return '/' + p.join('/');
		},

		name: 'Agentur',
		container: AgenturContainer,
		private: true,
	};

	TEAM: IRoute = {
		route: '/team/:bnrId?/:panel?/:level1?/:level2?/:level3?/:level4?/:level5?',
		getPath: (bnrId?: string, panel?: string, level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) => {
			const p = ['team'];
			if (bnrId) {
				p.push(bnrId);
				if (panel) {
					p.push(panel);
					if (level1) {
						p.push(level1);
						if (level2) {
							p.push(level2);
							if (level3) {
								p.push(level3);
								if (level4) {
									p.push(level4);
									if (level5) {
										p.push(level5);
									}
								}
							}
						}
					}
				}
			}
			return '/' + p.join('/');
		},

		name: 'Team',
		container: TeamContainer,
		private: true,
	};

	BERICHTE: IRoute = {
		route: '/berichte/:level1?/:level2?/:level3?/:level4?/:level5?',
		getPath: (level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) => {
			const p = ['berichte'];
			if (level1) {
				p.push(level1);
				if (level2) {
					p.push(level2);
					if (level3) {
						p.push(level3);
						if (level4) {
							p.push(level4);
							if (level5) {
								p.push(level5);
							}
						}
					}
				}
			}
			return '/' + p.join('/');
		},

		name: 'Berichte',
		container: BerichteContainer,
		private: true,
	};

	ANWENDUNGEN: IRoute = {
		route: '/anwendungen/:level1?/:level2?/:level3?/:level4?',
		getPath: (level1?: string, level2?: string, level3?: string, level4?: string) => {
			const p = ['anwendungen'];
			if (level1) {
				p.push(level1);
				if (level2) {
					p.push(level2);
					if (level3) {
						p.push(level3);
						if (level4) {
							p.push(level4);
						}
					}
				}
			}
			return '/' + p.join('/');
		},
		name: 'Anwendungen',
		container: AnwendungenContainer,
		private: true,
	};

	DOKUMENTE: IRoute = {
		route: '/dokumente',
		getPath: () => '/dokumente',
		name: 'Dokumente',
		container: DokumenteContainer,
		private: true,
	};

	MASSNAHMEN: IRoute = {
		route: '/massnahmen',
		getPath: () => '/massnahmen',
		name: 'Massnahmen',
		container: TasksContainer,
		private: true,
	};

	VERLINKUNGEN: IRoute = {
		route: '/links',
		getPath: () => '/links',
		name: 'Verlinkungen',
		container: VerlinkungenContainer,
		private: true,
	};

	FEEDBACK: IRoute = {
		route: '/feedback',
		getPath: () => '/feedback',
		name: 'Feedback',
		container: FeedbackContainer,
		private: true,
	};
	TESTCLEARPLANUNG: IRoute = {
		route: '/testclearplanung',
		getPath: () => '/testclearplanung',
		name: 'testclearplanung',
		container: TestClearPlanungContainer,
		private: true,
	};

	EINSTELLUNGEN: IRoute = {
		route: '/settings',
		getPath: () => '/settings',
		name: 'Einstellungen',
		container: SettingsContainer,
		private: true,
	};

	printing: IRoute = {
		route: '/printing',
		getPath: () => '/printing',
		name: 'Printing',
		container: PrintingContainer,
		private: false,
	};

	LOGIN: IRoute = {
		route: '/login/:redirect?',
		getPath: () => '/login',
		name: 'Home',
		container: LoginContainer,
		private: false,
	};

	LOGOUT: IRoute = {
		route: '/logout',
		getPath: () => '/logout',
		name: 'Home',
		container: LogoutContainer,
		private: false,
	};

	SUPERLOGIN: IRoute = {
		route: '/super-login/:redirect?',
		getPath: () => '/super-login',
		name: 'Home',
		container: SuperLoginContainer,
		private: false,
	};

	REGISTER: IRoute = {
		route: '/register',
		getPath: () => '/register',
		name: 'Home',
		container: RegisterContainer,
		private: false,
	};
	FORGOTPASSWORD: IRoute = {
		route: '/forgot-password',
		getPath: () => '/forgot-password',
		name: 'Home',
		container: ForgotPasswordContainer,
		private: false,
	};

	SETPASSWORD: IRoute = {
		route: '/set-password',
		getPath: () => '/set-password',
		name: 'Home',
		container: SetPasswordContainer,
		private: false,
	};

	VERIFYPIN: IRoute = {
		route: '/verify-pin',
		getPath: () => '/verify-pin',
		name: 'Home',
		container: VerifyPinContainer,
		private: false,
	};

	ADMINHOME: IRoute = {
		route: '/admin/',
		getPath: () => '/admin/',
		name: 'Home',
		container: AdminHomeContainer,
		private: true,
	};

	ADMINUSERS: IRoute = {
		route: '/admin/users/:userId?/:userType?/:bensl?/:steartId?',
		name: 'Home',
		container: AdminUsersContainer,
		private: true,
		getPath: (userId?: string, userType?: string, bensl?: string, steart?: string) => {
			const p = ['admin', 'users'];
			if (userId) {
				p.push(userId);
				if (userType) {
					p.push(userType);
					if (bensl) {
						p.push(bensl);
						if (steart) {
							p.push(steart);
						}
					}
				}
			}
			return '/' + p.join('/');
		},
	};

	ADMINBNRDIFF: IRoute = {
		route: '/admin/bnrdiff/:bnrId?',
		name: 'BNR Änderungen',
		container: AdminBnrDiffContainer,
		private: true,
		getPath: (bnrId?: string) => {
			const p = ['admin', 'bnrdiff'];
			if (bnrId) {
				p.push(bnrId);
			}
			return '/' + p.join('/');
		},
	};

	ADMINBNRDIFFMULTI: IRoute = {
		route: '/admin/bnrdiffmulti',
		name: 'BNR Änderungen',
		container: AdminBnrDiffMultiContainer,
		private: true,
		getPath: () => {
			const p = ['admin', 'bnrdiffmulti'];

			return '/' + p.join('/');
		},
	};

	ADMINTRACKING: IRoute = {
		route: '/admin/userlogins/',
		name: 'User Logins',
		container: AdminUserLoginContainer,
		private: true,
		getPath: () => '/admin/userlogins',
	};

	ADMINPANIK: IRoute = {
		route: '/admin/panik/',
		name: 'Admin Danger Zone',
		container: AdminPanikContainer,
		private: true,
		getPath: () => '/admin/panik',
	};

	ADMINBNRTRANSFER: IRoute = {
		route: '/admin/bnrtransfer/:id?',
		name: 'Home',
		container: AdminBnrTransferContainer,
		private: true,
		getPath: (id?: string) => {
			const p = ['admin', 'bnrtransfer'];
			if (id) {
				p.push(id);
			}
			return '/' + p.join('/');
		},
	};
	BNRTRANSFER: IRoute = {
		route: '/datenuebernahme/:id/:sourceBnrId/:targetBnrId?',
		name: 'datenuebernahme',
		container: BnrTransferContainer,
		private: false,
		getPath: (id?: string, sourceBnrId?: string, targetBnrId?: string) => {
			const p = ['datenuebernahme'];
			if (id && sourceBnrId && targetBnrId) {
				p.push(id);
				p.push(sourceBnrId);
				p.push(targetBnrId);
			}
			return '/' + p.join('/');
		},
	};

	BNRTRANSFERDONE: IRoute = {
		route: '/datenuebernahme_done/:id',
		name: 'datenuebernahme_done',
		container: AdminBnrTransferDoneContainer,
		private: false,
		getPath: (id?: string) => {
			const p = ['datenuebernahme_done'];
			if (id) {
				p.push(id);
			}
			return '/' + p.join('/');
		},
	};

	ADMINIMPORTS: IRoute = {
		route: '/admin/imports/:type?/:id?',
		name: 'Home',
		container: AdminImportsContainer,
		private: true,
		getPath: (type?: string, id?: string) => {
			const p = ['admin', 'imports'];
			if (type) {
				p.push(type);
				if (id) {
					p.push(id);
				}
			}
			return '/' + p.join('/');
		},
	};
	ADMINNOTFICATIONS: IRoute = {
		route: '/admin/notifications',
		getPath: () => '/admin/notifications',
		name: 'Notifications',
		container: AdminNotificationsContainer,
		private: true,
	};
	ADMINTEMPLATES: IRoute = {
		route: '/admin/vorlagen/:type?/:id?',
		name: 'Home',
		container: AdminTemplatesContainer,
		private: true,
		getPath: (type?: string, id?: string) => {
			const p = ['admin', 'vorlagen'];
			if (type) {
				p.push(type);
				if (id) {
					p.push(id);
				}
			}
			return '/' + p.join('/');
		},
	};
	ADMINBNRBLACKLIST: IRoute = {
		route: '/admin/bnrblacklist/:type?/:id?',
		name: 'Home',
		container: AdminBnrBlacklistContainer,
		private: true,
		getPath: (type?: string, id?: string) => {
			const p = ['admin', 'bnrblacklist'];
			if (type) {
				p.push(type);
				if (id) {
					p.push(id);
				}
			}
			return '/' + p.join('/');
		},
	};

	ADMINTUSAGE: IRoute = {
		name: 'Tracking',
		container: AdminUsageContainer,
		private: true,
		route: '/admin/usage/:level1?/:level2?/:level3?/:level4?',
		getPath: (level1?: string, level2?: string, level3?: string, level4?: string) => {
			const p = ['admin', 'usage'];
			if (level1) {
				p.push(level1);
				if (level2) {
					p.push(level2);
					if (level3) {
						p.push(level3);
						if (level4) {
							p.push(level4);
						}
					}
				}
			}
			return '/' + p.join('/');
		},
	};
	ADMINCRAWL: IRoute = {
		route: '/admin/crawl',
		name: 'Crawl',
		container: AdminCrawlContainer,
		private: true,
		getPath: () => 'gsvb',
	};

	ADMINNEWS: IRoute = {
		route: '/admin/news/:type?/:id?',
		name: 'News',
		container: AdminNewsContaniner,
		private: true,
		getPath: (type?: string, id?: string) => {
			const p = ['admin', 'news'];
			if (type) {
				p.push(type);
				if (id) {
					p.push(id);
				}
			}
			return '/' + p.join('/');
		},
	};

	ADMINTGSVB: IRoute = {
		route: '/admin/gsvb',
		name: 'GSVB',
		container: AdminGsvbContainer,
		private: true,
		getPath: () => 'gsvb',
	};
	ERRUNGENSCHAFTEN: IRoute = {
		route: '/errungenschaften',
		name: 'Errungenschaften',
		container: ErrungenschaftenContainer,
		private: true,
		getPath: () => 'errungenschaften',
	};
	ERRUNGENSCHAFTENCRAWL: IRoute = {
		route: '/errungenschaften_crawl',
		name: 'Errungenschaften for the Crawler',
		container: ErrungenschaftenCrawlContainer,
		private: true,
		getPath: () => 'errungenschaften_crawl',
	};

	WOCHENMAIL: IRoute = {
		route: '/wochenmail',
		name: 'wochenmail',
		container: WochenmailContainer,
		private: true,
		getPath: () => 'wochenmail',
	};
}
const routes = new Routes();
export default routes;
