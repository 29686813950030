import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AgtFitnessVm } from 'app/stores/ui/agt.fitness.ui.store';
import { formatPercentWithIcon } from 'app/utils';
import { useHistory, useLocation } from 'react-router';
import routes from 'routes';
import { FitnessTrackerGraphics } from './FitnessTrackerGraphics';
import { amisNowProps } from 'app/stores/ui/agt.amisnow.ui.store';
import { EChartsOption, ECharts, init } from 'echarts';
import { debounce } from 'lodash';
import _ from 'lodash';
import colors from 'assets/scss/colors.module.scss';

export const FitnessTrackerAmisNow = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const [selectedAgtId, setSelectedAgtId] = useState<number | undefined>(undefined);
	const history = useHistory();
	const location = useLocation();

	const tm = useMemo(() => {
		const tm = new TableModel<AgtFitnessVm, number>();
		const cols: ITableColumn<AgtFitnessVm, number>[] = [];

		cols.push({
			label: 'Agentur',
			path: 'agt.name',
			sortBy: 'agt.name',
			render: AgenturNameCell,
		});

		/// AMIS.NOW
		const columns = amisNowProps.sort((a, b) => a.sort - b.sort);
		columns.forEach((prop, i) => {
			cols.push({
				label: prop.label,
				path: prop.path,
				sortBy: prop.path,
				cellModifier: `${i === 0 && 'border-left-turquoise'} bg-turquoise`,
				labelModifier: `${i === 0 && 'border-left-turquoise'} bg-turquoise`,
			});
		});
		cols.find((c) => c.path === 'amisNow.item.quoteRead')!.format = (val) => formatPercentWithIcon(val);

		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.onRowClick = (row) => {
			const p = routes.BERICHTE.getPath('bestand', 'fit', 'amisnowaufgaben', row.data.agt.agtId.toString());
			history.push(p);
		};

		tm.idProperty = 'agtId';
		tm.idType = 'number';

		return tm;
	}, [history]);

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);

	useEffect(() => {
		// das hier ginge auch nicer, via treeNode Model und openByPath
		const lastPathElement = location.pathname.split('/').pop();
		let agtId;
		if (typeof lastPathElement === 'string') {
			agtId = parseInt(lastPathElement);
		}
		if (agtId && !isNaN(agtId)) {
			setSelectedAgtId(agtId);
		} else {
			setSelectedAgtId(undefined);
		}
	}, [location]);

	const onClose = () => {
		const p = routes.BERICHTE.getPath('bestand', 'fit', 'amisnowaufgaben');
		history.push(p);
	};

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Fitnesstrackerdaten gefunden</div>
			) : (
				<>
					{selectedAgtId && <FitnessTrackerGraphics agtId={selectedAgtId} view="amis" onClose={onClose} />}
					<Table stickyHeader={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});

interface IFitnessTrackerAmisChart {
	fitVm: AgtFitnessVm;
}
export const FitnessTrackerAmisChart = observer((props: IFitnessTrackerAmisChart) => {
	const { fitVm } = props;

	const excludes = ['amisNow.item.summe', 'amisNow.item.davonRead', 'amisNow.item.quoteRead'];
	const columns = amisNowProps.sort((a, b) => b.sort - a.sort).filter((p) => excludes.indexOf(p.path) === -1);

	// echarts stuff
	const chartRef = useRef<HTMLDivElement>(null);
	const sourceData = columns.map((c) => ({ label: c.label, val: _.get(fitVm, c.path) }));

	useEffect(() => {
		const dim = ['label', 'val'];
		const chartHeight = 500;

		let option: EChartsOption = {
			color: colors.turquoise,
			dataset: {
				dimensions: dim,
				source: sourceData,
			},
			yAxis: {
				type: 'category',
				axisLabel: {
					interval: 0,
					fontSize: 14,
					padding: [0, 5, 0, 0],
				},
			},
			xAxis: {
				type: 'value',
			},
			series: {
				type: 'bar',
				encode: {
					y: 'label',
					x: 'val',
				},
			},
			legend: {
				show: true,
			},
			tooltip: {
				trigger: 'item',
			},
			grid: {
				containLabel: true,
			},
		};

		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, null, { renderer: 'svg', height: chartHeight });
			chart.setOption(option);
		}
		const handleResize = debounce(() => {
			chart?.resize();
		}, 300);
		window.addEventListener('resize', handleResize);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', handleResize.cancel);
		};
	}, [chartRef, sourceData]);

	return (
		<>
			<div style={{ width: '100%', height: 'auto', background: '#ffffff' }} ref={chartRef}></div>
		</>
	);
});
