import { ISankeyData, SankeyChart } from 'app/components/charts/SankeyChart';
import { useStore } from 'app/context';
import { AgtAkumaPrimeKwVM, AkumaPrimeType } from 'app/stores/ui/agt.akumaprime.ui.store';

import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import colors from 'assets/scss/colors.module.scss';
import _ from 'lodash';
import { countArray } from 'app/utils';

interface IAkumaPrimeSankeyData extends ISankeyData {
	einordnung?: string[];
	beziehung?: string[];
	terminierung?: string[];
	terminierungErgebnis?: string[];
}

const getSchema = (type: AkumaPrimeType) => {
	if (type === AkumaPrimeType.terminierung) return terminierungsSankeySchema;
	return einordnungsSankeySchema;
};

const einordnungsSankeySchema: IAkumaPrimeSankeyData[] = [
	{ source: 'Eingeordnet', target: 'Kranken', targetColor: colors.green, einordnung: ['K'] },
	{ source: 'Eingeordnet', target: 'Leben', targetColor: colors.blue, einordnung: ['L'] },
	{ source: 'Eingeordnet', target: 'Sach', targetColor: colors.yellow, einordnung: ['S'] },
	{ source: 'Eingeordnet', target: 'Sonstige', targetColor: colors.orange, einordnung: ['SO'] },
	{ source: 'Eingeordnet', target: 'Bestehende Aktion', targetColor: colors.purple, einordnung: ['BA'] },
	{ source: 'Eingeordnet', target: 'Nicht kontaktieren', targetColor: colors.richRed, einordnung: ['NK'] },
	{ source: 'Einzuordnende Kunden', target: 'Eingeordnet', sourceColor: colors.blue, targetColor: colors.richGreen, einordnung: ['K', 'L', 'S', 'SO', 'BA', 'NK'] },
	{ source: 'Einzuordnende Kunden', target: 'Nicht eingeordnet', sourceColor: colors.turquoise, targetColor: colors.greyLight, einordnung: ['NE'] },
];

const terminierungsSankeySchema: IAkumaPrimeSankeyData[] = [
	{ source: 'Terminierung', target: 'Terminierung durch Agentur', targetColor: colors.darkYellow, terminierung: ['AGT'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Terminierung', target: 'Externe Terminierung', targetColor: colors.richTurquoise, terminierung: ['CC'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Terminierung durch Agentur', target: 'Beziehung unbekannt', targetColor: colors.darkYellow, terminierung: ['AGT'], beziehung: ['BZU'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Terminierung durch Agentur', target: 'Beziehung weniger gut', targetColor: colors.richRed, terminierung: ['AGT'], beziehung: ['BZWG'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Terminierung durch Agentur', target: 'Beziehung mittel', targetColor: colors.richBlue, terminierung: ['AGT'], beziehung: ['BZM'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Terminierung durch Agentur', target: 'Beziehung gut', targetColor: colors.richGreen, terminierung: ['AGT'], beziehung: ['BZG'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Externe Terminierung', target: 'Beziehung unbekannt ', targetColor: colors.darkYellow, terminierung: ['CC'], beziehung: ['BZU'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Externe Terminierung', target: 'Beziehung weniger gut ', targetColor: colors.richRed, terminierung: ['CC'], beziehung: ['BZWG'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Externe Terminierung', target: 'Beziehung mittel ', targetColor: colors.richBlue, terminierung: ['CC'], beziehung: ['BZM'], einordnung: ['K', 'L', 'S', 'SO'] },
	{ source: 'Externe Terminierung', target: 'Beziehung gut ', targetColor: colors.richGreen, terminierung: ['CC'], beziehung: ['BZG'], einordnung: ['K', 'L', 'S', 'SO'] },
];

function getChartData(schema: IAkumaPrimeSankeyData[], data: AgtAkumaPrimeKwVM[]): ISankeyData[] {
	const ret: ISankeyData[] = [];

	// iterate through sankey schema nodes
	schema.forEach((node) => {
		let val = 0;
		data.forEach((dataKwVM) => {
			let filteredData = dataKwVM.items.filter(
				(d) =>
					(!node.einordnung || node.einordnung.includes(d.einordnung)) &&
					(!node.beziehung || node.beziehung.includes(d.beziehung)) &&
					(!node.terminierung || node.terminierung.includes(d.terminierung)) &&
					(!node.terminierungErgebnis || node.terminierungErgebnis.includes(d.terminierungErgebnis)),
			);
			val += filteredData.reduce((sum, child) => sum + child.count, 0);
		});

		ret.push({ ...node, value: val });
	});

	return ret;
}

interface IAkumaPrimeSankeyChartType {
	type: AkumaPrimeType;
}
export const AkumaPrimeSankeyChart = observer((props: IAkumaPrimeSankeyChartType) => {
	const { agtAkumaPrimeUiStore, berichteUiiStore, agenturListUiStore } = useStore();

	const [chartData, setChartData] = useState<ISankeyData[]>();
	const [itemsTotal, setItemsTotal] = useState<number>(0);

	useEffect(() => {
		let kwdata = agtAkumaPrimeUiStore.currentKWData;
		if (berichteUiiStore.selectedAgt) {
			const agtId = berichteUiiStore.selectedAgt.agtId;
			kwdata = agtAkumaPrimeUiStore.filterByAgtIdandKw(agtId);
			kwdata = _.sortBy(kwdata, 'bm').reverse();
		} else {
			kwdata = agenturListUiStore.applyFilterOnAgt(kwdata) as AgtAkumaPrimeKwVM[];
		}

		const schema = getSchema(props.type);
		const cdata = getChartData(schema, kwdata);
		setChartData(cdata);

		const einordnungTotal = countArray(kwdata.map((e) => e.einordnungTotal));
		const terminierungTotal = countArray(kwdata.map((e) => e.zuKontaktierendeKunden));
		setItemsTotal(props.type === AkumaPrimeType.terminierung ? terminierungTotal : einordnungTotal);
	}, [agenturListUiStore, agenturListUiStore.filterHasChanged, agtAkumaPrimeUiStore, agtAkumaPrimeUiStore.currentKWItems, berichteUiiStore.selectedAgt, props.type]);

	const labelFormat = (params: any) => {
		const perc: string = itemsTotal > 0 ? `{italic|(${Math.round((params.value / itemsTotal) * 100)}%)}` : '';
		return `{bold|${params.name}} ${params.value} ${perc}`;
	};

	return <>{chartData && <SankeyChart data={chartData} labelFormatter={labelFormat} />}</>;
});
