import { action, makeObservable, observable } from "mobx";
import { AdminImportsStore, ImportDataType, ImportJobModel } from "../admin.imports.store";
import { UiStore } from "../ui.store";
import { IAdminUploadOptions } from "app/components/admin/AdminFileUpload";
import moment from "moment";
import { notify } from "app/components/common/notify";


export const isDvsImportFileName = (fileName: string, dataType: ImportDataType): boolean => {
    const regex = new RegExp(`^${dataType}_\\d{8}(_\\w+)?_?.*\\.csv$`);
    return regex.test(fileName);
}

export const extractDateFromFileName = (fileName: string): Date | null => {
    const match = fileName.match(/_(\d{8})(?:_|\.csv)/);
    if (match) {
        return moment(match[1], 'YYYYMMDD').toDate();
    }
    return null;
}

export class AdminFileUploadUiStore {

    uiStore: UiStore;
    adminImportsStore: AdminImportsStore

    constructor(uiStore: UiStore, adminImportsStore: AdminImportsStore) {
        makeObservable(this);
        this.uiStore = uiStore;
        this.adminImportsStore = adminImportsStore;
    }


    @observable
    newFilename?: string;
    @observable
    orgFilename?: string;
    onFileUploaded?: () => void;
    @action
    _newUpload(filename: string) {
        this.orgFilename = filename;
        this.newFilename = undefined;
        this.onFileUploaded = undefined;
    }



    @action
    async uploadFile(file: any, opts: IAdminUploadOptions, onProgres: (p: number) => void) {
        if (opts.dataType && ![ImportDataType.aup, ImportDataType.aupvorjahr].includes(opts.dataType)) {
            this._newUpload(file.path);
            this.uiStore.showModal(this.uiStore.modalIds.adminFileUploadRenamFile);
            while (this.newFilename === undefined) {
                await new Promise((resolve) => setTimeout(resolve, 100));
            }
            this.uiStore.hideModal(this.uiStore.modalIds.adminFileUploadRenamFile);
            if (this.newFilename === 'cancel') {
                this.uiStore.setIsUploading(false);
                return;
            }
            opts.fileName = this.newFilename;
        }
        await this.adminImportsStore.uploadFile(file, opts, onProgres);
        if (this.onFileUploaded) {
            this.onFileUploaded();
        }

    }

    calcNewFileName(stand: Date, dataType: ImportDataType, zusatz?: string,) {
        const d = moment(stand).format('YYYYMMDD');
        const z = zusatz ? '_' + zusatz : '';
        return dataType + '_' + d + z + '.csv'
    }
    isDvsImportFileName(fileName: string, dataType: ImportDataType): boolean {
        return isDvsImportFileName(fileName, dataType);
    }
    extractDateFromFileName(fileName: string): Date | null {
        return extractDateFromFileName(fileName);
    }

    prepareJobForTrigger(fileName: string, job: ImportJobModel) {
        if (!job.meta) {
            job.meta = {};
        }
        job.meta.fileName = fileName;
        job.meta.datenStand = this.extractDateFromFileName(fileName);
        if (!job.meta.datenStand) {
            notify('Kein Datenstand ', 'Job konnte nicht gestartet werden', 'info', 10000);
            return;
        }
        if (job.needsBM) {
            job.meta.kw = job.getBmFromFileName(job.meta.datenStand);
            if (!job.meta.kw) {
                notify('Kein KW aus Datenstand ', 'Job konnte nicht gestartet werden', 'info', 10000);
                return;
            }
        }
        return job;
    }


    @action
    async renameFile(dataType: ImportDataType, orgFilename: string) {
        this.orgFilename = orgFilename;
        this.newFilename = undefined;
        this.uiStore.showModal(this.uiStore.modalIds.adminFileUploadRenamFile);
        while (this.newFilename === undefined) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }
        this.uiStore.hideModal(this.uiStore.modalIds.adminFileUploadRenamFile);
        if (this.newFilename === 'cancel') {
            return;
        }
        const newFileName = this.newFilename;

        return this.adminImportsStore.renameFile(dataType, this.orgFilename, newFileName);

    }


}