import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import { Steart } from './steart.model';
interface IMenuItemModel {
	name: string;
	icon: string;
	path: string;
	hideForStearts?: Steart[];
	showForStearts?: Steart[];
}

export class MenuItemModel {
	constructor(opts: IMenuItemModel) {
		makeAutoObservable(this);
		this.name = opts.name;
		this.icon = opts.icon;
		this.path = opts.path;
		this._path = opts.path;
		if (opts.hideForStearts) {
			this.hideForStearts = opts.hideForStearts;
		}
		if (opts.showForStearts) {
			this.showForStearts = opts.showForStearts;
		}
	}
	name: string;
	icon: string;
	path: string;
	_path: string;

	hideForStearts: Steart[] = [];
	showForStearts: Steart[] = [];

	@action
	updatePath(p: string) {
		this.path = p;
	}
	active: boolean = false;
}

export class MenuModel {
	constructor(items: MenuItemModel[], comparer?: (i: MenuItemModel, p: string) => boolean) {
		makeObservable(this);
		this.items = items;
		if (comparer) {
			this.pathComparer = comparer;
		}
	}

	@action
	_updateActive() {
		const p = this.activePath;
		const items = this.items;
		items.forEach((i) => {
			i.active = this.pathComparer(i, p);
		});
	}

	pathComparer(i: MenuItemModel, p: string): boolean {
		if (i.path === p) {
			return true;
		} else if (p.length > 1 && i.path.startsWith(p)) {
			return true;
		} else {
			return false;
		}
	}

	@observable items: MenuItemModel[] = [];
	@observable activePath: string = '';
	@action
	setActivePath(path: string) {
		this.activePath = path;
		this._updateActive();
	}
}

const vblMenuItems: MenuItemModel[] = [];
vblMenuItems.push(new MenuItemModel({ name: 'Home', icon: 'home', path: '/' }));
vblMenuItems.push(new MenuItemModel({ name: 'Meine Notizen', icon: 'sticky-note', path: '/notizen' }));
vblMenuItems.push(new MenuItemModel({ name: 'Agenturen', icon: 'building', path: '/agentur' }));
vblMenuItems.push(new MenuItemModel({ name: 'Team', icon: 'users', path: '/team', showForStearts: [900] }));
vblMenuItems.push(new MenuItemModel({ name: 'Berichte', icon: 'chart-pie', path: '/berichte' }));
vblMenuItems.push(new MenuItemModel({ name: 'Anwendungen', icon: 'cube', path: '/anwendungen' }));
vblMenuItems.push(new MenuItemModel({ name: 'Dokumente', icon: 'archive', path: '/dokumente' }));
vblMenuItems.push(new MenuItemModel({ name: 'Maßnahmen', icon: 'tasks-alt', path: '/massnahmen', hideForStearts: [900, 1001, 1002, 1003] }));
vblMenuItems.push(new MenuItemModel({ name: 'Verlinkungen', icon: 'external-link-square', path: '/links' }));
vblMenuItems.push(new MenuItemModel({ name: 'Feedback', icon: 'envelope', path: '/feedback' }));

const adminMenuItems: MenuItemModel[] = [];
adminMenuItems.push(new MenuItemModel({ name: 'Home', icon: 'home', path: '/admin/' }));
adminMenuItems.push(new MenuItemModel({ name: 'Benutzer', icon: 'users', path: '/admin/users' }));
adminMenuItems.push(new MenuItemModel({ name: 'BNÄRnderungen', icon: 'users', path: '/admin/bnrdiff' }));
adminMenuItems.push(new MenuItemModel({ name: 'BNÄRnderungen (Multis)', icon: 'car', path: '/admin/bnrdiffmulti' }));
adminMenuItems.push(new MenuItemModel({ name: 'Datenübernahme', icon: 'exchange-alt', path: '/admin/bnrtransfer' }));
adminMenuItems.push(new MenuItemModel({ name: 'Daten Importieren', icon: 'cloud-upload-alt', path: '/admin/imports' }));
adminMenuItems.push(new MenuItemModel({ name: 'News', icon: 'newspaper', path: '/admin/news' }));
adminMenuItems.push(new MenuItemModel({ name: 'Vorlagen', icon: 'file-alt', path: '/admin/vorlagen' }));
adminMenuItems.push(new MenuItemModel({ name: 'Bnr Blacklist', icon: 'ban', path: '/admin/bnrblacklist' }));
adminMenuItems.push(new MenuItemModel({ name: 'DVS Nutzung', icon: 'analytics', path: '/admin/usage' }));
adminMenuItems.push(new MenuItemModel({ name: 'GS/VB', icon: 'analytics', path: '/admin/gsvb' }));
adminMenuItems.push(new MenuItemModel({ name: 'Berichte', icon: 'chart-pie', path: '/berichte' }));
adminMenuItems.push(new MenuItemModel({ name: 'Agenturen', icon: 'building', path: '/agentur' }));
adminMenuItems.push(new MenuItemModel({ name: 'Danger Zone', icon: 'skull-crossbones', path: '/admin/panik' }));

// adminMenuItems.push(new MenuItemModel({ name: 'Logins', icon: 'chart-pie', path: '/admin/userlogins' }));

const MainMenuPathComparer = (i: MenuItemModel, p: string): boolean => {
	if (p.length <= 1 && i.path === p) {
		return true;
	} else if (i.path.length > 1 && p.startsWith(i.path)) {
		return true;
	} else {
		return false;
	}
};
export const AdminMenu = new MenuModel(adminMenuItems, MainMenuPathComparer);

export const VblMenu = new MenuModel(vblMenuItems, MainMenuPathComparer);

const agtMenuItems: MenuItemModel[] = [];
agtMenuItems.push(new MenuItemModel({ name: 'Übersicht', icon: '', path: '' }));
agtMenuItems.push(new MenuItemModel({ name: 'Planung', icon: '', path: 'planung' }));
agtMenuItems.push(new MenuItemModel({ name: 'Berichte', icon: '', path: 'berichte' }));
agtMenuItems.push(new MenuItemModel({ name: 'Anwendungen', icon: '', path: 'anwendungen' }));
agtMenuItems.push(new MenuItemModel({ name: 'Dokumente', icon: '', path: 'dokumente' }));
agtMenuItems.push(new MenuItemModel({ name: 'Maßnahmen', icon: '', path: 'massnahmen' }));

const multiAgtMenuItems: MenuItemModel[] = [];
multiAgtMenuItems.push(new MenuItemModel({ name: 'Übersicht', icon: '', path: '' }));
multiAgtMenuItems.push(new MenuItemModel({ name: 'Planung', icon: '', path: 'planung' }));
multiAgtMenuItems.push(new MenuItemModel({ name: 'Berichte', icon: '', path: 'berichte' }));
multiAgtMenuItems.push(new MenuItemModel({ name: 'Dokumente', icon: '', path: 'dokumente' }));

export const AgtPathComparer = (i: MenuItemModel, p: string) => {
	if (!p && i.name === 'Übersicht') {
		return true;
	}
	if (p && i.path.endsWith(p)) {
		return true;
	}
	return false;
};

export const AgtMenu = new MenuModel(agtMenuItems, AgtPathComparer);
export const MultiAgtMenu = new MenuModel(multiAgtMenuItems, AgtPathComparer);

const teamMenuItems: MenuItemModel[] = [];
teamMenuItems.push(new MenuItemModel({ name: 'Übersicht', icon: '', path: '' }));
teamMenuItems.push(new MenuItemModel({ name: 'Dokumente', icon: '', path: 'dokumente' }));

export const TeamPathComparer = (i: MenuItemModel, p: string) => {
	if (!p && i.name === 'Übersicht') {
		return true;
	}
	if (p && i.path.endsWith(p)) {
		return true;
	}
	return false;
};

export const TeamMenu = new MenuModel(teamMenuItems, TeamPathComparer);
