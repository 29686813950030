import { Button } from 'app/components/common/Button';
import { notify } from 'app/components/common/notify';
import { useStore } from 'app/context';
import { ImportDataType } from 'app/stores/admin.imports.store';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import { formatDate, formatDateLong } from 'app/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../../Panel';
import { AdminAupVorjahrForm } from './AdminAupVorjahrForm';
import { AdminImportNewAupForm } from './AdminImportNewAupForm';
import { AdminImportNewCrawlerForm } from './AdminImportNewCrawlerForm';
import ReactJson from 'react-json-view';
import { AggregrationType, ITableColumn, TableModel } from 'app/components/table/table.model';
import { WochenmailUserVM } from 'app/stores/ui/admin.wochenmail.ui.store';
import { Table } from 'app/components/table/table';
import { AdminImportForm } from './AdminImportForm';

interface IAdminImportJobDetail {
	job: ImportJobVM;
}

export const AdminImportJobDetail = observer((props: IAdminImportJobDetail) => {
	const { adminImportsUiStore } = useStore();

	const history = useHistory();

	const job = props.job;

	const onRerun = async () => {
		await adminImportsUiStore.adminImportsStore.rerun(job.job.id);
		notify('Job wurde erneut gestartet', '', 'info');
		setTimeout(() => {
			adminImportsUiStore.reload();
		}, 500);
	};

	const onJobDelete = async () => {
		if (!window.confirm('sicher??? ')) {
			return;
		}
		await adminImportsUiStore.adminImportsStore.deleteJob(job.job.id);

		notify('Job wurde erneut gelöscht', '', 'info');
		setTimeout(() => {
			adminImportsUiStore.reload();
			history.push(routes.ADMINIMPORTS.getPath());
		}, 500);
	};

	const download = async () => {
		if (!job.job.meta && !job.job.meta.fileName) {
			alert('Keine Date zum reunterladen');
			return;
		}
		await adminImportsUiStore.adminImportsStore.downloadJobFile(job.job.dataType, job.job.meta.fileName);
	};

	return (
		<>
			<table className="table">
				<tbody>
					<tr>
						<td>
							<strong>Job</strong>
						</td>
						<td>
							{job.job.dataType} <strong>{job.KW}</strong>
						</td>
					</tr>
					<tr>
						<td>Status</td>
						<td>{job.job.status}</td>
					</tr>
					<tr>
						<td>Ausführungsdatum</td>
						<td>{formatDateLong(job.job.created)}</td>
					</tr>
					<tr>
						<td>Datenstand</td>
						<td>{formatDate(job.job.meta?.datenStand)}</td>
					</tr>
					<tr>
						<td>Datei</td>
						<td>
							{job.job.meta && (
								<>
									{job.job.meta.fileName} &nbsp;
									<Button type="button" onClick={download} className={'button'}>
										Download
									</Button>
								</>
							)}
						</td>
					</tr>
					<tr>
						<td>Dauer</td>
						<td>{job.job.duration}</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<Button type={'button'} className={'button is-primary'} onClick={onRerun}>
								Job erneut ausführen
							</Button>
							<Button type={'button'} className={'button is-primary'} onClick={onJobDelete}>
								Job löschen
							</Button>
						</td>
					</tr>
				</tbody>
			</table>
			{job.job.hasError && (
				<>
					<strong>Fehler:</strong>
					<br />

					{job.job.message && <ReactJson src={job.job.message} />}
					{/* {!job.isJsonError && <pre>{job.fehler}</pre>} */}
				</>
			)}
			{job.job.hasError2 && (
				<>
					<strong>Fehler:</strong>
					<br />

					{job.job.message && <ReactJson src={job.job.message} />}
					{/* {!job.isJsonError && <pre>{job.fehler}</pre>} */}
				</>
			)}
		</>
	);
});

export const AupJobResults = observer((props: IAdminImportJobDetail) => {
	const results = props.job.job.results;

	return (
		<>
			<table className={'table'}>
				<thead>
					<tr>
						<th>Datei</th>
						<th>Dauer</th>
						<th>Fehler?</th>
						<th>Log</th>
					</tr>
				</thead>
				<tbody>
					{results.map((result) => (
						<tr key={result.key}>
							<td>{result.key}</td>
							<td>{result.duration}</td>
							<td>{result.hasErrors && <>Ja</>}</td>
							<td>{result.result}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
});

export const AladinJobResults = observer((props: IAdminImportJobDetail) => {
	const results = props.job.job.results;

	return (
		<>
			<table className={'table'}>
				<tbody>
					{results.map((result) => (
						<tr key={result.key}>
							<td>{result.key}</td>
							<td>{result.result}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
});
export const CrawlerJobResults = observer((props: IAdminImportJobDetail) => {
	const message = props.job.job.message;
	if (!message) {
		return <></>;
	}

	return (
		<>
			<table className={'table'}>
				<tbody>
					<tr>
						<td>Completed</td>
						<td>{message.completed}</td>
					</tr>
					<tr>
						<td>Success</td>
						<td>{message.success}</td>
					</tr>
					<tr>
						<td>Error</td>
						<td>{message.error}</td>
					</tr>
				</tbody>
			</table>
			<CrawlerWochenmailSummary {...props} />
		</>
	);
});

export const CrawlerWochenmailSummary = observer((props: IAdminImportJobDetail) => {
	const { adminWochenmailUiStore } = useStore();

	const tm = useMemo(() => {
		const valCols = ['agt', 'bnr', 'newsItems', 'bds', 'jbsl', 'endingExprts', 'newShrdDcs', 'tasksDue'];

		const tm = new TableModel<WochenmailUserVM, number>();
		let cols: ITableColumn<WochenmailUserVM, number>[] = [
			{
				label: 'Benutzer',
				path: 'user.user.name',
			},
			{
				label: 'Steart',
				path: 'user.user.steart.name',
			},
		];

		valCols.forEach((c) => {
			cols.push({
				label: c,
				path: 'mail.summary.' + c,
				agg: [
					{
						path: 'mail.summary.' + c,
						aggType: AggregrationType.count,
					},
					{
						path: 'mail.summary.' + c,
						aggType: AggregrationType.sum,
					},
				],
			});
		});

		tm.setCols(cols);
		tm.sortBy = 'job.created';
		tm.sortAsc = false;
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		adminWochenmailUiStore.currentKw = parseInt(props.job.KW);
		adminWochenmailUiStore.load().then(() => {
			tm.setRowData(adminWochenmailUiStore.currentItems);
		});
	}, [adminWochenmailUiStore, props.job.KW, adminWochenmailUiStore.currentItems]);

	return (
		<>
			<div>Emails versand: {tm.data.length}</div>
			<Table stickyHeader={true} tm={tm}></Table>
		</>
	);
});

export const AdminImportJobDetailPanel = observer(() => {
	const [title, setTitle] = useState('');

	const { adminImportsUiStore } = useStore();
	const job = adminImportsUiStore.current;
	const newJob = adminImportsUiStore.newJob;
	const history = useHistory();
	useEffect(() => {
		if (adminImportsUiStore.newJob) {
			setTitle('Neuer Job ' + adminImportsUiStore.newJob.job.dataType);
		}
		if (adminImportsUiStore.current) {
			setTitle('Job Details ' + adminImportsUiStore.current.job.dataType);
		}
	}, [adminImportsUiStore.newJob, adminImportsUiStore]);

	const onClose = () => {
		runInAction(() => {
			adminImportsUiStore.current = undefined;
			adminImportsUiStore.newJob = undefined;
			history.push(routes.ADMINIMPORTS.getPath());
		});
	};

	return (
		<>
			{(job || newJob) && (
				<Panel title={title} header={<> </>} onClose={onClose}>
					<div>
						{job && (
							<>
								<AdminImportJobDetail job={job} />
								{/* {job.job.dataType === ImportDataType.aup && <AupJobResults job={job} />} */}
								{job.job.dataType === ImportDataType.aupvorjahr && <AdminAupVorjahrForm job={job} onClose={onClose} />}
								{job.job.dataType === ImportDataType.aladin && <AladinJobResults job={job} />}
								{job.job.dataType === ImportDataType.aladin2 && <AladinJobResults job={job} />}
								{job.job.dataType === ImportDataType.crawler && <CrawlerJobResults job={job} />}
							</>
						)}
						{newJob && (
							<>
								{newJob.job.dataType === ImportDataType.aup && <AdminImportNewAupForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.aupvorjahr && <AdminAupVorjahrForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.crawler && <AdminImportNewCrawlerForm job={newJob} onClose={onClose} />}

								{newJob.job.dataType === ImportDataType.leadnow && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.aladin && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.aladin2 && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.agenturcluster && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.digitalreport && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.akumaprime && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.akumaplaner && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.multisagentur && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.amisnow && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.googleprofile && <AdminImportForm job={newJob} onClose={onClose} />}
								{newJob.job.dataType === ImportDataType.multisgp && <AdminImportForm job={newJob} onClose={onClose} />}
							</>
						)}

					</div>
				</Panel>
			)}
		</>
	);
});
