import { action, computed, makeObservable } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { AgtGoogleProfileUiStore, GoogleProfileVM } from './agt.googleprofile.ui.store';
import { AgenturModel } from 'app/models/agentur.model';
import { AgtAkumaPrimeKwVM, AgtAkumaPrimeUiStore } from './agt.akumaprime.ui.store';
import { AgtAkumaPlanerUiStore, AgtAkumaPlanerVM } from './agt.akumaplaner.ui.store';
import { AgtDigitalreportUiStore, DigitalReportVM } from './agt.digitalreport.ui.store';
import { BaseUiStore } from './base.ui.store';
import { AgtAmisNowUiStore, AmisNowVm } from './agt.amisnow.ui.store';

interface IFitModel {
	ziel?: number;
	ergebnis?: number;
	max?: number;
}

export class FitModel {
	constructor(opts: IFitModel) {
		makeObservable(this);
		this.ergebnis = opts.ergebnis;
		this.ziel = opts.ziel;
		this.max = opts.max;
	}

	ergebnis?: number;
	ziel?: number;
	max?: number;

	@computed
	get ze() {
		if (!this.ziel || !this.ergebnis || this.ziel === 0) {
			return 0;
		}
		const z = this.ziel;
		const e = this.ergebnis;
		if (e > z) return 100;
		let ze = (e / z) * 100;
		ze = Math.round(ze * 100) / 100;
		return ze;
	}
}

export class AgtFitnessVm {
	agt: AgenturModel;
	gup?: GoogleProfileVM;
	prime?: AgtAkumaPrimeKwVM;
	planer?: AgtAkumaPlanerVM;
	digiReport?: DigitalReportVM;
	amisNow?: AmisNowVm;
	isPrime?: boolean;

	keineDaten: boolean = false;

	constructor(agt: AgenturModel, gup?: GoogleProfileVM, prime?: AgtAkumaPrimeKwVM, planer?: AgtAkumaPlanerVM, digiReport?: DigitalReportVM, ami?: AmisNowVm, isPrime?: boolean) {
		this.agt = agt;
		this.gup = gup;
		this.prime = prime;
		this.planer = planer;
		this.digiReport = digiReport;
		this.amisNow = ami;
		this.isPrime = isPrime;

		if (!digiReport && !planer && !prime && !gup && !ami) {
			this.keineDaten = true;
		}
	}

	@computed
	get googleExists() {
		return !!this.gup ? 1 : 0;
	}

	@computed
	get primeExists() {
		return !!this.prime ? 1 : 0;
	}

	@computed
	get planerExists() {
		return !!this.planer ? 1 : 0;
	}

	@computed
	get mazExists() {
		if (this.digiReport && this.mazBestandDurchdringungsquote && this.mazBestandDurchdringungsquote.ziel! >= 0) {
			return 1;
		}
		return 0;
	}

	@computed
	get amisNowExists() {
		return !!this.amisNow ? 1 : 0;
	}

	//// GOOGLE ///////
	@computed
	get gupAverage() {
		if (!this.gup) {
			return new FitModel({});
		}
		const vals = [this.anzBew.ze, this.bewAvg.ze, this.imgCount.ze, this.qCount.ze];
		const res = vals.reduce((acc, val) => acc + val, 0) / vals.length;

		return new FitModel({
			ziel: 100,
			ergebnis: res,
		});
	}

	@computed
	get anzBew() {
		if (!this.gup) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: 25,
			ergebnis: this.gup.bew,
			max: 100,
		});
	}

	@computed
	get bewAvg() {
		if (!this.gup) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: 5,
			ergebnis: this.gup.bewAvg,
		});
	}

	@computed
	get imgCount() {
		if (!this.gup) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: 5,
			ergebnis: this.gup.imgCount,
		});
	}

	@computed
	get qCount() {
		if (!this.gup) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: 5,
			ergebnis: this.gup.qCount,
		});
	}

	//// AMIS.NOW  ///////
	@computed
	get amisNowQuoteRead() {
		if (!this.amisNow) {
			return new FitModel({});
		}

		return new FitModel({
			ziel: this.amisNow.item?.summe,
			ergebnis: this.amisNow.item?.davonRead,
		});
	}

	//// AKUMA  ///////
	@computed
	get isAkumaPrimeUser() {
		if (this.prime && this.prime.einordnungsQuote > 0) {
			return 1;
		}
		return 0;
	}

	@computed
	get planerAnzahlWochenNutzung() {
		if (!this.planer) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: this.planer.kwItems.length,
			ergebnis: this.planer.gpWochen,
		});
	}

	@computed
	get planerUebertragungquoteAmisNow() {
		// ?AmisNow?
		if (!this.planer) {
			return new FitModel({});
		}
		return new FitModel({
			// ziel: 40,
			// ergebnis: this.planer.uebertragungsQuote,
			ziel: this.planer.gesamt,
			ergebnis: this.planer.zu,
		});
	}

	@computed
	get primeEinordnungsquote() {
		if (!this.prime) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: this.prime.einordnungTotal,
			ergebnis: this.prime.eingeordnetTotal,
		});
	}

	@computed
	get primeAbwahlquote() {
		if (!this.prime) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: this.prime.eingeordnetTotal,
			ergebnis: this.prime.abwahl,
		});
	}

	@computed
	get primeTerminierungExtern() {
		if (!this.prime) {
			return new FitModel({});
		}
		return new FitModel({
			ziel: this.prime.zuKontaktierendeKunden,
			ergebnis: this.prime.terminierungExternTotal,
		});
	}

	/// Meine Allianz
	@computed
	get mazBestandDurchdringungsquote() {
		if (!this.digiReport) {
			return new FitModel({ ziel: -1 });
		}

		const anz = this.digiReport.pos.anzKunden18;
		const erg = this.digiReport.pos.anzMaz18;

		// erg = Math.round(erg);
		return new FitModel({
			ziel: anz,
			ergebnis: erg,
		});
	}

	@computed
	get mazNeuDurchdringungsquote() {
		if (!this.digiReport) {
			return new FitModel({});
		}
		const anz = this.digiReport.pos.anzKundenNeu;
		const erg = this.digiReport.pos.anzMazNeu;

		return new FitModel({
			ziel: anz,
			ergebnis: erg,
		});
	}
}

export class AgtFitnessUiStore extends BaseUiStore<AgtFitnessVm> {
	name = 'AgtFitnessUiStore#';
	agtDigitalreportUiStore: AgtDigitalreportUiStore;
	agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore;
	agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore;
	agtGoolgeProfileUiStore: AgtGoogleProfileUiStore;
	agtAmisNowUiStore: AgtAmisNowUiStore;
	agenturStore: AgenturStore;

	constructor(
		agenturStore: AgenturStore,
		agtGoolgeProfileUiStore: AgtGoogleProfileUiStore,
		agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore,
		agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore,
		agtDigitalreportUiStore: AgtDigitalreportUiStore,
		agtAmisNowUiStore: AgtAmisNowUiStore,
	) {
		super();
		this.agtDigitalreportUiStore = agtDigitalreportUiStore;
		this.agtAkumaPlanerUiStore = agtAkumaPlanerUiStore;
		this.agtAkumaPrimeUiStore = agtAkumaPrimeUiStore;
		this.agtGoolgeProfileUiStore = agtGoolgeProfileUiStore;
		this.agtAmisNowUiStore = agtAmisNowUiStore;
		this.agenturStore = agenturStore;
	}

	@action
	async _baseLoad() {
		const promises = await Promise.all([
			this.agenturStore.findAll(),
			this.agtGoolgeProfileUiStore.load(),
			this.agtAkumaPrimeUiStore.load(),
			this.agtAkumaPlanerUiStore.load(),
			this.agtDigitalreportUiStore.load(),
			this.agtAmisNowUiStore.load(),
		]);
		// const promises = await Promise.all([this.agenturStore.findAll(), this.agtGoolgeProfileUiStore.load(), this.agtDigitalreportUiStore.findAll()]);

		// set the the date ranges:
		this.agtGoolgeProfileUiStore.bmCurrent = this.agtGoolgeProfileUiStore.maxBm;
		this.agtAkumaPrimeUiStore.minKwCurrent = this.agtAkumaPrimeUiStore.firstWeek;
		this.agtAkumaPrimeUiStore.maxKwCurrent = this.agtAkumaPrimeUiStore.lastWeek;
		this.agtAkumaPlanerUiStore.minKwCurrent = this.agtAkumaPlanerUiStore.firstWeek;
		this.agtAkumaPlanerUiStore.maxKwCurrent = this.agtAkumaPlanerUiStore.lastWeek;

		let gups = this.agtGoolgeProfileUiStore.currentBMItems.slice();
		let primes = this.agtAkumaPrimeUiStore.currentKWItems.slice();
		let plans = this.agtAkumaPlanerUiStore.currentKWItems.slice();
		let digis = this.agtDigitalreportUiStore.items.slice();
		let amis = this.agtAmisNowUiStore.items.slice();

		const res: AgtFitnessVm[] = [];
		const agts = promises[0];
		let i = 0;
		for (const agt of agts) {
			if (agt.gpShort === 'AGP') {
				continue;
			}
			if (!agt.vdId || !agt.gsId) {
				continue;
			}
			if (agt.gsId === 'XX') {
				continue;
			}

			let gup;
			let prime = undefined;
			let planer = undefined;
			let digi = undefined;
			let ami = undefined;

			const isPrime = this.agtAkumaPrimeUiStore.checkAgtParticipation(agt.agtId);

			[gup, gups] = this.findAndSplice(gups, (a: any) => a.agt.agtId === agt.agtId);

			[prime, primes] = this.findAndSplice(primes, (a: any) => a.agt.agtId === agt.agtId);

			[planer, plans] = this.findAndSplice(plans, (a: any) => a.agt.agtId === agt.agtId);

			[digi, digis] = this.findAndSplice(digis, (a: any) => a.agt.agtId === agt.agtId);

			[ami, amis] = this.findAndSplice(amis, (a: any) => a.agt.agtId === agt.agtId);

			const vm = new AgtFitnessVm(agt, gup, prime, planer, digi, ami, isPrime);
			if (vm.keineDaten) {
				i++;
				continue;
			}

			res.push(vm);
		}

		console.warn('FITNESSTRACKER: ', i, ' Agenturen haben keine Daten und werden nicht angezeigt.');

		this.items = res;
	}
}
