import { AgenturModel } from "app/models/agentur.model";
import { AgenturStore } from "../agentur.store";
import { AgtZielModel, AgtZielStore } from "../agt.ziel.store";
import { SessionStore } from "../session.store";
import { BaseUiStore } from "./base.ui.store";
import { BPMAXYEAR, BPMINYEAR } from "./berichte.ui.store";
import { computed, runInAction } from "mobx";


interface IAgtHerbstPos {
    posDef: HerbstPosDef;
    agt: AgenturModel;
    posVal?: AgtZielModel;
    year: number;
}

export class AgtHerbstPos {
    posDef: HerbstPosDef;
    agt: AgenturModel;
    posVal?: AgtZielModel;
    year: number;
    constructor(opts: IAgtHerbstPos) {
        this.posDef = opts.posDef;
        this.agt = opts.agt;
        this.posVal = opts.posVal;
        this.year = opts.year;

    }

    @computed
    get ergebnis() {
        if (this.posVal) {
            return this.posVal.ergebnis;
        }
        return 0;
    }
    @computed
    get ziel() {
        if (this.posVal) {
            return this.posVal.ziel;
        }
        return 0;
    }

    @computed
    get ze() {
        if (this.posVal) {
            return this.posVal.ze;
        }
        return 0;
    }

}


export class HerbstPosDef {
    constructor(opts: any) {
        this.name = opts.name;
        this.nameShort = opts.nameShort;
        this.posId = opts.goldPosId;
        this.posName = opts.posName;
        this.soloPos = opts.soloPos;
    }
    name: string;
    nameShort: string;
    posId: number;
    posName: string;
    soloPos: boolean;



}

class CacheItem {
    constructor(year: number, items: AgtHerbstVm[]) {
        this.items = items;
        this.year = year;
        this.loadingPromise = new Promise((resolve) => {
            this._resolver = resolve;
        });
    }
    markAsLoaded = () => {
        if (this._resolver) {
            this._resolver();
        }
    };
    _resolver: any;
    year: number;
    items: AgtHerbstVm[];
    loadingPromise: Promise<any>;
}

export const HerbstPosDefGroups: HerbstPosDef[] = [

    { name: 'Leben EH', nameShort: 'Leben', posId: 5101020, posName: 'leben', soloPos: true },
    { name: 'Fonds+FRVZ', nameShort: 'Fonds', posId: 5101103, posName: 'fonds', soloPos: true },
    // { name: 'Fonds/FRVZ wegen FIP', nameShort: 'FondsFip', posId: 5101199, posName: 'fondsfip', soloPos: true },
    { name: 'Unfall (KUS LfB*3)', nameShort: 'Unfall', posId: 5101102, posName: 'unfall', soloPos: true },
    { name: 'Kranken gesamt', nameShort: 'Kranken', posId: 5101101, posName: 'kranken', soloPos: true },
    { name: 'mAZ-Quote', nameShort: 'mAZ-Quote', posId: 5101010, posName: 'maz', soloPos: true },
    { name: 'erfüllte Positionen nEH', nameShort: '', posId: 5101030, posName: 'anzErfuellt', soloPos: false },
    { name: 'Erfüller', nameShort: '', posId: 5101050, posName: 'istErfueller', soloPos: false },
];


export class AgtHerbstVm {

    constructor(agt: AgenturModel, items: AgtHerbstPos[]) {
        this.agt = agt;
        this.items = items;


    }
    agt: AgenturModel;
    items: AgtHerbstPos[];

    @computed
    get erfuelltIn() {
        return this.items.filter(i => i.posVal && i.posVal.ze >= 100);

    }

    @computed
    get leben() {
        return this.items.find(i => i.posDef.posName === 'leben');
    }

    @computed
    get fonds() {
        return this.items.find(i => i.posDef.posName === 'fonds');
    }

    @computed
    get fondsfip() {
        return this.items.find(i => i.posDef.posName === 'fondsfip');
    }

    @computed
    get unfall() {
        return this.items.find(i => i.posDef.posName === 'unfall');
    }

    @computed
    get kranken() {
        return this.items.find(i => i.posDef.posName === 'kranken');
    }

    @computed
    get maz() {
        return this.items.find(i => i.posDef.posName === 'maz');
    }

    @computed
    get anzErfuellt() {
        return this.items.find(i => i.posDef.posName === 'anzErfuellt');
    }

    @computed
    get istErfueller() {
        return this.items.find(i => i.posDef.posName === 'istErfueller');
    }

    @computed
    get hatErfuelt() {
        if (this.istErfueller && this.istErfueller.ergebnis > 0) {
            return true;
        }
        return false;
    }


}



export class AgtHerbstwerbungUiStore extends BaseUiStore<CacheItem> {
    name = 'AgtHerbstUiStore';
    session: SessionStore;
    agenturStore: AgenturStore;
    posAgtZielStore: AgtZielStore;
    constructor(session: SessionStore, agenturStore: AgenturStore, posAgtZielStore: AgtZielStore) {
        super();
        this.session = session;
        this.agenturStore = agenturStore;
        this.posAgtZielStore = posAgtZielStore;
        this.posAgtZielStore.registerPosIds(this.herbstPosIds);
    }

    get herbstPosIds() {
        const ids: number[] = [];
        this.herbstPosGroups.forEach((p) => {
            ids.push(p.posId);
        });
        return ids;
    }

    get herbstPosGroups(): HerbstPosDef[] {
        return HerbstPosDefGroups;
    }

    // items: [number, AgtRingPos[]] = [0,[]];


    cache: CacheItem[] = [];

    async _baseLoad() {
        for (let y = BPMAXYEAR; y >= BPMINYEAR; y--) {
            await this.findByYear(y);
        }
    }

    async findByYear(year: number) {
        let cacheItem = this.cache.find((c) => c.year === year);
        if (cacheItem) {
            await cacheItem.loadingPromise;
            return cacheItem.items;
        }
        cacheItem = new CacheItem(year, []);

        const p = await Promise.all([this.agenturStore.findAll(), this.posAgtZielStore.findAll()]);
        const agts = p[0];
        let allAgtPos = p[1];
        allAgtPos = allAgtPos.filter((p) => p.year === year);

        const posDefinitions = this.herbstPosGroups;
        const relevantPosIds: number[] = [];
        posDefinitions.forEach((pos) => {
            relevantPosIds.push(pos.posId);
        });

        // a lot faster for users with many agts
        allAgtPos = allAgtPos.filter((p) => relevantPosIds.includes(p.posId));

        const res: AgtHerbstVm[] = [];
        agts.forEach((agt) => {
            let agtHerbsPoses: AgtHerbstPos[] = [];
            posDefinitions.forEach((posDef) => {
                let agtPos;

                [agtPos, allAgtPos] = this.findAndSplice(allAgtPos, (p) => p.agtId === agt.agtId && posDef.posId === p.posId);

                const agtHerbstPos = new AgtHerbstPos({
                    agt,
                    posDef: posDef,
                    posVal: agtPos,
                    year
                });
                agtHerbsPoses.push(agtHerbstPos);

            });
            const vm = new AgtHerbstVm(agt, agtHerbsPoses);
            res.push(vm);
        });

        runInAction(() => {
            cacheItem!.items = res;
            this.cache.push(cacheItem!);
        });
        await cacheItem._resolver();
        return res;
    }


}