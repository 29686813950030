import { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../table/table';
import { TableModel, TableRow } from './../table/table.model';
import { AgenturExtendedVM } from 'app/stores/ui/agt.list.ui.store';
import { Modal } from '../Modal';
import { TempAgenturForm } from './temp/TempAgenturForm';
import { getAgtListCols } from 'app/models/tables/agentur.list.cols';
import { runInAction } from 'mobx';

export interface IAgenturList {
	onSelectAgt: (agt: AgenturExtendedVM | undefined) => void;
}

export interface IAgenturListItems {
	onSelectAgt: (agt: AgenturExtendedVM | undefined) => void;
}

export const AgenturListItems = observer((props: IAgenturListItems) => {
	const { agenturListUiStore } = useStore();

	useEffect(() => {
		const filtered = agenturListUiStore.applyFilter(agenturListUiStore.items, { ignoreAgtId: true }) as AgenturExtendedVM[];
		runInAction(() => {
			agenturListUiStore.filteredItems = filtered;
		});

		if (agenturListUiStore.currentFilter.singleSelectedAgtId) {
			const agt = filtered.find((a) => a.agtId === agenturListUiStore.currentFilter.singleSelectedAgtId);
			if (agt) {
				props.onSelectAgt(agt);
			}
		}
	}, [agenturListUiStore.items, agenturListUiStore.currentFilter.changedString, agenturListUiStore, props]);

	return <></>;
});

export const AgenturList = (props: IAgenturList) => {
	const { agenturListUiStore, agenturUiStore, session, uiStore } = useStore();
	const steart = session.currentUser!.steart;

	const tm = useMemo(() => {
		const tm = new TableModel<AgenturExtendedVM, number>();
		const cols = getAgtListCols(steart);

		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'agtId';
		tm.idType = 'number';

		tm.onRowClick = (row: TableRow<AgenturExtendedVM>) => {
			props.onSelectAgt(row.data);
		};
		tm.onRowShiftClick = (row: TableRow<AgenturExtendedVM>) => {
			window.open(`#/agentur/${row.data.agtId}`, '_blank');
		};
		return tm;
	}, [steart]);

	useEffect(() => {
		tm.setRowData(agenturListUiStore.filteredItems);
	}, [tm, agenturListUiStore.filteredItems]);

	useEffect(() => {
		if (agenturListUiStore.items.length >= 0) {
			tm.setSelectedId(agenturUiStore.currentAgtId);
		}
	}, [tm, agenturListUiStore, agenturUiStore.currentAgtId, agenturListUiStore.items.length]);

	const closeTempAgtMode = () => {
		uiStore.hideModal(uiStore.modalIds.newTempAgentur);
	};

	return (
		<>
			<Modal modalId={uiStore.modalIds.newTempAgentur} title={'Temporäre Agentur anlegen'} size="large" modifier="is-paddingless">
				<TempAgenturForm onClose={closeTempAgtMode} />
			</Modal>
			<Table modifier="left-col-bold" tm={tm} stickyHeader={true}></Table>
		</>
	);
};
