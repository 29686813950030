import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { ExportPdfTable } from 'app/stores/pdf.store';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { BaseTreeStore, AgtBaseTreeStore } from 'app/stores/core/base.tree.store';
import moment from 'moment';

interface IBerichtePanelHeaderProps {
	panelAreaRef: React.MutableRefObject<HTMLDivElement | null>;
	treeStore: AgtBaseTreeStore | BaseTreeStore;
	title?: string;
	extras?: JSX.Element;
}

export const TreePanelHeader = observer((props: IBerichtePanelHeaderProps) => {
	const location = useLocation<any>();
	const treeStore = props.treeStore;
	
	const history = useHistory();
	const node = treeStore.menu.current;
	const nodeTitle = node ? node.name : '';
	const title = props.title ? props.title : nodeTitle;

	const prevBericht = () => {
		const node = treeStore.menu.findPreviousNode(treeStore.menu.current);
		if (!node) {
			return;
		}
		history.push(treeStore.getPath(node, location as any));
	};
	const nextBericht = () => {
		const node = treeStore.menu.findNextNode(treeStore.menu.current);
		if (!node) {
			return;
		}
		history.push(treeStore.getPath(node, location as any));
	};

	if (!node) return null;

	const downloadPdf = node.downloadPdf || <DownloadPdf {...props} />;

	return (
		<>
			<div className="panel-header">
				<div className="header-left">
					<div className="breadcrumb">
						{node &&
							node.parents &&
							node.parents.map((p) => (
								<span key={p.name}>
									{p.name} {'>'}{' '}
								</span>
							))}
					</div>
					<div className="subtitle is-4">{node && title}</div>
					{node.subTitle && <div className="subtitle is-5">{node.subTitle}</div>}
					<div className="details">
						{node.datenStand && (
							<span className="date">
								<strong>{node.datenStand}</strong>
							</span>
						)}
						{}
						{downloadPdf}
					</div>
				</div>
				<div className="header-right">
					<div className="button-group panel-nav">
						<Button className="button is-inverted panel-nav-button" onClick={prevBericht}>
							<Icon iconClass="chevron-left" />
						</Button>
						<Button className="button is-inverted panel-nav-button" onClick={nextBericht}>
							<Icon iconClass="chevron-right" />
						</Button>
					</div>
				</div>
				<div className="header-extras">
					<div className="extras">{props.extras && <>{props.extras}</>}</div>
				</div>
				{treeStore.showFilter && (
					<div className="panel-filters">
						{treeStore.filterComponent}
						{/* <AgenturListsFilter /> */}
					</div>
				)}
			</div>
		</>
	);
});

const DownloadPdf = observer((props: IBerichtePanelHeaderProps) => {
	const { pdfStore } = useStore();
	const { treeStore } = props;

	const node = treeStore.menu.current;
	const nodeTitle = node ? node.name : '';
	const title = props.title ? props.title : nodeTitle;

	const downloadPdf = () => {
		if (!props.panelAreaRef.current) {
			return;
		}
		const html = props.panelAreaRef.current.innerHTML;
		const tb = new ExportPdfTable();
		tb.data = html;
		tb.isHtml = true;
		const versionDate = node && node.datenStandDate ? node.datenStandDate() : moment().format('YYYY-DD-MM');
		pdfStore.printPdf({ tables: [tb], title, versionDate, agt: undefined });
	};

	return (
		<Button className="button is-text" onClick={downloadPdf}>
			Download PDF
		</Button> 
	)
});