import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AgtFitnessVm } from 'app/stores/ui/agt.fitness.ui.store';
import { formatPercent } from 'app/utils';
import { simpleFitCell } from './FitnessTrackerListe';
import { useHistory, useLocation } from 'react-router';
import routes from 'routes';
import { FitnessTrackerGraphics } from './FitnessTrackerGraphics';

export const FitnessTrackerGUP = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);

	const [selectedAgtId, setSelectedAgtId] = useState<number | undefined>(undefined);
	const history = useHistory();
	const location = useLocation();

	const tm = useMemo(() => {
		const tm = new TableModel<AgtFitnessVm, number>();
		const cols: ITableColumn<AgtFitnessVm, number>[] = [];

		cols.push({
			label: 'Agentur',
			path: 'agt.name',
			sortBy: 'agt.name',
			render: AgenturNameCell,
		});

		// GOOGLE
		cols.push({
			label: 'Ranking',
			path: 'gup.rank',
			sortBy: 'gup.rank',
			cellModifier: 'border-left-green bg-green',
			labelModifier: 'border-left-green bg-green',
		});
		cols.push({
			label: 'GUP Bewertungen',
			path: `anzBew.ze`,
			sortBy: `anzBew.ze`,
			cellModifier: 'border-left-green bg-green',
			labelModifier: 'border-left-green bg-green',
			render(row) {
				return simpleFitCell(row.data.anzBew);
			},
		});
		cols.push({
			label: 'GUP Bewertungen Durchschnitt',
			path: `bewAvg.ze`,
			sortBy: `bewAvg.ze`,
			cellModifier: 'bg-green',
			labelModifier: 'bg-green',
			render(row) {
				return simpleFitCell(row.data.bewAvg);
			},
		});
		cols.push({
			label: 'GUP Bilder/Videos',
			path: `imgCount.ze`,
			sortBy: `imgCount.ze`,
			cellModifier: 'bg-green',
			labelModifier: 'bg-green',
			render(row) {
				return simpleFitCell(row.data.imgCount);
			},
		});
		cols.push({
			label: 'GUP Fragen + Anworten',
			path: `qCount.ze`,
			sortBy: `qCount.ze`,
			cellModifier: 'bg-green',
			labelModifier: 'bg-green',
			render(row) {
				return simpleFitCell(row.data.qCount);
			},
		});
		cols.push({
			label: 'Durchschnitt',
			path: `gupAverage.ze`,
			sortBy: `gupAverage.ze`,
			cellModifier: 'border-left-green bg-green',
			labelModifier: 'border-left-green bg-green',
			render(row) {
				return <>{formatPercent(row.data.gupAverage.ze)}%</>;
			},
		});

		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.onRowClick = (row) => {
			const p = routes.BERICHTE.getPath('bestand', 'fit', 'gup', row.data.agt.agtId.toString());
			history.push(p);
		};

		tm.idProperty = 'agtId';
		tm.idType = 'number';

		return tm;
	}, [history]);

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);

	useEffect(() => {
		// das hier ginge auch nicer, via treeNode Model und openByPath
		const lastPathElement = location.pathname.split('/').pop();
		let agtId;
		if (typeof lastPathElement === 'string') {
			agtId = parseInt(lastPathElement);
		}
		if (agtId && !isNaN(agtId)) {
			setSelectedAgtId(agtId);
		} else {
			setSelectedAgtId(undefined);
		}
	}, [location]);

	const onClose = () => {
		const p = routes.BERICHTE.getPath('bestand', 'fit', 'gup');
		history.push(p);
	};

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Fitnesstrackerdaten gefunden</div>
			) : (
				<>
					{selectedAgtId && <FitnessTrackerGraphics agtId={selectedAgtId} view="gup" onClose={onClose} />}
					<Table stickyHeader={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});
