import { action, computed, makeAutoObservable, makeObservable, observable } from 'mobx';
import { Steart } from './steart.model';

export interface ITreeNodeModel {
	name: string;
	icon: string;
	path: string;
	component?: JSX.Element;
	agtComponent?: JSX.Element;
	nodes?: ITreeNodeModel[];
	subTitle?: string;
	hideFromAgt?: boolean;
	hideFromAll?: boolean;
	datenStand?: JSX.Element;
	datenStandDate?: () => string;
	hideFilter?: boolean;
	visibileForRoles?: Steart[];
	lastPathIsParam?: boolean;
	downloadPdf?: JSX.Element;
}

export class TreeNodeModel {
	constructor(opts: ITreeNodeModel) {
		makeAutoObservable(this);
		this.name = opts.name;
		this.icon = opts.icon;
		this.path = opts.path;
		this.subTitle = opts.subTitle;
		if (opts.hideFromAgt) {
			this.hideFromAgt = opts.hideFromAgt;
		}
		if (opts.hideFromAll) {
			this.hideFromAll = opts.hideFromAll;
		}
		this.datenStand = opts.datenStand;
		this.datenStandDate = opts.datenStandDate;
		if (opts.hideFilter) {
			this.hideFilter = opts.hideFilter;
		}
		this.visibileForRoles = opts.visibileForRoles;
		if (opts.lastPathIsParam) {
			this.lastPathIsParam = true;
		}
		this.downloadPdf = opts.downloadPdf;
	}
	@observable level: number = 1;
	active: boolean = false;
	name: string;
	icon: string;
	path: string;
	@observable hideFilter: boolean = false;
	@observable.ref component?: JSX.Element;
	@observable.ref agtComponent?: JSX.Element;
	@observable.ref datenStand?: JSX.Element;
	datenStandDate?: () => string;
	visibileForRoles?: Steart[];

	lastPathIsParam: boolean = false;

	subTitle?: string;
	hideFromAgt: boolean = false;
	hideFromAll: boolean = false;
	downloadPdf?: JSX.Element;

	isVisibleForRole(steart: Steart) {
		if (this.visibileForRoles && this.visibileForRoles.length > 0) {
			return this.visibileForRoles.includes(steart);
		}
		return true;
	}

	@computed
	get currentPath() {
		const p = this.path;
		const s = p.split('/');
		return s[s.length - 1];
	}

	@action addNode(i: ITreeNodeModel) {
		const n = new TreeNodeModel(i);
		const path = this.path + '/' + n.path;
		const e = this.nodes.find((x) => x.path === path);
		if (e) {
			console.warn(` returning existing node at path ${path} `);
			return e;
		}
		n.path = this.path + '/' + n.path;
		n.level = this.level + 1;
		n.component = i.component;
		n.agtComponent = i.agtComponent;
		n.parent = this;
		this.nodes.push(n);
		if (i.nodes) {
			i.nodes.forEach((i) => {
				n.addNode(i);
			});
		}
		return n;
	}

	@computed
	get parents() {
		const res: TreeNodeModel[] = [];
		if (this.parent) {
			res.push(this.parent);
			if (this.parent.parent) {
				res.push(this.parent.parent);
				if (this.parent.parent.parent) {
					res.push(this.parent.parent.parent);
				}
			}
		}
		return res.reverse();
	}

	@observable parent?: TreeNodeModel;
	_open: boolean = false;

	@computed
	get open() {
		if (!this.parent) {
			return true;
		}
		return this._open;
	}

	@action
	setOpen(open: boolean) {
		this._open = open;
	}

	_getCleanLevels(level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) {
		let levels = [level1, level2, level3, level4, level5];
		levels = levels.filter((l) => l!); // remove undefined levels

		let num: number | undefined;
		if (this.lastPathIsParam) {
			const lastLevel = levels[levels.length - 1];
			// the last level  will be ignored if it an integer
			if (!isNaN(parseInt(lastLevel!))) {
				num = parseInt(lastLevel!);
				levels = levels.slice(0, levels.length - 1);
			}
		}

		return { levels, num };
	}

	@action
	setActivePath(level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) {
		const { levels } = this._getCleanLevels(level1, level2, level3, level4, level5);
		const currLevels = levels.slice(0, this.level);
		this.active = this.path === currLevels.join('/');
		this.nodes.forEach((n) => n.setActivePath(level1, level2, level3, level4, level5));
	}

	findNodeByPath(level1?: string, level2?: string, level3?: string, level4?: string, level5?: string): TreeNodeModel | undefined {
		const { levels } = this._getCleanLevels(level1, level2, level3, level4, level5);
		const currLevels = levels.slice(0, this.level + 1);
		let n = this.nodes.find((x) => x.path === currLevels.join('/'));
		if (n && levels.length > this.level + 1) {
			const x = n.findNodeByPath(level1, level2, level3, level4, level5);
			if (x) {
				n = x;
			}
		}
		return n;
	}

	@action
	openByPath(level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) {
		const { levels } = this._getCleanLevels(level1, level2, level3, level4, level5);
		const currLevels = levels.slice(0, this.level + 1);
		let n = this.nodes.find((x) => x.path === currLevels.join('/'));
		if (n && levels.length > this.level + 1) {
			n._open = true;
			n.openByPath(level1, level2, level3, level4, level5);
		}
	}

	nodes: TreeNodeModel[] = [];
}

export class TreeMenuModel {
	constructor(opts: ITreeNodeModel) {
		makeObservable(this);
		this.nodes = opts.nodes!.map((c) => {
			const n = new TreeNodeModel(c);
			if (c.nodes) {
				c.nodes.forEach((i) => {
					n.addNode(i);
				});
			}
			return n;
		});
	}

	@observable current?: TreeNodeModel;
	@observable nodes: TreeNodeModel[] = [];
	@observable activePath?: string = '';

	@computed
	get currentPath() {
		return this.current ? this.current.currentPath : undefined;
	}

	@computed
	get allNodesFlat() {
		const res: TreeNodeModel[] = [];
		this.nodes.forEach((n) => {
			res.push(n);
			n.nodes.forEach((n) => {
				res.push(n);
				n.nodes.forEach((n) => {
					res.push(n);
					n.nodes.forEach((n) => {
						res.push(n);
					});
				});
			});
		});
		return res;
	}

	@action
	findPreviousNode(node?: TreeNodeModel) {
		while (true) {
			if (!node) {
				break;
			}
			// eslint-disable-next-line
			const index = this.allNodesFlat.findIndex((n) => n.currentPath === node!.currentPath);

			if (index > 0) {
				node = this.allNodesFlat[index - 1];
				if (node.component) {
					return node;
				}
			} else {
				break;
			}
		}
	}

	@action
	findNextNode(node?: TreeNodeModel) {
		while (true) {
			if (!node) {
				break;
			}
			// eslint-disable-next-line
			const index = this.allNodesFlat.findIndex((n) => n.currentPath === node!.currentPath);

			if (index < this.allNodesFlat.length - 1) {
				node = this.allNodesFlat[index + 1];
				if (node.component) {
					return node;
				}
			} else {
				break;
			}
		}
	}

	@action
	setActivePath(level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) {
		this.nodes.forEach((node1) => {
			node1.setActivePath(level1, level2, level3, level4, level5);
		});
		this.current = this.findNodeByPath(level1!, level2, level3, level4, level5);
	}

	findNodeByPath(level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) {
		let n = this.nodes.find((n) => n.path === level1);
		if (n && level2) {
			n = n.findNodeByPath(level1, level2, level3, level4, level5);
		}
		return n;
	}

	@action
	openByPath(level1?: string, level2?: string, level3?: string, level4?: string, level5?: string) {
		let n = this.nodes.find((n) => n.path === level1);
		if (n) {
			n._open = true;
			n.openByPath(level1, level2, level3, level4, level5);
		}
	}
}

// export const BerichteModel = new TreeMenuModel(berichte);
