import moment from "moment";
import { BaseEntityStore } from "./core/base.entity.store";
import { ImportDataType } from "./admin.imports.store";

import { formatDate } from "app/utils";
import { computed } from "mobx";
import { TagColors } from "app/components/common/DottedTag";
import { BaseCachedEntityStore } from "./core/base.cached.entity.store";

enum ImportCycle {
    weekly = 'weekly',
    monthly = 'monthly',
    yearly = 'yearly',
    onceinawhile = 'onceinawhile ',

}


const DatenStandTypes = [
    {
        dataType: 'aup_gp',
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.weekly
    },
    {
        dataType: 'aup_bestand',
        format: (d: Date) => formatDate(moment(d).startOf('month').toDate()),
        cycle: ImportCycle.monthly

    },
    {
        dataType: 'aup_vorjahr',
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.yearly
    },
    {
        dataType: ImportDataType.aladin,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.onceinawhile
    },
    {
        dataType: ImportDataType.aladin2,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.onceinawhile
    },
    {
        dataType: ImportDataType.amisnow,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.onceinawhile
    },
    {
        dataType: ImportDataType.leadnow,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.weekly
    },
    {
        dataType: ImportDataType.agenturcluster,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.yearly
    },
    {
        dataType: ImportDataType.akumaprime,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.weekly
    },
    {
        dataType: ImportDataType.akumaplaner,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.weekly
    },
    {
        dataType: ImportDataType.digitalreport,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.monthly
    },
    {
        dataType: ImportDataType.multisagentur,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.onceinawhile
    },
    {
        dataType: ImportDataType.multisgp,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.onceinawhile
    },
    {
        dataType: ImportDataType.googleprofile,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.monthly
    },
    {
        dataType: ImportDataType.crawler,
        format: (d: Date) => formatDate(d),
        cycle: ImportCycle.weekly
    },

]


export class DatenStandModel {
    constructor(opts: any) {
        this.dataType = opts.key;
        if (opts.valueString) {
            const j = JSON.parse(opts.valueString);
            this.file = j.dataPath;
            this.stand = moment(j.datenStand).toDate();
            this.agtCount = j.agtCount;
            this.rowCount = j.rowCount;
        }
        this.modified = opts.modified;
        const thing = DatenStandTypes.find(x => x.dataType === this.dataType);
        if (thing) {
            this.cycle = thing.cycle;
            this.standFormated = thing.format(this.stand!);
        }
    }

    dataType: string;
    file?: string;
    stand?: Date;
    standFormated?: string;
    modified?: Date;
    cycle?: ImportCycle;

    agtCount?: number;
    rowCount?: number;

    @computed
    get ageInDays() {
        if (!this.cycle) {
            return -500;
        }
        return moment().diff(moment(this.stand!), 'days');
    }

    @computed
    get importDataType(): ImportDataType {
        if (["aup_gp", "aup_bestand"].includes(this.dataType)) {
            return ImportDataType.aup;
        }
        return this.dataType as ImportDataType;
    }

    @computed
    get ageForSorting() {
        const age = this.ageInDays;
        let goodAge = 7;
        let badAge = 14;
        switch (this.cycle!) {
            case ImportCycle.weekly:
                break;
            case ImportCycle.monthly:
                goodAge = 30;
                badAge = 45;
                break;
            case ImportCycle.yearly:
                goodAge = 365;
                badAge = 400;
                break;
            case ImportCycle.onceinawhile:
                goodAge = 120;
                badAge = 240;
                break;
            default:
                return -500;

        }
        if (age <= goodAge) {
            return age / 10;
            //return TagColors.green;
        }
        if (age < badAge) {
            return age + badAge
            //return TagColors.yellow;
        }
        return age + badAge * 10;
        return TagColors.red;;
    }


    @computed
    get color() {
        const age = this.ageInDays;
        let goodAge = 7;
        let badAge = 14;
        switch (this.cycle!) {
            case ImportCycle.weekly:
                break;
            case ImportCycle.monthly:
                goodAge = 30;
                badAge = 45;
                break;
            case ImportCycle.yearly:
                goodAge = 365;
                badAge = 400;
                break;
            case ImportCycle.onceinawhile:
                goodAge = 120;
                badAge = 240;
                break;

        }
        if (age <= goodAge) {
            return TagColors.green;
        }
        if (age < badAge) {
            return TagColors.yellow;
        }
        return TagColors.red;;
    }
}

export class AdminDatenStandStore extends BaseEntityStore<DatenStandModel> {
    apiPath = 'api/datenstand';
    idProp: string = 'id';
    createInstances(opts: any): DatenStandModel {
        return new DatenStandModel(opts);
    }
}

export class DatenStandStore extends BaseCachedEntityStore<DatenStandModel> {
    apiPath = 'api/datenstand';
    idProp: string = 'id';
    createInstances(opts: any): DatenStandModel {
        return new DatenStandModel(opts);
    }
}
