import React, { useEffect } from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'app/context';

const TestClearPlanungContainer = observer((props: RouteComponentProps) => {
	const { agtPlanStore } = useStore();
	useEffect(() => {
		agtPlanStore.clearPlanungForTest().then(() => {
			console.log("cleared");
		})

	}, [agtPlanStore]);
	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Feedback</div>}>

		</BaseContainer>
	);
});

export default TestClearPlanungContainer;
