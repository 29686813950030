import React, { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../../table/table';
import { ITableColumn, TableModel, TableRow } from './../../table/table.model';
import { runInAction } from 'mobx';
import { UserVM } from 'app/stores/ui/admin.user.ui.store';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Icon } from 'app/components/common/Icon';
import { formatDateLong } from 'app/utils';

export const AdminUsersList = observer(() => {
	const { adminUserUiStore } = useStore();
	const history = useHistory();

	const tm = useMemo(() => {
		const viewDoc = (doc: UserVM) => {
			runInAction(() => {
				history.push(routes.ADMINUSERS.getPath(doc.user.userId));
			});
		};

		const tm = new TableModel<UserVM, number>();
		tm.renderBatchSize = 200;
		let cols: ITableColumn<UserVM, number>[] = [

			{
				label: 'Bensl',
				path: 'user.bensl',
			},
			{
				label: 'Name',
				path: 'user.name',
			},

			{
				label: 'invitable',
				path: 'user.isInvitable',
				render: (row: TableRow<UserVM>) => {
					return <>{row.data.user.isInvitable ? <>Yes</> : <>No</>}</>;
				},
			},
			{
				label: 'BnrId',
				path: 'user.bnrId',
			},
			{
				label: 'Email',
				path: 'user.email',
			},
			{
				label: 'Status',
				path: 'user.statusText',
			},
			{
				label: 'VD',
				path: 'user.firstVd',
			},
			{
				label: 'GS',
				path: 'user.firstGs',
			},
			{
				label: 'Steart',
				path: 'user.steartText',
			},
			{
				label: 'Notifications',
				path: 'user.emailNotifications',
				render: (row: TableRow<UserVM>) => {
					return <>{row.data.user.emailNotifications && <Icon iconClass={'check'} />}</>;
				},
			},
			{
				label: 'Login am',
				path: 'user.lastLoginDate',
				format: formatDateLong,
			},
			{
				label: 'Letzte Einladung Vor Tagen',
				path: 'user.lastInviteDaysAgo',
			}, {
				label: 'Letzte Wochenmail Vor Tagen',
				path: 'user.lastCrawlDaysAge',
			},
			{
				label: 'AnzAgt',
				path: 'user.agtCount',
			},
			{
				label: 'Lebt',
				path: 'user.lebend',
				render: (row: TableRow<UserVM>) => {
					return <>{row.data.user.lebend ? <>Y</> : <>N</>}</>;
				},
			},
		];

		tm.onRowClick = (row: TableRow<UserVM>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols);
		tm.sortBy = 'user.lastLoginDate';
		tm.sortAsc = false;
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [history]);

	useEffect(() => {
		adminUserUiStore.findAll();
		tm.setRowData(adminUserUiStore.currentItems);
	}, [adminUserUiStore, adminUserUiStore.currentItems, tm]);

	return (
		<>
			<Table stickyHeader={true} tm={tm}></Table>
		</>
	);
});
