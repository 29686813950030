import { Icon } from 'app/components/common/Icon';
import { AgenturNameCell, IAgtCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtHerbstVm, HerbstPosDefGroups } from 'app/stores/ui/agt.herbstwerbung.ui.store';
import { formatNum, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

export interface IGPYear {
	year: number;
}

export const AgtHerbstOverviewList = observer((props: IGPYear) => {
	const { agtHerbstwerbungUiStore, berichteUiiStore, agenturListUiStore } = useStore();
	const formatPerc = (val: number) => {
		return `${formatPercent(val, 2)}%`;
	};

	const tm = useMemo(() => {
		const tm = new TableModel<AgtHerbstVm, number>();
		const cols: ITableColumn<AgtHerbstVm, number>[] = [
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			}
		];
		let posDef = HerbstPosDefGroups.find(x => x.posName === 'anzErfuellt')!
		let sortPath = 'agtId';
		if (posDef) {

			cols.push({
				label: 'Hat Erfüllt nEH',
				path: 'hatErfuelt',
				sortBy: 'anzErfuellt.ergebnis',
				render: (row: TableRow<AgtHerbstVm>) => {
					return (<>
						<span className={`medal is-small ${row.data.hatErfuelt ? 'is-gold' : ''}`}>
							<Icon iconClass={'leaf'} />
						</span>{' '}
					</>
					);
				},
			})

			HerbstPosDefGroups.filter(x => x.soloPos).forEach(posDef => {
				let path = posDef.posName + '.ze';

				cols.push({
					label: posDef.nameShort,
					path,

					format: formatPerc,
					agg: [
						{
							path: 'empty',
							// format: formatPerc,
							aggType: AggregrationType.empty,
						},
						{
							path,
							format: formatPerc,
							aggType: AggregrationType.avg,
						},
						{
							path,
							format: formatNum,
							aggType: AggregrationType.count,
						},
					],

				})
			})

		}

		tm.setCols(cols);
		tm.sortBy = 'anzErfuellt.ergebnis';
		tm.sortAsc = false;
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		const p = berichteUiiStore.menu.currentPath;
		if (p) {


			agtHerbstwerbungUiStore.findByYear(props.year).then((res) => {
				res = agenturListUiStore.applyFilterOnAgt(res) as AgtHerbstVm[];
				if (berichteUiiStore.selectedAgt) {
					const agtId = berichteUiiStore.selectedAgt.agtId;
					res = res.filter((d) => d.agt.agtId === agtId);
				}

				tm.setRowData(res);
			});
		}
	}, [tm, agtHerbstwerbungUiStore, agtHerbstwerbungUiStore.cache, agenturListUiStore.filterHasChanged, agenturListUiStore.items, berichteUiiStore.selectedAgt, berichteUiiStore.menu.currentPath, props.year, agenturListUiStore]);
	return (
		<>
			<div className="table-container">

				<Table tm={tm} stickyHeader={true}></Table>
			</div>
		</>
	);
});
